import {Component, OnInit} from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  ChildActivationEnd,
  NavigationEnd,
  NavigationStart,
  ResolveEnd,
  Router,
  RouterOutlet
} from "@angular/router";
import {filter} from "rxjs/operators";
import {LocaleManager} from "../../managers/locale.manager";
import {RouteManager} from "../../managers/route.manager";

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrl: './root.component.scss'
})
export class RootComponent implements OnInit {

  constructor(
      private readonly router: Router,
      private readonly localeManager: LocaleManager,
      private readonly routeManager: RouteManager,
  ) {
  }

  ngOnInit() {
    this.router.events.pipe(
        filter((event: any) => event instanceof ChildActivationEnd),
    )
        .subscribe((event: ChildActivationEnd) => {
          const data = event.snapshot.firstChild?.data;

          if (data && data["locale"]) {
            this.localeManager.setCurrentLocale(data["locale"]);
          }

          if (data && data["id"]) {
              let additionalParameters = undefined;

              if (data["articleKeyname"]) {
                  additionalParameters = {articleKeyname: data["articleKeyname"]};
              } else if (data["calculationKeyname"]) {
                  additionalParameters = {calculationKeyname: data["calculationKeyname"]};
              }

              this.routeManager.setCurrentRoute(data["id"], additionalParameters);
          }
        });
  }
}
