import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export type TeamMember = {
    name: TextContentModel;
    profilePicture: ImageContentModel;
}

export const AboutUsContentResources: {
    TeamMembers: TeamMember[]
} & ContentResourceInterface & { [id in string]: any } = {
    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "À propos de nous", "en": "About us"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Une entreprise québécoise qui crée des outils pour les cabinets comptables d’ici. Optitax est une entreprise québécoise pionnière dans le développement d’outils informatiques fiscaux. Nous vous permettons d’optimiser des situations fiscales complexes en quelques clics. Notre logiciel avancé effectue des dizaines de milliers de simulations pour déterminer la solution la plus optimale selon la situation précise de votre client. Optitax vous offre également l’option de télécharger les formulaires électroniques gouvernementaux remplis en bonne et due forme afin de vous faire économiser encore plus de temps. Ce n’est qu’un début. Ne tardez pas et commencez à utiliser Optitax dès maintenant.\n",
                "en": "Contact Optitax to get tailored tax solutions that suit your needs. Our experienced team is here to answer all  your questions and help you optimize your tax management. Whether you're an individual, a business, or a student,  we offer a comprehensive range of customized packages, including free solutions for students.\n"
            }
        ),
        Keywords: new TextContentModel(
            {
                "fr": "Optitax, Contactez-nous, Logiciel de gestion fiscale, Expertise fiscale, Solutions fiscales personnalisées, Assistance fiscale, Conseils fiscaux, Contactez-nous pour vos besoins fiscaux, Gestion fiscale efficace, Planification fiscale, Conformité fiscale, Services fiscaux professionnels, Optimisation fiscale, Réglementation fiscale, Support client pour la gestion fiscale, Demandez une démo gratuite, Partenariat fiscal",
                "en": "Optitax, Contact Us, Tax management software,Tax expertise,Customized tax solutions,Tax assistance,Tax advisory,Contact us for your tax needs,Efficient tax management,Tax planning,Tax compliance,Professional tax services,Tax optimization,Tax regulations,Customer support for tax management,Request a free demo,Tax partnership"
            }
        ),
    },


    "about-us.body.title": new TextContentModel(
        {"fr": "À propos de nous", "en": "About us"}
    ),

    "about-us.body.first-section.title": new TextContentModel(
        {
            "fr": "Une entreprise québécoise qui crée des outils pour les cabinets comptables d’ici",
            "en": "A canadian company that creates powerful tools for canadian companies"
        }
    ),

    "about-us.body.first-section.description": new MarkdownContentModel(
        {
            "fr": "Optitax est une entreprise québécoise pionnière dans le développement d’outils informatiques fiscaux. Nous vous permettons d’optimiser des situations fiscales complexes en quelques clics.          \n\nNotre logiciel avancé effectue des dizaines de milliers de simulations pour déterminer la solution la plus optimale selon la situation précise de votre client.          \n\nOptitax vous offre également l’option de télécharger les formulaires électroniques gouvernementaux remplis en bonne et due forme afin de vous faire économiser encore plus de temps.          \n\n**Ce n’est qu’un début**. Ne tardez pas et commencez à utiliser Optitax dès maintenant.\n",
            "en": "A canadian company that creates powerful tax calculation tools for canadian companies"
        }
    ),

    "about-us.body.first-section.call-to-action": new TextContentModel(
        {"fr": "Débuter gratuitement maintenant", "en": "Start for free now"}
    ),

    "about-us.body.first-section.image": new ImageContentModel(
        {
            url: new TextContentModel({
                fr: "images/hands_holding.jpg",
                en: "images/hands_holding.jpg",
            }),
        }
    ),

    "about-us.body.second-section.title": new TextContentModel(
        {"fr": "Notre Histoire", "en": "Our Story"}
    ),

    "about-us.body.second-section.description": new MarkdownContentModel(
        {
            "fr": "4 grands amis avec une passion hors du commun: la fiscalité. \n**Nicolas L'Homme, CPA, M. Fisc**, travaillait dans un grand cabinet comptable et réfléchissait à ses heures à une façon de rendre la fiscalité plus précise et plus rapide. Il débute un prototype du calcul qui deviendra éventuellement un des plus grands vendeurs.\n\nArmé de son idée innovatrice, il approcha son ami de longue date, **Antoine Noël, Ph.D. en économie**  afin de l'aider à coder les calculs. \n\nQuelques semaines plus tard, Antoine et Nicolas approchèrent **Etienne Noël, ing**. afin qu'ils leurs donnent un coup de main au niveau de l'ingénierie. Ce qui débuta par une idée, commençait à prendre forme.\n\nRéalisant l'ampleur du projet, Etienne approcha son bon ami **Mathieu Giroux-Huppé, ing.** afin qu'il l'épaule dans ce projet. Tous réunis, les 4 travaillèrent pendant plusieurs années afin de mettre au monde: **Optitax**.\n\nOptitax est un logiciel fiscal unique sur le marché. Optitax repousse les limites de ce qui se fait en fiscalité dans le marché. Ensemble, ils lancent des calculs innovateurs:\n\n* [Détention Automobile](/outils-fiscaux/detention-automobile-personelle-ou-corporative)\n* [Salaire Dividendes](/outils-fiscaux/remuneration-salaire-ou-dividendes)\n* [Roulement](/outils-fiscaux/roulement)\n* [Vente d'actions](/outils-fiscaux/vente-actions)\n          \n\nOptitax est une fierté québécoise. Il n'est pas tous les jours qu'une compagnie québécoise compétitionne les grandes entreprises mondiales et Optitax est fière d'être une pionnière dans le monde de la fiscalité. \n",
            "en": "Our Story"
        }
    ),

    "about-us.body.second-section.image": new ImageContentModel(
        {
            url: new TextContentModel({
                fr: "images/photo_groupe_optitax.jpg",
                en: "images/photo_groupe_optitax.jpg",
            }),
        }
    ),

    "about-us.body.our-team.title": new TextContentModel(
        {"fr": "Notre équipe", "en": "Our Team"}
    ),

    TeamMembers: [
        {
            name: new TextContentModel(
                {
                    "fr": "Nicolas L'Homme, CPA, M. Fisc.",
                    "en": "Nicolas L'Homme, CPA, M. Fisc.",
                }
            ),
            profilePicture: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/photo_nicolas_lhomme.jpg",
                        en: "images/photo_nicolas_lhomme.jpg",
                    }),
                }
            )
        },
        {
            name: new TextContentModel(
                {
                    "fr": "Antoine Noël, PHD en Économie",
                    "en": "Antoine Noël, PHD en Économie",
                }
            ),
            profilePicture: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/photo_antoine_noel.jpg",
                        en: "images/photo_antoine_noel.jpg",
                    }),
                }
            )
        },
        {
            name: new TextContentModel(
                {
                    "fr": "Etienne Noël, ing.",
                    "en": "Etienne Noël, ing.",
                },
            ),
            profilePicture: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/photo_etienne_noel.jpg",
                        en: "images/photo_etienne_noel.jpg",
                    }),
                }
            )
        },
        {
            name: new TextContentModel(
                {
                    "fr": "Mathieu Giroux-Huppé, ing.",
                    "en": "Mathieu Giroux-Huppé, ing.",
                }
            ),
            profilePicture: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/photo_mathieu_giroux_huppe.jpg",
                        en: "images/photo_mathieu_giroux_huppe.jpg",
                    }),
                }
            )
        }
    ]
}
