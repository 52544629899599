<app-header>
    <div class="row">
        <div class="col-12">
            <h1 class="text-center text-white display-2">{{ AboutUsContentResources["about-us.body.title"] | render | async }}</h1>
        </div>
    </div>
</app-header>

<div class="container">
    <div class="row">
        <div class="col-12 col-lg-6">
            <h2 class="mt-5">{{ AboutUsContentResources["about-us.body.first-section.title"] | render | async }}</h2>

            <div class="mt-5"
                 [innerHTML]="AboutUsContentResources['about-us.body.first-section.description'] | render | async "></div>

            <a class="btn btn-primary d-block"
               [routerLink]="routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted)">{{ AboutUsContentResources["about-us.body.first-section.call-to-action"] | render | async }}</a>
        </div>
        <div class="col-12 col-lg-6 d-flex align-items-center">
            <app-image [imageContentModel]="AboutUsContentResources['about-us.body.first-section.image']"
                       [fetchPriority]="FetchPriorityEnum.Low" [loading]="LoadingEnum.Lazy"
                       [imageClass]="'img-fluid rounded align-self-center'"></app-image>
        </div>
    </div>
</div>

<div class="second-section mt-5 pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-5 d-flex align-items-center">
                <app-image [imageContentModel]="AboutUsContentResources['about-us.body.second-section.image']"
                           [fetchPriority]="FetchPriorityEnum.Low" [loading]="LoadingEnum.Lazy"
                           [imageClass]="'img-fluid rounded align-self-center'"></app-image>
            </div>
            <div class="col-12 col-lg-7">
                <h2>{{ AboutUsContentResources["about-us.body.second-section.title"] | render | async }}</h2>

                <div class="mt-4"
                     [innerHTML]="AboutUsContentResources['about-us.body.second-section.description'] | render | async"></div>
            </div>
        </div>
    </div>
</div>

<div class="third-section pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>{{ AboutUsContentResources["about-us.body.our-team.title"] | render | async }}</h2>
                    <div class="d-flex flex-row justify-content-center mt-5">
                        @for (teamMember of AboutUsContentResources.TeamMembers; track $index; ) {
                            <div class="ms-4 me-4" style="width: 250px; height: 250px;">
                                <app-image [imageContentModel]="teamMember.profilePicture"
                                           [fetchPriority]="FetchPriorityEnum.Low" [loading]="LoadingEnum.Lazy"
                                           [imageClass]="'img-fluid rounded-circle'"></app-image>
                                <h4 class="h6 mt-3 text-center">{{ teamMember.name | render | async }}</h4>
                            </div>
                        }


                    </div>

                </div>

            </div>
        </div>
    </div>
