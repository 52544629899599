import {AdvancedFormControlDecoratorOptionsInterface} from "./advanced-form-control-decorator-options.interface";
import {AdvancedFormArrayDecoratorOptions} from "./advanced-form-array-decorator.options";
import {AdvancedFormGroupDecoratorOptionsInterface} from "./advanced-form-group-decorator-options.interface";

export const advancedFormGroup = (advancedFormGroup: AdvancedFormGroupDecoratorOptionsInterface, ) => {
    return (
        target: Object,
        propertyKey: string | symbol,
    ) => {
        if(target.constructor.prototype.hasOwnProperty("__metadata__") === false) {
            target.constructor.prototype["__metadata__"] = {}
        }

        if(target.constructor.prototype["__metadata__"].hasOwnProperty("advancedForms") === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"] = {}
        }
        if(target.constructor.prototype["__metadata__"]["advancedForms"].hasOwnProperty("properties") === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"]["properties"] = {}
        }

        if(target.constructor.prototype["__metadata__"]["advancedForms"]["properties"].hasOwnProperty(propertyKey) === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey] = {}
        }

        if(target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey].hasOwnProperty("advancedFormGroup") === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey]["advancedFormGroup"] = {};
        }

        target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey]["advancedFormGroup"] = advancedFormGroup;
    }
}