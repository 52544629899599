import { translationBaseKey } from "../../../../decorators/translation-base-key.decorator";
import { advancedFormControl } from "../../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../../enums/field-type.enum";
import { IsInt, IsNotEmpty} from "@pristine-ts/class-validator";
import { ErrorMessageBuilder } from "../../../../builders/error-message.builder";

@translationBaseKey("income-tax-personal-taxable-dividend-options")
export class IncomeTaxPersonalTaxableDividendOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Valeur des dividendes imposables ordinaires",
      en: "Value of non-eligible taxable dividend"
    },
    tooltip: {
      fr: "Indiquez le montant des dividendes imposables ordinaires reçus. Ces dividendes peuvent être considérés comme non admissibles à certains crédits d'impôt.",
      en: "Specify the value of non-eligible taxable dividends received. These dividends may not be eligible for certain tax credits.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  nonEligible: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur des dividendes imposables déterminés",
      en: "Value of eligible taxable dividend"
    },
    tooltip: {
      fr: "Indiquez le montant des dividendes imposables déterminés reçus. Ces dividendes peuvent être considérés comme admissibles à certains crédits d'impôt.",
      en: "Specify the value of eligible taxable dividends received. These dividends may be eligible for certain tax credits.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  eligible: number;
}
