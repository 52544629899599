import { Component } from '@angular/core';
import {TermsAndConditionsContentResources} from "../../content/resources/terms-and-conditions.content-resources";
import {BaseComponent} from "../../components/base.component";
import {LocaleManager} from "../../managers/locale.manager";
import {RouteManager} from "../../managers/route.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent extends BaseComponent {
    constructor(
        localeManager: LocaleManager,
        routeManager: RouteManager,
        contentManager: ContentManager,
        titleService: Title,
        meta: Meta,) {
        super(localeManager,
            routeManager,
            contentManager,
            titleService,
            meta,
            TermsAndConditionsContentResources
        )
    }
    protected readonly TermsAndConditionsContentResources = TermsAndConditionsContentResources;
}
