<app-header>
    <h1 class="text-center text-white mt-5"
        style="height: 200px;">{{ TermsAndConditionsContentResources["terms-and-conditions.body.hero.title"] | render | async }}
        <br>
        <small>{{ TermsAndConditionsContentResources["terms-and-conditions.body.hero.last-modified"] | render | async }}</small>
    </h1>
</app-header>

<div class="container content-space-1 content-space-md-3">
    <div class="row">
        <div class="col-12"
             [innerHTML]="TermsAndConditionsContentResources['terms-and-conditions.body.terms-and-conditions'] | render | async">
        </div>
        <!-- End Col -->
    </div>
    <!-- End Row -->
</div>