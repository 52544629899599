import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import { IsNotEmpty, IsString } from "@pristine-ts/class-validator";
import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { v4 as uuid } from 'uuid';
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-non-share-consideration-options")
export class RolloverNonShareConsiderationOptions {

  @advancedFormControl({
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "Nom de la contrepartie autre qu'en actions",
      en: "Name of the consideration other than shares"
    },
    tooltip: {
      fr: "Le nom de la contrepartie dans la transaction de roulement qui n'est pas en actions. Ce champ est utilisé pour spécifier le nom de la contrepartie autre que les actions impliquée dans la transaction de roulement.",
      en: "The name of the consideration in the rollover transaction that is not in shares. This field is used to specify the name of the consideration other than shares involved in the rollover transaction."
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  title: string;
}