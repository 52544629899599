import {property} from "@pristine-ts/metadata";
import {CalculationKeynameEnum} from "../enums/calculations/calculation-keyname.enum";
import {type} from "@pristine-ts/data-mapping-common";
import {CalculationRunOptions} from "../options/calculations/calculation-run.options";
import {SalaryDividendOptions} from "../options/calculations/salary-dividend/salary-dividend.options";
import {RolloverOptions} from "../options/calculations/rollover/rollover.options";
import {Section84_1Options} from "../options/calculations/section-84_1/section-84_1.options";
import {SalaryContributionsOptions} from "../options/calculations/salary-contributions/salary-contributions.options";

export class OptitaxFileModel {
    @property()
    _type = "__optitax_file__";

    @property()
    version: string;

    @property()
    calculationKeyname: CalculationKeynameEnum

    @property()
    title: string;

    @type((target, propertyKey) => {
        switch (target.calculationKeyname) {
            case CalculationKeynameEnum.SalaryDividendSimulation:
                return new SalaryDividendOptions();
            case CalculationKeynameEnum.Rollover:
                return new RolloverOptions();
            case CalculationKeynameEnum.Section84_1:
                return new Section84_1Options();
            case CalculationKeynameEnum.SalaryContributions:
                return new SalaryContributionsOptions();
        }

        return {};
    })
    options: CalculationRunOptions;

    @property()
    createdAt: Date;

    @property()
    updatedAt: Date;
}