import {Inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class MagienoApiController {
    constructor(
        @Inject("magieno.observability.api_key") private readonly observabilityApiKey: string,
        private readonly httpClient: HttpClient) {
    }

    async sendAlert(severity: "CRITICAL" | "ERROR" | "WARNING" | "INFO" | "DEBUG", message: string, extra: { [id in string]: any }) {
        return this.httpClient.post("https://api.magieno.com/api/observability/alerts", {
            severity,
            message,
            extra,
        }, {
            headers: {
                "x-api-key": this.observabilityApiKey,
            }
        }).toPromise();
    }

    async sendLog(severity: "CRITICAL" | "ERROR" | "WARNING" | "INFO" | "DEBUG", message: string, extra: { [id in string]: any }) {
        return this.httpClient.post("https://api.magieno.com/api/observability/logs", {
            severity,
            message,
            extra,
        }, {
            headers: {
                "x-api-key": this.observabilityApiKey,
            }
        }).toPromise();
    }
}
