export const translationBaseKey = (baseKey: string) => {
    return (
        constructor: Function
    ) => {
        if(constructor.prototype.hasOwnProperty("__metadata__") === false) {
            constructor.prototype["__metadata__"] = {}
        }

        if(constructor.prototype["__metadata__"].hasOwnProperty("translation") === false) {
            constructor.prototype["__metadata__"]["translation"] = {}
        }

        constructor.prototype["__metadata__"]["translation"]["baseKey"] = baseKey;
    }
}