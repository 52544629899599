import { Component } from '@angular/core';
import {RouteManager} from "../../managers/route.manager";
import {RouteEnum} from "../../enums/route.enum";

@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  styleUrl: './error-404.component.scss'
})
export class Error404Component {
  constructor(
      public readonly routeManager: RouteManager,
  ) {
  }

  protected readonly RouteEnum = RouteEnum;
}
