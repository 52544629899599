<app-header>
    <div class="row">
        <div class="col-12">
            <h1>{{ ContactUsContentResources["contact-us.body.title"] | render | async }}</h1>

            <h4 class="subtitle text-center text-white">
                {{ ContactUsContentResources["contact-us.body.subtitle"] | render | async }}
            </h4>
        </div>
    </div>
</app-header>

<div class="container main">
    <div class="row">
        <div class="col-xs-12 col-md-6 text-center">
            <h3 style="margin-bottom: 20px;">{{ ContactUsContentResources["contact-us.body.chat.title"] | render | async }}</h3>


            <div id="chat-container">
                <div id="chat-blocked-alert" *ngIf="hubspotChatStatus === HubspotChatStatusEnum.Blocked" ng-c
                     class="alert alert-danger d-flex align-items-center">
                    <i class="bi bi-exclamation-triangle"></i>
                    <div>Votre bloqueur de contenu empêche notre clavardage de s'initialiser. Veuillez le désactiver
                        pour clavarder avec nous.
                    </div>
                </div>

                <a id="chat-open-link" [href]="this.routeManager.getCurrentRouteInCurrentLocale() + '#hs-chat-open'"
                   class="btn btn-primary" [class.visually-hidden]="hubspotChatStatus === HubspotChatStatusEnum.Blocked"
                   [class.disabled]="hubspotChatStatus !== HubspotChatStatusEnum.Loaded">
                    <div id="chat-loading-text"
                         [class.visually-hidden]="hubspotChatStatus !== HubspotChatStatusEnum.Loading">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Chargement...
                    </div>

                    <span id="chat-loaded-text"
                          [class.visually-hidden]="hubspotChatStatus !== HubspotChatStatusEnum.Loaded"> <i
                            class="bi bi-chat-fill"></i> {{ ContactUsContentResources["contact-us.body.chat.call-to-action"] | render | async }} </span>
                </a>
            </div>

        </div>
        <div class="col-xs-12 col-md-6 text-center">
            <h3 style="margin-bottom: 20px;">{{ ContactUsContentResources["contact-us.body.email.title"] | render | async }}</h3>
            <a href="mailto:support@optitax.ca"
               class="btn btn-link">support&#64;optitax.ca</a>
        </div>

        <hr>

        <div class="col-12 col-lg-8 offset-lg-2">

            <div class="row">
                <div class="col-12 text-center">
                    <h2 id="contact-us-form">{{ ContactUsContentResources["contact-us.body.contact-us.title"] | render | async }}</h2>
                    <div>{{ ContactUsContentResources["contact-us.body.contact-us.subtitle"] | render | async }}</div>
                </div>
            </div>

            <br>
            <br>

            <div id="contact_us_hubspot_form"></div>

        </div>
    </div>
</div>
