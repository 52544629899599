<app-header>
    <h1 class="text-center text-white mt-5 mb-8"
        style="height: 150px;">{{ PricingContentResources["pricing.body.hero.title"] | render | async }}</h1>
</app-header>

    <div class="pricing-boxes">

        <div class="container">
            <div class="row">
                @for (pricingBox of PricingContentResources.PricingBoxes; track $index;) {
                    @let pricingBoxIndex = $index;
                        <div class="col-md mb-3 pt-3">
                            <!-- Card -->
                            <div class="card h-100 pb-3" [class.highlight]="$index === 1">
                                <div class="text-center mb-4 mt-4">
                                    @if ($index === 2) {
                                        <h3 class="mt-2 mb-2 display-5 font-weight-bold">{{ pricingBox.price | render | async }}</h3>
                                    }@else {
                                        <h3 class="mt-2 mb-2 display-5 font-weight-bold">{{ pricingBox.price | render | async}}
                                            <small>/{{ pricingBox.unit | render | async }}</small>
                                         </h3>
                                    }
                                    <h4 class="mt-3">{{ pricingBox.users | render | async }}</h4>
                                    <p class="card-text" [class.text-light]="$index === 1" [class.text-secondary]="$index !== 1">{{ pricingBox.subtitle | render | async }}</p>
                                </div>

                                <div class="card-body d-flex justify-content-center py-0 text-secondary mt-3">
                                    <!-- List Checked -->
                                    <ul class="list-unstyled ms-4">

                                        @for(feature of pricingBox.features; track $index) {
                                            <li class="mb-1" [class.text-light]="pricingBoxIndex === 1"
                                                [class.text-secondary]="pricingBoxIndex !== 1">
                                                <font-awesome-icon icon="fad fa-check-circle"
                                                                   style="--fa-primary-color: #1C7ED6; --fa-secondary-color:#D2DDEC;"></font-awesome-icon>
                                            {{ feature.title | render | async }}
                                            </li>
                                        }
                                    </ul>
                                    <!-- End List Checked -->
                                </div>

                                <div class="card-footer text-center">
                                    <div class="d-grid mb-2">
                                        <a routerLink="/get-started" class="btn" [class.btn-primary]="pricingBoxIndex !== 2" [class.btn-light]="pricingBoxIndex === 2">
                                            {{ pricingBox.callToAction | render | async  }}
                                        </a>
                </div>
                <p class="card-text small" [class.text-light]="$index === 1" [class.text-secondary]="$index !== 1">{{ pricingBox.subtitle2 | render | async }}</p>
            </div>
        </div>
        <!-- End Card -->
    </div>
                }

            </div>
        </div>

    </div>

    <div class="pricing-table mt-5">
        <div class="container">
            <!-- Heading -->
            <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                <h2>{{ PricingContentResources.PricingTable.title | render | async }}</h2>
            </div>
            <!-- End Heading -->

            <!-- Table -->
            <div class="table-responsive-lg w-lg-75 mx-lg-auto">
                <table class="table table-lg table-striped table-borderless table-nowrap table-vertical-border-striped">
                    <thead class="table-text-center">
                    <tr>
                        <th scope="col"></th>

                        <th scope="col">
                            <span class="d-block">{{ PricingContentResources.PricingTable.singleUser.title | render | async }}</span>
                            <span class="d-block text-muted small">{{ PricingContentResources.PricingTable.singleUser.monthlyPricing | render | async }}</span>
                        </th>

                        <th scope="col">
                                <span class="d-block">{{ PricingContentResources.PricingTable.users25.title | render | async }}
                                    <span
                                            class="badge bg-warning text-dark rounded-pill ms-1">{{ PricingContentResources.PricingTable.users25.popular | render | async }}</span></span>
                            <span class="d-block text-muted small">{{ PricingContentResources.PricingTable.users25.monthlyPricing | render | async }} </span>
                        </th>

                        <th scope="col">
                            <span class="d-block">{{ PricingContentResources.PricingTable.enterprise.title | render | async }}</span>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <th scope="row"
                            class="text-dark">{{ PricingContentResources.PricingTable.categories.accessCalculations | render | async }}
                        </th>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary"></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.salaryVsDividends.title | render | async }}
                            <button data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top"
                                    [attr.data-bs-content]="PricingContentResources.PricingTable.categories.salaryVsDividends.popup | render | async"
                                    data-bs-custom-class="popover-pricing" class="btn btn-link">
                                <font-awesome-icon
                                        class="far fa-info-circle"></font-awesome-icon>
                            </button>
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>


                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.carOwnership.title | render | async }}
                            <button data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top"
                                    [attr.data-bs-content]="PricingContentResources.PricingTable.categories.carOwnership.popup | render | async"
                                    data-bs-custom-class="popover-pricing" class="btn btn-link">
                                <font-awesome-icon
                                        class="far fa-info-circle"></font-awesome-icon>
                            </button>
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.rollover.title | render | async }}
                            <button data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top"
                                    [attr.data-bs-content]="PricingContentResources.PricingTable.categories.rollover.popup | render | async"
                                    data-bs-custom-class="popover-pricing" class="btn btn-link">
                                <font-awesome-icon
                                        class="far fa-info-circle"></font-awesome-icon>
                            </button>
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.sharesSelling.title | render | async }}
                            <button data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top"
                                    [attr.data-bs-content]="PricingContentResources.PricingTable.categories.sharesSelling.popup | render | async"
                                    data-bs-custom-class="popover-pricing" class="btn btn-link">
                                <font-awesome-icon
                                        class="far fa-info-circle"></font-awesome-icon>
                            </button>
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row"
                            class="text-dark">{{ PricingContentResources.PricingTable.categories.clientPortal.title | render | async }}
                        </th>
                        <td class="table-text-center text-primary"><span class="text-primary"><font-awesome-icon
                                class="fal fa-check-circle"></font-awesome-icon></span>
                            @if(this.getLocale() === LocaleEnum.French) {
                                Inclus (valeur de 500$)
                        } @else if (getLocale() === LocaleEnum.English) {
                                Included ($500 value)
                            }
                        </td>
                        <td class="table-text-center text-primary"><span class="text-primary"><font-awesome-icon
                                class="fal fa-check-circle"></font-awesome-icon></span>
                            @if(this.getLocale() === LocaleEnum.French) {
                                Inclus (valeur de 500$)
                        } @else if (getLocale() === LocaleEnum.English) {
                                Included ($500 value)
                            }
                        </td>
                        <td></td>
                    </tr>


                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.clientPortal.access | render | async }}
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.clientPortal.questionnaire.title | render | async }}
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark ps-10">{{ PricingContentResources.PricingTable.categories.clientPortal.questionnaire.tax2022 | render | async }}
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                    </tr>


                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.clientPortal.questionnaire.number | render | async }}
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark ps-5">{{ PricingContentResources.PricingTable.categories.clientPortal.questionnaire.numberOfClients | render | async}}
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                            @if(this.getLocale() === LocaleEnum.French) {Illimité} @else if (getLocale() === LocaleEnum.English) {
                                Unlimited
                            }
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark">{{ PricingContentResources.PricingTable.categories.securedHosting | render | async }}
                        </th>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark">{{ PricingContentResources.PricingTable.categories.customColors | render | async }}
                        </th>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark">{{ PricingContentResources.PricingTable.categories.dedicatedCloudHosting | render | async }}
                        </th>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark">{{ PricingContentResources.PricingTable.categories.internalToolsIntegration | render | async }}
                        </th>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"
                            class="text-dark">{{ PricingContentResources.PricingTable.categories.chat | render | async }}
                        </th>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary"></td>
                        <td class="table-text-center text-primary">
                            <font-awesome-icon icon="fal fa-check-circle"></font-awesome-icon>
                        </td>
                    </tr>


                    <tr>
                        <th scope="row" class="text-dark"></th>
                        <td>
                            <a routerLink="get-started" class="btn btn-primary btn-sm btn-transition">
                                @if(this.getLocale() === LocaleEnum.French) {Choisir} @else if (getLocale() === LocaleEnum.English) {
                                    Choose
                                }
                            </a>
                        </td>
                        <td>
                            <a routerLink="get-started" class="btn btn-primary btn-sm btn-transition"> @if(this.getLocale() === LocaleEnum.French) {Choisir} @else if (getLocale() === LocaleEnum.English) {
                                Choose
                            }</a>
                        </td>
                        <td>
                            <a routerLink="get-started"
                            class="btn btn-white btn-sm btn-transition"> @if(this.getLocale() === LocaleEnum.French) {Choisir} @else if (getLocale() === LocaleEnum.English) {
                                Choose
                            }</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!-- End Table -->
        </div>
    </div>
    <!-- End Pricing Table -->


<!-- Pricing Table -->


<!-- FAQ -->
<div class="container content-space-2 content-space-lg-3 mt-5">
    <!-- Heading -->
    <div class="w-md-75 w-lg-50 text-center mx-lg-auto mb-5 mb-md-9">
        <h2>{{ PricingContentResources["pricing.body.faq.title"] | render | async }}</h2>
    </div>
    <!-- End Heading -->

    <div class="row justify-content-md-center">
        @for(question of PricingContentResources.PricingBodyFaqQuestions; track $index) {
            <div class="col-sm-6 col-lg-5 mb-3 mb-md-5">
                <h5>{{ question.title | render | async }}</h5>
                <p>{{ question.description | render | async }}</p>
            </div>
        }
    </div>
    <!-- End Row -->
</div>
<!-- End FAQ -->