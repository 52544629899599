import {TranslateService} from "@ngx-translate/core";
import {AlertMessageModel} from "../models/alert-message.model";
import {LogModel} from "../models/log.model";
import {Injectable} from "@angular/core";
import {SeverityTypeEnum} from "../enums/severity-type.enum";

@Injectable()
export class LogFactory {
    public constructor(private readonly translateService: TranslateService) {
    }

    createFromAlertMessage(alertMessage: AlertMessageModel): LogModel {
        return new LogModel(alertMessage.severity, alertMessage.getTranslatedMessage(this.translateService.currentLang))
    }

    static createFromError(error: Error): LogModel {
        return new LogModel(SeverityTypeEnum.Error, error.message, {thrownError: error})
    }
}