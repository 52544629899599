import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SupportArticleEnum} from "../../content/enums/support-article.enum";
import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../components/base.component";
import {LocaleManager} from "../../managers/locale.manager";
import {RouteManager} from "../../managers/route.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {
  SupportArticleContentResourceInterface
} from "../../content/interfaces/support-article-content-resource.interface";
import {LoadingEnum} from "../../enums/loading.enum";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {RouteEnum} from "../../enums/route.enum";
import {LocaleEnum} from "../../enums/locale.enum";
import {SupportArticleSlugConstants} from "../../content/constants/support-article-slug.constants";
import {RolloverContentResources} from "../../content/resources/articles/rollover.content-resources";
import {isPlatformBrowser} from "@angular/common";
import {Meta, Title} from "@angular/platform-browser";
import {HeaderStyleEnum} from "../../enums/header-style.enum";

@Component({
  selector: 'app-support-article',
  templateUrl: './support-article.component.html',
  styleUrl: './support-article.component.scss'
})
export class SupportArticleComponent extends BaseComponent implements OnInit {
  get article(): SupportArticleContentResourceInterface | undefined {
    return this.content as SupportArticleContentResourceInterface;
  }

  get articleKeyname(): SupportArticleEnum | undefined {
    return this._articleKeyname;
  }

  set articleKeyname(value: SupportArticleEnum) {
    this._articleKeyname = value;

    switch (value) {
      case SupportArticleEnum.SuccessfullyOrchestrateRolloversInHarmonyWithCanadianAndQuebecTaxLaws:
        this.content = RolloverContentResources;
        break;
    }
  }

  private _articleKeyname?: SupportArticleEnum

  linkedInUrl: string = "";
  twitterUrl: string = "";

  constructor(
      private readonly activatedRoute: ActivatedRoute,
      @Inject(PLATFORM_ID) private platformId: Object,
      localeManager: LocaleManager,
      routeManager: RouteManager,
      contentManager: ContentManager,
      titleService: Title,
      meta: Meta
      ) {
    super(localeManager,
        routeManager,
        contentManager,
        titleService,
        meta,
    )
  }

  override ngOnInit() {
    super.ngOnInit();

    this.registerSubscription(this.activatedRoute.data.subscribe(data => {
      if (!data.hasOwnProperty('articleKeyname')) {
        return;
      }

      this.articleKeyname = data['articleKeyname'];
    }));

    this.refresh();
  }

  refresh() {
    this.setLinkedInUrl();
    this.setTwitterUrl();
  }

  setLinkedInUrl() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${location.protocol}//${location.host}${location.pathname}`
  }

  setTwitterUrl() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.twitterUrl = `http://twitter.com/share?text=Voici un article intéressant&url=${location.protocol}//${location.host}${location.pathname}&hashtags=optitax,taxtech`
  }

  protected readonly LoadingEnum = LoadingEnum;
  protected readonly FetchPriorityEnum = FetchPriorityEnum;
  protected readonly RouteEnum = RouteEnum;
  protected readonly LocaleEnum = LocaleEnum;
  protected readonly HeaderStyleEnum = HeaderStyleEnum;
}
