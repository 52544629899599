export enum CalculationKeynameEnum {
    VehicleOwnership = "VEHICLE_OWNERSHIP",
    Section84_1 = "SECTION_84_1",
    Section84_3 = "SECTION_84_3",
    Rollover = "ROLLOVER",
    SalaryContributions = "SALARY_CONTRIBUTIONS",
    QuickSalaryContributions = "QUICK_SALARY_CONTRIBUTIONS",
    Schedule8 = "SCHEDULE_8",
    Schedule10 = "SCHEDULE_10",
    ScheduleP = "SCHEDULE_P",
    ScheduleR = "SCHEDULE_R",
    FSS = "FSS",
    RRQ = "RRQ",
    CNT = "CNT",
    CNESST = "CNESST",
    IncomeTaxPersonalFederal = "INCOME_TAX_PERSONAL_FEDERAL",
    IncomeTaxPersonalQuebec = "INCOME_TAX_PERSONAL_QUEBEC",
    QuickIncomeTaxPersonal = "QUICK_INCOME_TAX_PERSONAL",
    IncomeTaxPersonal = "INCOME_TAX_PERSONAL",
    T778 = "T778",
    CorporateTaxQuebec = "CORPORATE_TAX_QUEBEC",
    CorporateTaxFederal = "CORPORATE_TAX_FEDERAL",
    CorporateTax = "CORPORATE_TAX",
    SalaryDividend = "SALARY_DIVIDEND",
    SalaryDividendSimulation = "SALARY_DIVIDEND_SIMULATION",
    Vehicle = "VEHICLE",
    QppPension = "QPP_PENSION",
    QppPensionForSalaryDividend = "QPP_PENSION_FOR_SALARY_DIVIDEND",
    RenteRRQ = "RENTE_RRQ",
}
