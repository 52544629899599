import {
    IsBoolean,
    IsNotEmpty,
    IsInt,
    IsString,
    Min,
    ValidateIf,
    ValidateNested,
    IsOptional
} from "@pristine-ts/class-validator";
import {Section84_1ShareClassOptions} from "./section-84_1-share-class.options";
import {advancedFormControl} from "../../../decorators/advanced-form-control.decorator";
import {CalculationRunOptions} from "../calculation-run.options";
import {translationBaseKey} from "../../../decorators/translation-base-key.decorator";
import {CalculationKeynameEnum} from "../../../enums/calculations/calculation-keyname.enum";
import {FieldTypeEnum} from "../../../enums/field-type.enum";
import { v4 as uuid } from 'uuid';
import {Section84_1MetadataOptions} from "./section-84_1-metadata.options";
import {advancedFormGroup} from "../../../decorators/advanced-form-group.decorator";
import {advancedFormArray} from "../../../decorators/advanced-form-array.decorator";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("section-84_1-options")
export class Section84_1Options extends CalculationRunOptions {
    @advancedFormControl({})
    calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.Section84_1;

    @advancedFormControl({
        fieldType: FieldTypeEnum.String
    })
    @IsString({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    id: string = uuid();

    @advancedFormControl({
        labelTitle: {
            fr: "Acheteur est la société dont les actions sont vendues",
            en: "Is the buyer the corporation whose shares are being sold?"
        },
        tooltip: {
            fr: "Indique si l'acheteur est la société dont les actions sont vendues.",
            en: "Indicate whether the buyer is the corporation whose shares are being sold.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    isBuyerTheCorporationWhoseSharesAreBeingSold: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Actions sont des actions du capital-actions d'une société résidant au Canada",
            en: "Are the shares part of the shares capital of a Canadian enterprise?"
        },
        tooltip: {
            fr: "Indique si les actions font partie du capital-actions d'une entreprise canadienne.",
            en: "Indicate whether the shares are part of the shares capital of a Canadian enterprise.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    areTransferredSharesCanadianShares: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Actions cédées sont des immobilisations pour le vendeur",
            en: "Are the shares sold capital property to the seller?"
        },
        tooltip: {
            fr: "Indique si les actions vendues sont des immobilisations pour le vendeur.",
            en: "Indicate whether the shares sold are capital property to the seller.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    areTransferredSharesCapitalPropertyForTheSeller: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Vendeur est un particulier ou une fiducie",
            en: "Is the seller an individual or a trust?"
        },
        tooltip: {
            fr: "Indique si le vendeur est un particulier ou une fiducie.",
            en: "Indicate whether the seller is an individual or a trust.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    isSellerAnIndividualOrATrust: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Vendeur réside au Canada",
            en: "Is the seller a Canadian resident?"
        },
        tooltip: {
            fr: "Indique si le vendeur est un résident canadien.",
            en: "Indicate whether the seller is a Canadian resident.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    isSellerACanadianResident: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Acheteur est une société",
            en: "Is the buyer a corporation?"
        },
        tooltip: {
            fr: "Indique si l'acheteur est une société.",
            en: "Indicate whether the buyer is a corporation.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    isBuyerACorporation: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Vendeur et acheteur ont un lien de dépendance",
            en: "Are the seller and buyer at arm's length?"
        },
        tooltip: {
            fr: "Indique si le vendeur et l'acheteur sont liés de dépendance.",
            en: "Indicate whether the seller and buyer are at arm's length.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    areBuyerAndSellerAtArmsLength: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Société dont les actions sont vendues devient à distance immédiatement après la transaction",
            en: "Is the corporation whose shares are being sold becoming at arm's length to the buyer immediately after the transaction?"
        },
        tooltip: {
            fr: "Indique si la société dont les actions sont vendues devient à distance immédiatement après la transaction avec l'acheteur.",
            en: "Indicate whether the corporation whose shares are being sold is becoming at arm's length to the buyer immediately after the transaction.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Actions acquises après 1971",
            en: "Were the shares acquired after 1971?"
        },
        tooltip: {
            fr: "Indique si les actions ont été acquises après 1971.",
            en: "Indicate whether the shares were acquired after 1971.",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @ValidateIf(o =>
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCapitalPropertyForTheSeller &&
        o.isSellerAnIndividualOrATrust &&
        o.isSellerACanadianResident &&
        o.isBuyerACorporation &&
        o.areBuyerAndSellerAtArmsLength &&
        o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction
    )
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    wereSharesAcquiredAfter1971?: boolean;

    @advancedFormControl({
        labelTitle: {
            fr: "Juste valeur marchande des actions au jour de l'évaluation",
            en: "Fair market value of the shares on the valuation day"
        },
        tooltip: {
            fr: "Entrez la juste valeur marchande des actions à la date d'évaluation.",
            en: "Enter the fair market value of the shares on the valuation day.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @ValidateIf(o =>
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCapitalPropertyForTheSeller &&
        o.isSellerAnIndividualOrATrust &&
        o.isSellerACanadianResident &&
        o.isBuyerACorporation &&
        o.areBuyerAndSellerAtArmsLength &&
        o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction &&
        o.wereSharesAcquiredAfter1971 === false
    )
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    fairMarketValueOnValuationDayOfTransferredShares?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Coût effectif des actions au 1er janvier 1972 selon RAIR 26(13)",
            en: "Effective cost of transferred shares as of January 1, 1972 under ITAR 26(13)"
        },
        tooltip: {
            fr: "Entrez le coût effectif des actions au 1er janvier 1972 selon RAIR 26(13).",
            en: "Enter the effective cost of the transferred shares as of January 1, 1972 under ITAR 26(13).",
        },
        fieldType: FieldTypeEnum.Money
    })
    @ValidateIf(o =>
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCapitalPropertyForTheSeller &&
        o.isSellerAnIndividualOrATrust &&
        o.isSellerACanadianResident &&
        o.isBuyerACorporation &&
        o.areBuyerAndSellerAtArmsLength &&
        o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction &&
        o.wereSharesAcquiredAfter1971 === false
    )
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    actualCostOnJanuary1972OfTransferredShares?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Dividendes reçus sur lesquels le choix du paragraphe 83(1) a été effectué",
            en: "Dividends received on which the subsection 83(1) was made"
        },
        tooltip: {
            fr: "Entrez les dividendes reçus sur lesquels le choix du paragraphe 83(1) a été effectué.",
            en: "Enter the dividends received on which the subsection 83(1) was made.",
        },
        fieldType: FieldTypeEnum.Money
    })
    @ValidateIf(o =>
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCapitalPropertyForTheSeller &&
        o.isSellerAnIndividualOrATrust &&
        o.isSellerACanadianResident &&
        o.isBuyerACorporation &&
        o.areBuyerAndSellerAtArmsLength &&
        o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction &&
        o.wereSharesAcquiredAfter1971 === false
    )
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    receivedDividendChoice83OnTransferredShares?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Prix de base rajusté des actions",
            en: "Adjusted cost base of the transferred shares"
        },
        tooltip: {
            fr: "Entrez le prix de base rajusté des actions.",
            en: "Enter the adjusted cost base of the transferred shares.",
        },
        fieldType: FieldTypeEnum.Money
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    adjustedCostBaseOfTransferredShares: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Produit de disposition",
            en: "Proceeds of disposition"
        },
        tooltip: {
            fr: "Entrez le produit de disposition.",
            en: "Enter the proceeds of disposition.",
        },
        fieldType: FieldTypeEnum.Money
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    proceedsOfDisposition: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Gains en capital déclarés après 1984 sur l'action par le contribuable ou un particulier avec qui le contribuable avait un lien de dépendance et qui ont donné droit à la DGC",
            en: "Capital gains reported after 1984 on the share by the taxpayer or an individual with whom the taxpayer did deal at arm's length that gave rise to the CGD"
        },
        tooltip: {
            fr: "Entrez les gains en capital déclarés après 1984 sur l'action qui ont donné droit à la déduction pour gains en capital.",
            en: "Enter the capital gains reported after 1984 on the share that gave rise to the Capital Gains Deduction (CGD).",
        },
        fieldType: FieldTypeEnum.Money
    })
    @ValidateIf(o =>
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCapitalPropertyForTheSeller &&
        o.isSellerAnIndividualOrATrust &&
        o.isSellerACanadianResident &&
        o.isBuyerACorporation &&
        o.areBuyerAndSellerAtArmsLength &&
        o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction
    )
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    capitalGainsOnTheShareDeclaredAfter1984?: number;

    @advancedFormArray({
        emptyNumberOfChildrenFormToCreate: 0,
        elementClassType: Section84_1ShareClassOptions,
    })
    @ValidateNested()
    shareClasses: Section84_1ShareClassOptions[] = [];

    @advancedFormControl({
        labelTitle: {
            fr: "Capital versé des actions cédées",
            en: "Paid-up capital of the gifted shares"
        },
        tooltip: {
            fr: "Entrez le capital versé des actions cédées.",
            en: "Enter the paid-up capital of the gifted shares.",
        },
        fieldType: FieldTypeEnum.Money
    })
    @ValidateIf(o =>
        o.areTransferredSharesCanadianShares &&
        o.areTransferredSharesCapitalPropertyForTheSeller &&
        o.isSellerAnIndividualOrATrust &&
        o.isSellerACanadianResident &&
        o.isBuyerACorporation &&
        o.areBuyerAndSellerAtArmsLength &&
        o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction
    )
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    paidUpCapitalOfGiftedShares?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Montant de la contrepartie autre qu'en actions",
            en: "Amount of the non-share consideration"
        },
        tooltip: {
            fr: "Entrez le montant de la contrepartie autre qu'en actions.",
            en: "Enter the amount of the non-share consideration.",
        },
        fieldType: FieldTypeEnum.Money
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    nonShareConsideration: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Frais de vente",
            en: "Selling fees"
        },
        tooltip: {
            fr: "Entrez les frais de vente.",
            en: "Enter the selling fees.",
        },
        fieldType: FieldTypeEnum.Money
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    sellingFees: number;

    @advancedFormGroup({
        classType: Section84_1MetadataOptions
    })
    @IsOptional()
    metadata?: Section84_1MetadataOptions;
}
