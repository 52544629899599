<header [class.homepage]="headerStyle === HeaderStyleEnum.HomePage"
        [class.default]="headerStyle === HeaderStyleEnum.Default"
        [class.support]="headerStyle === HeaderStyleEnum.Support"
        [class.bg-dark]="headerStyle === HeaderStyleEnum.Support"
>
    <div id="language-switcher-bar"
         [class.d-flex]="showLanguageSwitcherBar"
         class="bg-dark text-center text-white pt-2 pb-2 d-none"
         style="margin-left: -15px; margin-right: -15px;">
        @if (localeManager.getCurrentLocale() === LocaleEnum.French) {
            <div class="align-self-center" style="margin-left: calc(50% - 90px)">
                View this site in <a [routerLink]="this.routeManager.getCurrentRouteInLocale(LocaleEnum.English)"
                                     class="btn btn-primary btn-sm ms-2">English</a>
            </div>

            <div>
                <button class="btn btn-link ms-3" (click)="doNotShowLanguageSwitcherBar()">Do not show this again
                </button>
            </div>
        } @else if (localeManager.getCurrentLocale() === LocaleEnum.English) {
            <div class="align-self-center" style="margin-left: calc(50% - 90px)">
                Consulter ce site en <a [routerLink]="this.routeManager.getCurrentRouteInLocale(LocaleEnum.English)"
                                        class="btn btn-primary btn-sm ms-2">Français</a>
            </div>

            <div>
                <button class="btn btn-link ms-3" (click)="doNotShowLanguageSwitcherBar()">Ne plus jamais montrer
                </button>
            </div>
        }
    </div>

    <div class="container">
        <nav id="main-navbar" [class.collapsed]="this.collapsed">
            <div class="brand">
                <!--                <a href="{{ path("index") }}">-->
                <!--                {{ component('image', {-->
                <!--                size: 300,-->
                <!--                url: "assets/images/logos/logo-white.png",-->
                <!--                fetchPriority: "high",-->
                <!--                loading: "eager",-->
                <!--            }) }}-->
                <!--                </a>-->
                <a routerLink="/">
                    <app-image [url]="'images/logos/logo-white.png'"
                               [fetchPriority]="FetchPriorityEnum.High"
                               [loading]="LoadingEnum.Eager"
                    ></app-image>
                </a>
            </div>

            <ul class="main-links">
                <li>
                    <a [routerLink]="routeManager.getRouteInCurrentLocale(RouteEnum.Calculations)">{{ HeaderContentResources["header.main.calculations"] | render | async }}</a>
                </li>
                <li>
                    <a [routerLink]="routeManager.getRouteInCurrentLocale(RouteEnum.Pricing)">{{ HeaderContentResources["header.main.pricing"] | render | async }}</a>
                </li>
                <li>
                    <a [routerLink]="routeManager.getRouteInCurrentLocale(RouteEnum.ContactUs)">{{ HeaderContentResources['header.main.contact-us'] | render | async }}</a>
                </li>
            </ul>

            <ul>
                <li><a href="https://app.optitax.ca"
                       class="btn btn-primary">{{ HeaderContentResources['header.main.login'] | render | async }}</a>
                </li>
                <li class="ms-2 me-1"><a href="https://client.optitax.ca"
                                         class="btn btn-link text-white">{{ HeaderContentResources['header.main.client-portal'] | render | async }}</a>
                </li>
                @if (localeManager.getCurrentLocale() === LocaleEnum.French) {
                    <li><a [routerLink]="this.routeManager.getCurrentRouteInLocale(LocaleEnum.English)"
                           class="btn btn-link text-white" style="font-size: 0.8rem; margin-top: 3px;">English</a></li>
                } @else if (localeManager.getCurrentLocale() === LocaleEnum.English) {
                    <li><a [routerLink]="this.routeManager.getCurrentRouteInLocale(LocaleEnum.French)"
                           class="btn btn-link text-white" style="font-size: 0.8rem; margin-top: 3px;">Français</a></li>
                }
            </ul>
            <a class="menu-toggler" href="#" (click)="toggleCollapsed()">
                <i class="bi bi-list"></i></a>
        </nav>

        <div class="hero">
            <ng-content></ng-content>
        </div>
    </div>
</header>
