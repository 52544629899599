import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {filter} from "rxjs/operators";
import {AlertMessageModel} from "../models/alert-message.model";
import {LoggingService} from "../services/logging.service";
import {LogFactory} from "../factories/log.factory";
import {SeverityTypeEnum} from "../enums/severity-type.enum";
import {AlertContextEnum} from "../enums/alert-context.enum";

@Injectable()
export class AlertMessageStore {
    public readonly store: {[context: string]: BehaviorSubject<AlertMessageModel | undefined>} = {};

    constructor(private readonly loggingService: LoggingService, private readonly logFactory: LogFactory) {
    }

    getGlobalErrorsObservable(): Observable<AlertMessageModel | undefined> {
        return this.store[AlertContextEnum.Global].pipe(filter(alertMessage => alertMessage !== undefined && alertMessage.target === undefined && alertMessage.severity >= SeverityTypeEnum.Error));
    }

    getTargetedErrorsObservable(context: AlertContextEnum): Observable<AlertMessageModel | undefined> {
        return this.store[context].pipe(filter(alertMessage => alertMessage !== undefined && alertMessage.target !== undefined));
    }

    saveAll(alertMessages: AlertMessageModel[]) {
        alertMessages.forEach(alertMessage => {
            this.save(alertMessage);
        })
    }

    save(alertMessage: AlertMessageModel) {
        this.loggingService.log(this.logFactory.createFromAlertMessage(alertMessage));

        if (this.store.hasOwnProperty(alertMessage.context) === false) {
            this.store[alertMessage.context] = new BehaviorSubject<AlertMessageModel | undefined>(alertMessage);
        } else {
            this.store[alertMessage.context].next(alertMessage);
        }
    }

    public get(context: AlertContextEnum): Observable<AlertMessageModel | undefined> {
        if (this.store.hasOwnProperty(context) === false) {
            this.store[context] = new BehaviorSubject<AlertMessageModel | undefined>(undefined);
        }

        return this.store[context];
    }

    public setConsumed(context: AlertContextEnum): void {
        if (this.store.hasOwnProperty(context)) {
            this.store[context].next(undefined);
        }
    }

    public removeFromStore(context: AlertContextEnum): void {
        this.store[context].complete();
        delete this.store[context];
    }
}