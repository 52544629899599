import { DataMappingNode } from "../nodes/data-mapping.node";
import { DataNormalizerAlreadyAdded } from "../errors/data-normalizer-already-added.error";
import { DataBeforeMappingInterceptorAlreadyAddedError } from "../errors/data-before-mapping-interceptor-already-added.error";
import { DataAfterMappingInterceptorAlreadyAddedError } from "../errors/data-after-mapping-interceptor-already-added.error";
import { DataMappingLeaf } from "../nodes/data-mapping.leaf";
import { BaseDataMappingNode } from "../nodes/base-data-mapping.node";
import { DataMappingNodeTypeEnum } from "../enums/data-mapping-node-type.enum";
export class DataMappingBuilder extends BaseDataMappingNode {
  constructor() {
    super(...arguments);
    this.normalizers = [];
    this.beforeMappingInterceptors = [];
    this.afterMappingInterceptors = [];
  }
  /**
   * This method adds a normalizer to the root that will be applied on each node (unless they explicitly exclude to do
   * so).
   *
   * @param normalizerUniqueKey
   * @param options
   */
  addNormalizer(normalizerUniqueKey, options) {
    if (this.hasNormalizer(normalizerUniqueKey)) {
      throw new DataNormalizerAlreadyAdded("The data normalizer '" + normalizerUniqueKey + "' has already been added to this builder.", normalizerUniqueKey, options);
    }
    this.normalizers.push({
      key: normalizerUniqueKey,
      options
    });
    return this;
  }
  /**
   * This method returns whether there's a normalizer for the specified key or not.
   *
   * @param normalizerUniqueKey
   */
  hasNormalizer(normalizerUniqueKey) {
    return this.normalizers.find(element => element.key === normalizerUniqueKey) !== undefined;
  }
  /**
   * This method adds an interceptor that will be executed **before** the object is mapped.
   *
   * @param key
   * @param options
   */
  addBeforeMappingInterceptor(key, options) {
    if (this.hasBeforeMappingInterceptor(key)) {
      throw new DataBeforeMappingInterceptorAlreadyAddedError("The before row transform interceptor has already been added to this Tree.", key, options);
    }
    this.beforeMappingInterceptors.push({
      key,
      options
    });
    return this;
  }
  /**
   * This method returns whether a **before** interceptor already exists.
   * @param key
   */
  hasBeforeMappingInterceptor(key) {
    return this.beforeMappingInterceptors.find(element => element.key === key) !== undefined;
  }
  /**
   * This method adds an interceptor that will be executed **after** the object is mapped.
   *
   * @param key
   * @param options
   */
  addAfterMappingInterceptor(key, options) {
    if (this.hasAfterMappingInterceptor(key)) {
      throw new DataAfterMappingInterceptorAlreadyAddedError("The after row transform interceptor has already been added to this Tree.", key, options);
    }
    this.afterMappingInterceptors.push({
      key,
      options
    });
    return this;
  }
  /**
   * This method returns whether a **after** interceptor already exists.
   * @param key
   */
  hasAfterMappingInterceptor(key) {
    return this.afterMappingInterceptors.find(element => element.key === key) !== undefined;
  }
  /**
   * This property creates a new DataMappingLeaf and returns it. It doesn't add it yet. To do so, the `end()` method
   * must be called.
   */
  add() {
    return new DataMappingLeaf(this, this);
  }
  /**
   * This method adds a nesting level. This should be used when the property contains an object and you want to map
   * this object into another object.
   */
  addNestingLevel() {
    return new DataMappingNode(this, this);
  }
  /**
   * This method adds an array of Scalar allowing you to apply the normalizer on each scalar in the array. The
   * `sourceProperty` and `destinationProperty` correspond to the name of the property that is an array. But, the
   * values in the array will be normalized using the normalizer.
   *
   */
  addArrayOfScalar() {
    return new DataMappingLeaf(this, this, DataMappingNodeTypeEnum.ScalarArray);
  }
  /**
   * This method adds an array of objects allowing to define a node for each property in the object. Each object in
   * the array will be treated as being the same.
   */
  addArrayOfObjects() {
    return new DataMappingNode(this, this, DataMappingNodeTypeEnum.ObjectArray);
  }
  /**
   * This method is called at the end just to make it nice since all the nodes will have one, it's nice
   * that the builder has one too.
   */
  end() {
    return this;
  }
  /**
   * This method imports a schema.
   *
   * @param schema
   */
  import(schema) {
    this.normalizers = schema.normalizers;
    this.beforeMappingInterceptors = schema.beforeMappingInterceptors;
    this.afterMappingInterceptors = schema.afterMappingInterceptors;
    const nodes = schema.nodes;
    for (const key in nodes) {
      if (nodes.hasOwnProperty(key) === false) {
        continue;
      }
      const nodeInfo = nodes[key];
      const type = nodeInfo["_type"];
      switch (type) {
        case DataMappingNodeTypeEnum.ScalarArray:
        case DataMappingNodeTypeEnum.Leaf:
          const leaf = new DataMappingLeaf(this, this, type);
          leaf.import(nodeInfo);
          this.nodes[leaf.sourceProperty] = leaf;
          continue;
        case DataMappingNodeTypeEnum.Node:
        case DataMappingNodeTypeEnum.ObjectArray:
          const node = new DataMappingNode(this, this, type);
          node.import(nodeInfo);
          this.nodes[node.sourceProperty] = node;
          continue;
      }
    }
  }
  /**
   * This method exports this node.
   */
  export() {
    const nodes = this.nodes;
    for (const key in nodes) {
      if (nodes.hasOwnProperty(key) === false) {
        continue;
      }
      nodes[key] = nodes[key].export();
    }
    return {
      "nodes": nodes,
      "normalizers": this.normalizers,
      "beforeMappingInterceptors": this.beforeMappingInterceptors,
      "afterMappingInterceptors": this.afterMappingInterceptors
    };
  }
}
