import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {SupportArticleSlugConstants} from "../constants/support-article-slug.constants";
import {LocaleEnum} from "../../enums/locale.enum";
import {SupportArticleEnum} from "../enums/support-article.enum";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export type Category = {
    image: ImageContentModel;

    description: MarkdownContentModel;

    title: TextContentModel;

    callToAction: TextContentModel;

    link?: TextContentModel;
}

export const SupportContentResources: {
    Categories: Category[],

} & ContentResourceInterface & { [id in string]: any } = {

    LastModified: new TextContentModel(
        {"fr": "2024-12-03", "en": "2024-12-03"}
    ),

    Title: new TextContentModel(
        {"fr": "Centre d'aide - Optitax", "en": "Support - Optitax"}
    ),

    Meta: {
        Description: new TextContentModel(
            {"fr": "Centre d'aide d'Optitax\n", "en": "Optitax support centre\n"}
        ),

        Keywords: new TextContentModel(
            {"fr": "centre d'aide optitax, support", "en": "optitax support center"}
        ),
    },

    "support.body.hero.title": new TextContentModel(
        {"fr": "Comment pouvons nous vous aider?", "en": "How can we help you?"}
    ),

    "support.body.hero.description": new TextContentModel(
        {
            "fr": "Choisissez une catégorie pour trouver une réponse rapidement.",
            "en": "Choose a category to quickly find an answer"
        }
    ),

    Categories: [
        {
            title: new TextContentModel(
                {"fr": "Roulement", "en": "Tax Rollover"}
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/rollover.svg",
                        en: "images/illustrations/rollover.svg",
                    }),
                }
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "Avec Optitax, les professionnels peuvent calculer de manière précise et efficiente les implications fiscales d'un roulement fiscal, offrant ainsi des avantages significatifs en termes de planification fiscale et de réduction des charges fiscales à long terme.",
                    "en": "With Optitax, professionals can accurately and efficiently calculate the tax implications of a tax rollover, thereby offering significant benefits in terms of tax planning and long-term tax reduction."
                }
            ),
            callToAction: new TextContentModel(
                {"fr": "En savoir plus", "en": "Read more"}
            ),
            link: new TextContentModel({
                fr: `support/articles/${SupportArticleSlugConstants[LocaleEnum.French][SupportArticleEnum.SuccessfullyOrchestrateRolloversInHarmonyWithCanadianAndQuebecTaxLaws]}`,
                en: `support/articles/${SupportArticleSlugConstants[LocaleEnum.English][SupportArticleEnum.SuccessfullyOrchestrateRolloversInHarmonyWithCanadianAndQuebecTaxLaws]}`,
            })
        },
        {
            title: new TextContentModel(
                {"fr": "Vente d’actions", "en": "Stocks Sale"}
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/shares_selling.svg",
                        en: "images/illustrations/shares_selling.svg",
                    }),
                }
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "Optitax permet d'effectuer une vente d’actions de manière efficace et précise. En analysant les caractéristiques fiscales des actions vendues et les règles fiscales applicables, Optitax offre une évaluation complète des conséquences fiscales de la vente d'actions.",
                    "en": "Optitax allows for an efficient and accurate execution of a stock sale. By analyzing the tax characteristics of the shares being sold and the applicable tax rules, Optitax provides a comprehensive evaluation of the tax consequences of the stock sale."
                }
            ),
            callToAction: new TextContentModel(
                {"fr": "En savoir plus", "en": "Read more"}
            ),
        },
        {
            title: new TextContentModel(
                {"fr": "Détention automobile", "en": "Vehicle Ownership"}
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/car_ownership.svg",
                        en: "images/illustrations/car_ownership.svg",
                    }),
                }
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "En prenant en compte les frais d'utilisation du véhicule, Optitax permet de comparer les scénarios de détention automobile, d'identifier le plus avantageux fiscalement et de prendre des décisions éclairées en matière de détention automobile pour optimiser la situation financière.",
                    "en": "By considering vehicle usage expenses, Optitax allows for comparing different scenarios of vehicle ownership, identifying the most tax advantageous one, and making informed decisions to optimize the financial situation."
                }
            ),
            callToAction: new TextContentModel(
                {"fr": "En savoir plus", "en": "Read more"}
            ),
        },
        {
            title: new TextContentModel(
                {"fr": "Rémunération: Salaire ou dividendes", "en": "Compensation: Salary vs dividends"}
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/dividends_vs_salary.svg",
                        en: "images/illustrations/dividends_vs_salary.svg",
                    }),
                }
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "Optitax est utilisé pour calculer la proportion optimale de salaire et de dividendes à verser, en prenant en compte les considérations fiscales et les avantages pour l'individu ou l'entreprise. Cela permet de prendre des décisions éclairées en matière de rémunération, tout en maximisant les avantages fiscaux.",
                    "en": "Optitax is used to calculate the optimal proportion of salary and dividends to be paid, taking into account tax considerations and the benefits for individuals or companies. This allows for making informed decisions regarding compensation while maximizing tax advantages."
                }
            ),
            callToAction: new TextContentModel(
                {"fr": "En savoir plus", "en": "Read more"}
            ),
        }
    ],
}
