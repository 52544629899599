import {APP_INITIALIZER, NgModule, ErrorHandler as AngularErrorHandler} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IndexedDbService} from "./services/indexed-db.service";
import {LocalStorageService} from "./services/local-storage.service";
import {AlertMessageStore} from "./stores/alert-message.store";
import {LoggingService} from "./services/logging.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {LogFactory} from "./factories/log.factory";
import {AlertMessageComponent} from "./components/alert-message/alert-message.component";
import {AlertMessagesComponent} from "./components/alert-messages/alert-messages.component";
import {MenuService} from "./services/menu.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CurrencyMaskModule} from "ng2-currency-mask";
import {BrowserModule} from "@angular/platform-browser";
import {FlagManager} from "./managers/flag.manager";
import {FlagEnabledGuard} from "./guards/flag-enabled.guard";
import {DebugUtils} from "./utils/debug.utils";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DebugBarComponent} from "./components/debug-bar/debug-bar.component";
import {DateUtils} from "./utils/date.utils";
import {ErrorsUtils} from "./utils/errors.utils";
import {NumberUtils} from "./utils/number.utils";
import {BrowserUtils} from "./utils/browser.utils";
import {ErrorHandler} from "./handlers/error.handler";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {LifecycleManager} from "./managers/lifecycle.manager";
import {TransformerHttpInterceptor} from "./interceptors/transformer.http-interceptor";
import {TableComponent} from "./components/table/table.component";
import {
    AutoDataMappingBuilder,
    DataMapper,
    DateNormalizer,
    NumberNormalizer,
    StringNormalizer
} from "@pristine-ts/data-mapping-common";
import {SplashScreenComponent} from "./components/splash-screen/splash-screen.component";
import {CacheStorageService} from "./services/cache-storage.service";
import {MagienoApiController} from "./api-controllers/magieno.api-controller";
import {MagienoObservabilityManager} from "./managers/magieno-observability.manager";
import {EventManager} from "./managers/event.manager";
import {ExportResourceProvider} from "./providers/export-resource.provider";
import {RampUpApiController} from "./api-controllers/ramp-up.api-controller";
import {LifecycleListenerInterfaceToken} from "./interfaces/lifecycle-listener.interface";
import {PlatformRampUpLifecycleListener} from "./listeners/platform-ramp-up-lifecycle.listener";
import {FontAwesomeIconComponent} from "./components/font-awesome-icon/font-awesome-icon.component";
import {OptitaxFileManager} from "./managers/optitax-file.manager";

@NgModule({ declarations: [
        // Alert messages
        AlertMessageComponent,
        AlertMessagesComponent,
        DebugBarComponent,
        FontAwesomeIconComponent,
        SplashScreenComponent,
        TableComponent,
    ],
    exports: [
        AlertMessageComponent,
        AlertMessagesComponent,
        FontAwesomeIconComponent,
        DebugBarComponent,
        SplashScreenComponent,
        TableComponent,
        CommonModule,
        BrowserModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgbModule,
        FontAwesomeModule,
        CurrencyMaskModule,
    ], imports: [CommonModule,
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgbModule,
        FontAwesomeModule,
        CurrencyMaskModule], providers: [
        // API Controllers
        RampUpApiController,
        // Factories
        LogFactory,
        // Guards
        FlagEnabledGuard,
        // Managers
        EventManager,
        FlagManager,
        LifecycleManager,
        OptitaxFileManager,
        // Services
        CacheStorageService,
        IndexedDbService,
        LocalStorageService,
        LoggingService,
        MenuService,
        // Stores
        AlertMessageStore,
        // Utils
        BrowserUtils,
        DateUtils,
        DebugUtils,
        ErrorsUtils,
        NumberUtils,
        // Handlers
        ErrorHandler,
        {
            provide: AngularErrorHandler,
            useClass: ErrorHandler
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: DataMapper,
            useValue: new DataMapper(new AutoDataMappingBuilder(), [new StringNormalizer(), new DateNormalizer(), new NumberNormalizer()], []),
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: TransformerHttpInterceptor,
            multi: true,
        },
        // Magieno
        MagienoApiController,
        MagienoObservabilityManager,
        // Exports
        ExportResourceProvider,
        // Lifecycle Listeners
        { provide: LifecycleListenerInterfaceToken, useClass: PlatformRampUpLifecycleListener, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
