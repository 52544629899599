export var TypeEnum;
(function (TypeEnum) {
  TypeEnum["Boolean"] = "boolean";
  TypeEnum["Number"] = "number";
  TypeEnum["String"] = "string";
  TypeEnum["Array"] = "array";
  TypeEnum["Date"] = "date";
  TypeEnum["Object"] = "object";
  TypeEnum["Set"] = "set";
  TypeEnum["Function"] = "function";
  TypeEnum["Symbol"] = "symbol";
  TypeEnum["Null"] = "null";
})(TypeEnum || (TypeEnum = {}));
