import {Pipe, PipeTransform} from "@angular/core";
import {ContentManager} from "../managers/content.manager";
import {BaseContentModel} from "../models/base.content-model";

@Pipe({
    name: 'render'
})
export class RenderPipe implements PipeTransform {

    constructor(private readonly contentManager: ContentManager) {
    }

    public async transform(contentModel?: BaseContentModel, options?: any): Promise<string> {
        if (!contentModel) {
            return "";
        }

        return this.contentManager.render(contentModel, options);
    }

    public static setOptions(): void {

    }
}
