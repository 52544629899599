import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { CalculationRunOptions } from "../calculation-run.options";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { CalculationKeynameEnum } from "../../../enums/calculations/calculation-keyname.enum";
import {FieldTypeEnum} from "../../../enums/field-type.enum";
import {
  IsBoolean,
  IsNotEmpty,
  IsInt,
  Max,
  Min,
  IsDate,
  validateIf,
  IsOptional,
  ValidateIf,
  ValidateNested
} from "@pristine-ts/class-validator";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";
import {CalculationDate} from "../../../models/calculation.date";

@translationBaseKey("salary-contributions-options")
export class SalaryContributionsOptions extends CalculationRunOptions {
  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.SalaryContributions;

  @advancedFormControl({
    labelTitle: {
      fr: "Salaire brut",
      en: "Gross salary"
    },
    tooltip: {
      fr: "Ce champ représente le montant total du salaire brut de l'employé, y compris le salaire gagné à l'extérieur du Canada. Le salaire brut comprend la rémunération totale avant toute déduction. Il est utilisé pour calculer les cotisations au régime de retraite et autres avantages liés à l'emploi.",
      en: "This field represents the total amount of the employee's gross salary, including the salary earned outside Canada. Gross salary includes the total compensation before any deductions. It is used to calculate contributions to the retirement plan and other employment-related benefits."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  employmentIncome: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Masse salariale totale (incluant les indemnités pour accident du travail non remboursées)",
      en: "Total payroll (including unreimbursed workers' compensation benefits)"
    },
    tooltip: {
      fr: "Ce champ représente la masse salariale totale de l'employeur, y compris les indemnités pour accident du travail non remboursées. La masse salariale totale est utilisée pour calculer les cotisations aux régimes sociaux et les impôts sur le revenu des employés.",
      en: "This field represents the total payroll of the employer, including unreimbursed workers' compensation benefits. The total payroll is used to calculate contributions to social programs and income taxes for employees."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object:  SalaryContributionsOptions, root, currentPath): boolean => {
    return object.shouldFSSBeCalculated === true;
  })
  totalPayroll?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Masse salariale totale se rapportant à des activités des secteurs primaire et manufacturier",
      en: "Total payroll related to primary and manufacturing sectors activities"
    },
    tooltip: {
      fr: "Ce champ représente la masse salariale totale se rapportant aux activités des secteurs primaire et manufacturier de l'employeur. Elle est utilisée pour des évaluations spécifiques ou des avantages fiscaux liés à ces secteurs d'activité.",
      en: "This field represents the total payroll related to the primary and manufacturing sectors activities of the employer. It is used for specific assessments or tax benefits related to these business sectors."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object:  SalaryContributionsOptions, root, currentPath): boolean => {
    return object.shouldFSSBeCalculated === true;
  })
  totalPayrollForPrimarySector?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "L'employeur est-il du secteur public?",
      en: "Is the employer from the public sector?"
    },
    tooltip: {
      fr: "Ce champ indique si l'employeur est du secteur public. Le secteur public comprend les organismes gouvernementaux, les sociétés d'État et les administrations publiques. Cette information est pertinente pour certaines déductions ou crédits d'impôt applicables aux employeurs du secteur public.",
      en: "This field indicates whether the employer is from the public sector. The public sector includes government agencies, state-owned enterprises, and public administrations. This information is relevant for certain deductions or tax credits applicable to employers in the public sector."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object:  SalaryContributionsOptions, root, currentPath): boolean => {
    return object.shouldFSSBeCalculated === true;
  })
  isEmployerFromPublicSector?: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Date de naissance",
      en: "Birthdate"
    },
    tooltip: {
      fr: "Ce champ représente la date de naissance du contribuable. La date de naissance est utilisée pour déterminer l'âge du contribuable, ce qui peut avoir des implications fiscales pour certaines prestations ou déductions liées à l'âge.",
      en: "This field represents the taxpayer's birthdate. The birthdate is used to determine the taxpayer's age, which may have tax implications for certain benefits or deductions related to age."
    },
    fieldType: FieldTypeEnum.CalculationDate
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateNested()
  @IsOptional()
  birthdate?: CalculationDate;

  @advancedFormControl({
    labelTitle: {
      fr: "Le contribuable est-il décédé dans l'année?",
      en: "Is the taxpayer deceased in the year?"
    },
    tooltip: {
      fr: "Ce champ indique si le contribuable est décédé au cours de l'année fiscale. Le décès du contribuable peut entraîner des conséquences fiscales spécifiques, telles que des règles particulières de succession.",
      en: "This field indicates whether the taxpayer has deceased during the fiscal year. The taxpayer's death may have specific tax consequences, such as special succession rules."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isTaxpayerDead: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Date du décès",
      en: "Death date"
    },
    tooltip: {
      fr: "Ce champ représente la date de décès du contribuable, le cas échéant. La date de décès est utilisée pour des fins fiscales spécifiques, telles que l'établissement des biens du défunt et la succession.",
      en: "This field represents the taxpayer's death date, if applicable. The death date is used for specific tax purposes, such as establishing the deceased's estate and succession."
    },
    fieldType: FieldTypeEnum.CalculationDate
  })
  @ValidateNested()
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf((object: SalaryContributionsOptions) => object.isTaxpayerDead === true)
  deathdate?: CalculationDate; // todo this should be nullable

  @advancedFormControl({
    labelTitle: {
      fr: "Taux de versement périodique",
      en: "Recurring payment rate"
    },
    tooltip: {
      fr: "Ce champ représente le taux de versement périodique applicable à certains types de revenus, tels que les rentes ou les paiements échelonnés. Le taux de versement périodique est utilisé pour calculer le montant des paiements récurrents.",
      en: "This field represents the recurring payment rate applicable to certain types of income, such as annuities or installment payments. The recurring payment rate is used to calculate the amount of recurring payments."
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @Min(0, {buildErrorMessage: ErrorMessageBuilder.build})
  @Max(100, {buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: SalaryContributionsOptions, root, currentPath): boolean => {
    return object.shouldCNESSTBeCalculated === true;
  })
  recurringPaymentRate?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation RRQ de l'employé et de l'employeur?",
      en: "Calculate employee and employer's QPP contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation au Régime de rentes du Québec (RRQ) doit être calculée pour l'employé et l'employeur. Le RRQ est un régime de pension géré par le gouvernement du Québec, auquel les travailleurs et les employeurs contribuent.",
      en: "This field indicates whether the contribution to the Quebec Pension Plan (QPP) should be calculated for the employee and the employer. The QPP is a pension plan administered by the Quebec government, to which workers and employers contribute."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean()
  @IsNotEmpty()
  shouldQPPBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation RQAP de l'employé et de l'employeur?",
      en: "Calculate employee and employer's QPIP contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation au Régime québécois d'assurance parentale (RQAP) doit être calculée pour l'employé et l'employeur. Le RQAP est un régime d'assurance parentale géré par le gouvernement du Québec, auquel les travailleurs et les employeurs contribuent.",
      en: "This field indicates whether the contribution to the Quebec Parental Insurance Plan (QPIP) should be calculated for the employee and the employer. The QPIP is a parental insurance plan administered by the Quebec government, to which workers and employers contribute."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldQPIPBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation à l'assurance-emploi de l'employé et de l'employeur?",
      en: "Calculate employee and employer's EI contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation à l'assurance-emploi (AE) doit être calculée pour l'employé et l'employeur. L'AE est un programme d'assurance-chômage au Canada qui fournit des prestations aux travailleurs qui perdent leur emploi.",
      en: "This field indicates whether the contribution to Employment Insurance (EI) should be calculated for the employee and the employer. EI is a Canadian unemployment insurance program that provides benefits to workers who lose their jobs."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldEIBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation FSS de l'employé et de l'employeur?",
      en: "Calculate employee and employer's FSS contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation au Fonds des services de santé (FSS) doit être calculée pour l'employé et l'employeur. Le FSS est un fonds qui finance les services de santé au Québec.",
      en: "This field indicates whether the contribution to the Health Services Fund (FSS) should be calculated for the employee and the employer. The FSS is a fund that finances health services in Quebec."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldFSSBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation CNT de l'employé et de l'employeur?",
      en: "Calculate employee and employer's CNT contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation au Comité paritaire de l'industrie de la construction (CNT) doit être calculée pour l'employé et l'employeur. Le CNT est un organisme paritaire qui gère les contributions pour l'industrie de la construction au Québec.",
      en: "This field indicates whether the contribution to the Joint Labor-Management Construction Committee (CNT) should be calculated for the employee and the employer. The CNT is a joint organization that manages contributions for the construction industry in Quebec."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldCNTBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation CNESST de l'employé et de l'employeur?",
      en: "Calculate employee and employer's CNESST contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation à la Commission des normes, de l'équité, de la santé et de la sécurité du travail (CNESST) doit être calculée pour l'employé et l'employeur. La CNESST gère les régimes d'indemnisation des travailleurs au Québec.",
      en: "This field indicates whether the contribution to the Commission des normes, de l'équité, de la santé et de la sécurité du travail (CNESST) should be calculated for the employee and the employer. The CNESST manages workers' compensation schemes in Quebec."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldCNESSTBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Le salarié est-il assujetti à l'assurance-emploi?",
      en: "Is the employee subject to employment insurance?"
    },
    tooltip: {
      fr: "Ce champ indique si le salarié est assujetti à l'assurance-emploi (AE). L'AE est un programme d'assurance-chômage au Canada qui fournit des prestations aux travailleurs qui perdent leur emploi.",
      en: "This field indicates whether the employee is subject to Employment Insurance (EI). EI is a Canadian unemployment insurance program that provides benefits to workers who lose their jobs."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isEmployeeSubjectToEI: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant de salaire pour l'assurance-emploi",
      en: "Salary amount for the employment insurance"
    },
    tooltip: {
      fr: "Ce champ indique le montant de salaire pour le calcul de l'assurance-emploi.",
      en: "This field indicates the salary amount used for the employment insurance contribution."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: SalaryContributionsOptions, root, currentPath): boolean => { return !object.isEmployeeSubjectToEI })
  @IsOptional()
  employmentIncomeForEI?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Année",
      en: "Year"
    },
    tooltip: {
      fr: "Ce champ indique l'année pour laquelle les calculs sont effectués.",
      en: "This field indicates the year for which the calculations are being performed."
    },
    selectKeyValues: [
      {
        key: {
          fr: "2021",
        },
        value: 2021
      }
    ],
    fieldType: FieldTypeEnum.Number,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  fiscalYear: number;

}
