import {IsBoolean, IsNotEmpty} from "@pristine-ts/class-validator";
import {advancedFormControl} from "../../../decorators/advanced-form-control.decorator";
import {translationBaseKey} from "../../../decorators/translation-base-key.decorator";
import {FieldTypeEnum} from "../../../enums/field-type.enum";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("section-84_1-metadata-options")
export class Section84_1MetadataOptions {
    @advancedFormControl({
        labelTitle: {
            fr: "Est-ce qu’il s’agit d’un roulement fiscal selon l’article 85 LIR?",
            en: "Is it a fiscal rollover based on article 85 ITA?"
        },
        tooltip: {
            fr: "Indiquez si cette transaction est un roulement fiscal selon l'article 85 de la Loi de l'impôt sur le revenu (LIR).",
            en: "Specify if this transaction is a fiscal rollover based on section 85 of the Income Tax Act (ITA).",
        },
        fieldType: FieldTypeEnum.Boolean,
    })
    @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    isRollover: boolean;
}