<app-header>
    <div class="first-section"></div>
</app-header>

<div class="second-section">
    <div class="registration-card">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-10 offset-lg-1">
                    <div class="card">
                        <div class="card-body text-center">
                            <h2 class="card-title">{{ GetStartedContentResources["get-started.body.title"] | render | async }}</h2>

                            <p class="card-text text-start">{{ GetStartedContentResources["get-started.body.subtitle"] | render | async }}</p>

                            <div id="get_started_hubspot_form"></div>

                            <hr>

                            <h4 class="card-title">{{ GetStartedContentResources["get-started.body.access-your-account"] | render | async }}</h4>

                            <a class="btn btn-outline-primary"
                               href="https://app.optitax.ca">{{ GetStartedContentResources["get-started.body.call-to-action"] | render | async }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>