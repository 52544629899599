import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {CardsContentResources} from "./cards.content-resources";
import {
    IndividualCalculationContentResourceInterface
} from "../interfaces/individual-calculation-content-resource.interface";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export const DetentionAutomobilePersonnelleOuCorporativeContentResources: IndividualCalculationContentResourceInterface & ContentResourceInterface & { [id in string]: any } = {

    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {
            "fr": "Détention automobile: personnelle ou corporative - Optitax",
            "en": "Vehicle Ownership: Personal vs Corporate ownership - Optitax"
        }
    ),

    Meta: {
        Description: new TextContentModel(
            {
                fr: "Optitax est un logiciel web qui offre des outils utiles et performants dans le  domaine de la fiscalité. Nous offrons des calculs variés, allant d’un calcul simple,  tel qu’un rachat d’actions, à des calculs complexes, tel que la détention personnelle ou  corporative d’un véhicule. La mission d’Optitax est de développer une gamme complète d’outils  fiscaux afin que les cabinets comptables ou autres professionnels n’aient pas à développer leurs propres  outils à l’interne et puissent se concentrer entièrement sur leur pratique.",
                en: "Optitax is a web software that offers useful and powerful tools in the field of taxation. We offer various calculations, ranging from a simple calculation, such as a share buyback, to complex calculations, such as the personal or corporate ownership of a vehicle. Optitax's mission is to develop a complete range of tax tools so that accounting firms or other professionals do not have to develop their own tools internally and can focus entirely on their practice."
            }
        ),
        Keywords: new TextContentModel(
            {
                "fr": "comptable, accounting, fiscalité, optimiser votre fiscalité",
                en: "accounting, tax, optimize your tax"
            }
        ),
    },

    Hero: {
        Title: new TextContentModel(
            {"fr": "Détention automobile", "en": "Vehicle ownership"}
        ),
        Subtitle: new TextContentModel(
            {"fr": "Outil fiscal conçu spécifiquement pour le Québec", "en": "Software built specifically for Québec"}
        ),
        Description: new TextContentModel(
            {
                "fr": "Est-il plus intéressant de détenir une automobile personnellement ou corporativement? Ce calcul adapté à la situation de votre client vous permettra d'y répondre de façon précise et personnalisée.",
                "en": "Is it more interesting to own or lease a vehicle? Is it better for the corporation of the individual to possess the vehicle? You will get a personnalized answer for each scenario."
            }
        ),
        CallToAction: new TextContentModel(
            {
                "fr": "Calculez la détention automobile idéale maintenant!",
                "en": "Calculate the optimal vehicle possession scenario now!"
            }
        ),
        Image: new ImageContentModel(
            {
                url: new TextContentModel({
                    fr: "images/illustrations/car_ownership.svg",
                    en: "images/illustrations/car_ownership.svg",
                }),
            }
        )
    },

    MainFeature: {
        Title: new TextContentModel(
            {
                "fr": "Avec Optitax, déterminez la détention automobile idéale en 3 étapes simples!",
                "en": "With Optitax, calculate the optimal vehicle possession in 3 steps!"
            }
        ),
        Description: CardsContentResources.CarOwnership.description!,
        Image: new ImageContentModel(
            {
                url: new TextContentModel({
                    fr: "images/illustrations/man-success.svg",
                    en: "images/illustrations/man-success.svg",
                })
            }
        ),
        Steps: [
            {
                Title: new TextContentModel(
                    {
                        "fr": "Entrez les informations fiscales de votre client",
                        "en": "Enter the related tax information for your client"
                    }
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Notre calcul optimise selon la situation fiscale particulière de chacun de vos clients, c'est pourquoi nous avons besoin des informations fiscales.",
                        "en": "Our tool optimizes the tax consequences for each scenario."
                    }
                )
            },
            {
                Title: new TextContentModel(
                    {
                        "fr": "Entrez les informations du véhicule considéré",
                        "en": "Enter the information about the vehicle"
                    }
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Notre outil requiert les informations spécifiques selon le type de véhicule considéré. Par exemple, il y aura des variations selon le type de véhicule (essence, hybride ou électrique), le prix du véhicule ainsi que sa durée de vie estimée.-",
                        "en": "In order to get a precise result, the vehicle information is needed. There will be differences based on the vehicle type (gas, hybrid, EV) and its expected lifetime."
                    }
                )
            },
            {
                Title: new TextContentModel(
                    {
                        "fr": "Comparez tous les scénarios et présentez le scénario optimal à votre client!",
                        "en": "Compare all the scenarios and present the ideal one to your client!"
                    }
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Optitax vous donne une vue d'ensemble qui vous permet de comparer tous les scénarios et d'identifier le scénario le plus alléchant fiscalement pour votre client.",
                        "en": "Optitax provides you at a glance with all the possible scenarios and their unique tax consequences. Choose the most interesting one for your client!"
                    }
                )
            }
        ]
    },

    Features: [
        {
            Title: new TextContentModel(
                {"fr": "Des outils fiscaux adaptés au Québec", "en": "Tool specifically built for Québec"}
            ),
            Description: new TextContentModel(
                {
                    "fr": "Optitax est une firme québécoise qui développe des outils fiscaux pour les cabinets comptables québécois",
                    "en": "Optitax is a Québec-based technologies company that develops a powerful software that does advanced tax calculations for accounting firms."
                }
            ),
            Image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/man-phone.svg",
                        en: "images/illustrations/man-phone.svg",
                    }),
                }
            ),
            CallToAction: new TextContentModel(
                {"fr": "Effectuez ce calcul maintenant", "en": "Try this tool now."}
            )
        },
        {
            Title: new TextContentModel(
                {"fr": "Notre solution est accessible sur tous vos appareils", "en": "Available on all your devices"}
            ),
            Description: new TextContentModel(
                {
                    "fr": "Optitax est disponible sur tous vos appareils: Windows, Mac OS, iPhone, Android et iPad!",
                    "en": "Optitax is available on all your devices: Windows, Mac OS, iPhone, Android et iPad!"
                }
            ),
            Image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/devices.svg",
                        en: "images/illustrations/devices.svg",
                    }),
                }
            ),
            CallToAction: new TextContentModel(
                {"fr": "Effectuez ce calcul maintenant", "en": "Try this tool now"}
            )
        }
    ],
}
