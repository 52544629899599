import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsInt,
  Max,
  Min,
  ValidateIf,
  IsDate, IsOptional, ValidateNested
} from "@pristine-ts/class-validator";
import { FieldTypeEnum } from "../../../../../enums/field-type.enum";
import { advancedFormControl } from "../../../../../decorators/advanced-form-control.decorator";
import { translationBaseKey } from "../../../../../decorators/translation-base-key.decorator";
import {ErrorMessageBuilder} from "../../../../../builders/error-message.builder";
import {ValidationGroupEnum} from "../../../../../enums/validation-group.enum";
import {CalculationDate} from "../../../../../models/calculation.date";
import {type} from "@pristine-ts/data-mapping-common";
import {IncomeTaxPersonalSpouseQuebecOptions} from "../quebec/income-tax-personal-spouse-quebec.options";
import {Type} from "class-transformer";

@translationBaseKey("in-options")
export class IncomeTaxPersonalSpouseFederalOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que la personne est décédée?",
      en: "Is the person dead?"
    },
    tooltip: {
      fr: "Indiquez si la personne est décédée. Le statut de décès peut avoir des conséquences fiscales, notamment en ce qui concerne la déclaration de revenus et les taxes successorales.",
      en: "Specify if the person is deceased. The death status can have tax implications, including income tax filing and estate taxes.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isDead: boolean = false;

  @advancedFormControl({
    labelTitle: {
      fr: "Date du décès",
      en: "Date of death"
    },
    tooltip: {
      fr: "Indiquez la date du décès de la personne. La date de décès est essentielle pour déterminer la période de déclaration de revenus et les obligations fiscales de succession.",
      en: "Specify the date of death of the person. The date of death is crucial for determining the income tax filing period and estate tax obligations.",
    },
    fieldType: FieldTypeEnum.CalculationDate
  })
  @ValidateIf(o => o.isDead)
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateNested()
  @type(() => new CalculationDate())
  deathdate?: CalculationDate;

  @advancedFormControl({
    labelTitle: {
      fr: "Autres revenus à inclure à la ligne 13000",
      en: "Other income to include on line 13000"
    },
    tooltip: {
      fr: "Indiquez tout revenu supplémentaire à inclure à la ligne 13000 de la déclaration de revenus. Le code fiscal du Québec et du Canada peut comporter des directives spécifiques concernant les types de revenus à déclarer à cette ligne et leur taxation.",
      en: "Specify any additional income to include on line 13000 of the income tax return. The Quebec and Canada tax codes may have specific guidelines on what types of income should be reported on this line and how they are taxed.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({
    groups: [ValidationGroupEnum.SalaryDividend]
  })
  otherIncome: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Maximum déductible au titre des REER",
      en: "RRSP deduction limit"
    },
    tooltip: {
      fr: "Indiquez la limite de déduction des cotisations au Régime enregistré d'épargne-retraite (REER). Le Québec et le Canada ont des règles et des limites sur les cotisations déductibles d'impôt que les particuliers peuvent verser à leur REER.",
      en: "Specify the maximum deductible contributions allowed to a Registered Retirement Savings Plan (RRSP). Both Quebec and Canada have rules and limits on the tax-deductible contributions individuals can make to their RRSPs.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.paidContributionRRSP > 0)
    // todo set a warning if paid contribution > maximum allowed.
  maximumAllowedDeductibleContributionsRRSP: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre de mois complets d'études à temps partiel",
      en: "Number of full months in part-time education"
    },
    tooltip: {
      fr: "Indiquez le nombre de mois complets passés en études à temps partiel. Le code fiscal du Québec et du Canada peut accorder des crédits d'impôt ou des déductions liées à l'éducation en fonction du nombre de mois passés à temps partiel.",
      en: "Specify the number of full months spent in part-time education. Both Quebec and Canada may provide tax credits or deductions related to education based on the number of months spent in part-time education.",
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  numberOfMonthsOptionA: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre de semaines avec situations particulières",
      en: "Number of weeks with special situations"
    },
    tooltip: {
      fr: "Indiquez le nombre de semaines où des situations particulières s'appliquent. Le code fiscal du Québec et du Canada peut contenir des dispositions spécifiques concernant le traitement fiscal pendant des circonstances spéciales.",
      en: "Specify the number of weeks where special situations apply. Quebec and Canada tax codes may have specific provisions for tax treatment during special circumstances.",
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  numberOfWeeksOptionB: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant des cotisations bonifiées au RRQ",
      en: "Amount of enhanced QPP contributions"
    },
    tooltip: {
      fr: "Indiquez le montant des cotisations bonifiées au Régime de rentes du Québec (RRQ). Le Québec et le Canada ont des règles concernant les cotisations au RRQ et leur traitement fiscal.",
      en: "Specify the amount of enhanced Quebec Pension Plan (QPP) contributions. Both Quebec and Canada may have rules regarding QPP contributions and their tax treatment.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  enhancedContributionsQPP: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant des cotisations au RRQ",
      en: "Amount of QPP contributions"
    },
    tooltip: {
      fr: "Indiquez le montant des cotisations régulières au Régime de rentes du Québec (RRQ). Le Québec et le Canada ont des cotisations obligatoires au RRQ pour les travailleurs salariés et leur traitement fiscal peut varier.",
      en: "Specify the amount of regular Quebec Pension Plan (QPP) contributions. Both Quebec and Canada have mandatory QPP contributions for employed individuals, and the tax treatment of these contributions may vary.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  contributionsQPP: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Cotisations à l'assurance-emploi",
      en: "Employment insurance contributions"
    },
    tooltip: {
      fr: "Indiquez le montant des cotisations à l'assurance-emploi. Les cotisations à l'assurance-emploi sont obligatoires au Québec et au Canada et leur traitement fiscal est régi par les lois fiscales pertinentes.",
      en: "Specify the amount of Employment Insurance (EI) contributions. EI contributions are mandatory in Quebec and Canada, and their tax treatment is governed by relevant tax laws.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  contributionsEI: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant des cotisations au RQAP",
      en: "Amount of QPIP contributions"
    },
    tooltip: {
      fr: "Indiquez le montant des cotisations au Régime québécois d'assurance parentale (RQAP). Le RQAP est spécifique au Québec et peut avoir son propre traitement fiscal.",
      en: "Specify the amount of Quebec Parental Insurance Plan (QPIP) contributions. QPIP is specific to Quebec and may have its own tax treatment.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  contributionsQPIP: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant des cotisations au REEI",
      en: "Amount of RDSP contributions"
    },
    tooltip: {
      fr: "Indiquez le montant des cotisations versées dans un Régime enregistré d'épargne-invalidité (REEI). Les cotisations au REEI peuvent avoir des implications fiscales uniques au Québec et au Canada.",
      en: "Specify the amount of contributions made to a Registered Disability Savings Plan (RDSP). RDSP contributions may have unique tax implications in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  contributionsRDSP: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Êtes-vous en prison?",
      en: "Are you in prison?"
    },
    tooltip: {
      fr: "Indiquez si la personne est en prison. Le statut de résidence des contribuables, y compris le temps passé en prison, peut avoir des implications fiscales au Québec et au Canada.",
      en: "Specify if the person is in prison. Taxpayers' residency status, including time spent in prison, can have tax implications in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isInPrison: boolean = false;

  @advancedFormControl({
    labelTitle: {
      fr: "Avez-vous résidé au Canada toute l'année?",
      en: "Have you lived in Canada all year?"
    },
    tooltip: {
      fr: "Indiquez si la personne a résidé au Canada pendant toute l'année. La résidence fiscale est un facteur crucial pour déterminer les obligations fiscales au Québec et au Canada.",
      en: "Specify if the person has resided in Canada for the entire year. Tax residency is a crucial factor in determining tax obligations in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isCanadianResidentForFullYear: boolean = true;

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre de mois complets d'études à temps plein",
      en: "Number of full-time months at a post-secondary institution"
    },
    tooltip: {
      fr: "Indiquez le nombre de mois complets passés à étudier à temps plein dans un établissement d'enseignement postsecondaire. Le Québec et le Canada peuvent offrir des avantages fiscaux liés à l'éducation.",
      en: "Specify the number of full-time months spent studying at a post-secondary institution. Both Quebec and Canada may provide tax benefits related to education.",
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  numberOfMonthsFullTimeSchool: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Revenus exonérés",
      en: "Exempted income"
    },
    tooltip: {
      fr: "Indiquez tout revenu exonéré de l'impôt. Le Québec et le Canada peuvent avoir des règles spécifiques concernant les types de revenus qui peuvent bénéficier d'exemptions fiscales.",
      en: "Specify any income that is exempt from taxation. Quebec and Canada may have specific rules regarding the types of income that qualify for exemptions.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  exemptedIncome: number = 0;

  @advancedFormControl({
    labelTitle: {
      fr: "Gain ou perte en capital sur la disposition de certains biens",
      en: "Capital gain or loss on the disposition of certain properties"
    },
    tooltip: {
      fr: "Indiquez les gains ou les pertes en capital sur la disposition de certains biens. Les gains et les pertes en capital sont soumis à un traitement fiscal spécifique au Québec et au Canada.",
      en: "Specify capital gains or losses on the disposition of certain properties. Capital gains and losses are subject to specific tax treatment in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({
    groups: [ValidationGroupEnum.SalaryDividend]
  })
  a3Line13200: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Cotisations REER effectuées",
      en: "RRSP contributions made"
    },
    tooltip: {
      fr: "Indiquez le montant des cotisations REER versées entre mars et février (à l'exclusion de FONDACTION et FTQ). Les cotisations REER peuvent avoir des avantages fiscaux au Québec et au Canada.",
      en: "Specify the amount of RRSP contributions made between March and February (excluding FONDACTION and FTQ). RRSP contributions may have tax benefits in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  paidContributionRRSP: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Êtes-vous un fonctionnaire à revenu exonéré?",
      en: "Are you an exempted income official?"
    },
    tooltip: {
      fr: "Indiquez si vous êtes un fonctionnaire dont le revenu est exonéré d'impôt. Le statut d'exonération de revenu peut avoir des conséquences fiscales différentes au Québec et au Canada.",
      en: "Specify if you are an official whose income is exempt from taxation. The status of exempted income can have different tax implications in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty()
  @IsBoolean()
  isExemptedIncomeOfficial: boolean = false;

  @advancedFormControl({
    labelTitle: {
      fr: "Êtes-vous handicapé?",
      en: "Are you disabled?"
    },
    tooltip: {
      fr: "Indiquez si vous êtes handicapé. Le statut d'invalidité peut avoir des avantages fiscaux spécifiques au Québec et au Canada.",
      en: "Specify if you are disabled. The disabled status may have specific tax benefits in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty()
  @IsBoolean()
  isDisabled: boolean = false;
}
