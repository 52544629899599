var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { DataMappingNodeTypeEnum } from "../enums/data-mapping-node-type.enum";
import { DataMappingLeaf } from "./data-mapping.leaf";
import { BaseDataMappingNode } from "./base-data-mapping.node";
import { DataMappingSourcePropertyNotFoundError } from "../errors/data-mapping-source-property-not-found.error";
import { ArrayDataMappingNodeInvalidSourcePropertyTypeError } from "../errors/array-data-mapping-node-invalid-source-property-type.error";
import { plainToInstance } from "class-transformer";
export class DataMappingNode extends BaseDataMappingNode {
  constructor(root, parent, type = DataMappingNodeTypeEnum.Node) {
    super();
    this.root = root;
    this.parent = parent;
    this.type = type;
    /**
     * This method specified whether it's possible that this element not be present in the `source` object.
     */
    this.isOptional = false;
  }
  /**
   * This is a setter for `sourceProperty`.
   * @param sourceProperty
   */
  setSourceProperty(sourceProperty) {
    this.sourceProperty = sourceProperty;
    return this;
  }
  /**
   * This is a setter for `destinationProperty`.
   * @param destinationProperty
   */
  setDestinationProperty(destinationProperty) {
    this.destinationProperty = destinationProperty;
    return this;
  }
  /**
   * This is a setter for `destinationType`.
   * @param destinationType
   */
  setDestinationType(destinationType) {
    this.destinationType = destinationType;
    return this;
  }
  /**
   * This is a setter for `isOptional`.
   * @param isOptional
   */
  setIsOptional(isOptional) {
    this.isOptional = isOptional;
    return this;
  }
  /**
   * This property creates a new DataMappingLeaf and returns it. It doesn't add it yet. To do so, the `end()` method
   * must be called.
   */
  add() {
    return new DataMappingLeaf(this.root, this);
  }
  /**
   * This method adds a nesting level. This should be used when the property contains an object and you want to map
   * this object into another object.
   */
  addNestingLevel() {
    return new DataMappingNode(this.root, this);
  }
  /**
   * This method adds an array of Scalar allowing you to apply the normalizer on each scalar in the array. The
   * `sourceProperty` and `destinationProperty` correspond to the name of the property that is an array. But, the
   * values in the array will be normalized using the normalizer.
   *
   */
  addArrayOfScalar() {
    return new DataMappingLeaf(this.root, this, DataMappingNodeTypeEnum.ScalarArray);
  }
  /**
   * This method adds an array of objects allowing to define a node for each property in the object. Each object in
   * the array will be treated as being the same.
   */
  addArrayOfObjects() {
    return new DataMappingNode(this.root, this, DataMappingNodeTypeEnum.ObjectArray);
  }
  /**
   * This method adds this node to its parent and returns the parent.
   */
  end() {
    // todo: Validate that we actually have all the properties needed (sourceProperty and destinationProperty) for example.
    this.parent.addNode(this);
    return this.parent;
  }
  /**
   * This method maps the `sourceProperty` from the `source` object and maps it to the `destinationProperty` of the
   * `destination` object while applying the normalizers.
   *
   * @param source
   * @param destination
   * @param normalizersMap
   */
  map(source, destination, normalizersMap, options) {
    return __awaiter(this, void 0, void 0, function* () {
      if (source.hasOwnProperty(this.sourceProperty) === false) {
        if (this.isOptional) {
          return;
        }
        throw new DataMappingSourcePropertyNotFoundError("The property '" + this.sourceProperty + "' isn't found in the Source object and isn't marked as Optional. If you want to ignore this property, use the 'setIsOptional(true)' method in the builder.", this.sourceProperty);
      }
      const sourceElement = source[this.sourceProperty];
      if (sourceElement === undefined) {
        return;
      }
      if (this.type === DataMappingNodeTypeEnum.ObjectArray) {
        destination[this.destinationProperty] = [];
      } else {
        if (this.destinationType) {
          destination[this.destinationProperty] = plainToInstance(this.destinationType, sourceElement);
        } else {
          destination[this.destinationProperty] = {};
        }
        if ((options === null || options === void 0 ? void 0 : options.excludeExtraneousValues) === false) {
          Object.keys(sourceElement).forEach(property => {
            destination[this.destinationProperty][property] = sourceElement[property];
          });
        }
      }
      const destinationElement = destination[this.destinationProperty];
      if (this.type === DataMappingNodeTypeEnum.ObjectArray) {
        // This means that the source[propertyKey] contains an array of objects and each object should be mapped
        const array = source[this.sourceProperty];
        if (Array.isArray(array) === false) {
          throw new ArrayDataMappingNodeInvalidSourcePropertyTypeError(`According to your schema, the property '${this.sourceProperty}' in the source object must contain an Array of objects. Instead, it contains: '${typeof array}'.`, this.sourceProperty);
        }
        let index = 0;
        for (const element of array) {
          let dest = {};
          if (this.destinationType) {
            if (typeof this.destinationType === "function" && !this.destinationType.prototype) {
              const destinationType = this.destinationType;
              dest = plainToInstance(destinationType(source, this.sourceProperty, index).constructor, (options === null || options === void 0 ? void 0 : options.excludeExtraneousValues) === false ? element : {});
            } else if (this.destinationType.prototype) {
              dest = plainToInstance(this.destinationType, {});
            }
          }
          if ((options === null || options === void 0 ? void 0 : options.excludeExtraneousValues) === false) {
            Object.keys(element).forEach(property => {
              dest[property] = element[property];
            });
          }
          for (const key in this.nodes) {
            if (this.nodes.hasOwnProperty(key) === false) {
              continue;
            }
            const node = this.nodes[key];
            yield node.map(element, dest, normalizersMap, options);
          }
          destinationElement.push(dest);
        }
        index++;
        return;
      }
      // When the current node is not an array, we simply iterate
      for (const key in this.nodes) {
        if (this.nodes.hasOwnProperty(key) === false) {
          continue;
        }
        const node = this.nodes[key];
        yield node.map(sourceElement, destinationElement, normalizersMap, options);
      }
    });
  }
  /**
   * This method imports a schema.
   *
   * @param schema
   */
  import(schema) {
    this.sourceProperty = schema.sourceProperty;
    this.destinationProperty = schema.destinationProperty;
    this.isOptional = schema.isOptional;
    this.nodes = {};
    const nodes = schema.nodes;
    for (const key in nodes) {
      if (nodes.hasOwnProperty(key) === false) {
        continue;
      }
      const nodeInfo = nodes[key];
      const type = nodeInfo["_type"];
      switch (type) {
        case DataMappingNodeTypeEnum.ScalarArray:
        case DataMappingNodeTypeEnum.Leaf:
          const leaf = new DataMappingLeaf(this.root, this, type);
          leaf.import(nodeInfo);
          this.nodes[leaf.sourceProperty] = leaf;
          continue;
        case DataMappingNodeTypeEnum.Node:
        case DataMappingNodeTypeEnum.ObjectArray:
          const node = new DataMappingNode(this.root, this, type);
          node.import(nodeInfo);
          this.nodes[node.sourceProperty] = node;
          continue;
      }
    }
  }
  /**
   * This method exports this node.
   */
  export() {
    const nodes = this.nodes;
    for (const key in nodes) {
      if (nodes.hasOwnProperty(key) === false) {
        continue;
      }
      nodes[key] = nodes[key].export();
    }
    return {
      "_type": this.type,
      "sourceProperty": this.sourceProperty,
      "destinationProperty": this.destinationProperty,
      "isOptional": this.isOptional,
      "nodes": nodes
    };
  }
}
