<div class="container-fluid bg-dark pb-3">
    <div class="row">
        <div class="col-12">
            <app-header [headerStyle]="HeaderStyleEnum.Support"></app-header>
        </div>
    </div>
</div>

<div class="container">

    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-primary"
                                                   href="{{ routeManager.getRouteInCurrentLocale(RouteEnum.Home) }}">
                        <font-awesome-icon icon="fa fa-home"></font-awesome-icon>
                    </a></li>
                    <li class="breadcrumb-item"><a class="text-primary"
                                                   href="{{ routeManager.getRouteInCurrentLocale(RouteEnum.Support) }}">
                        @if(localeManager.getCurrentLocale() === LocaleEnum.French) {
                            Centre d'aide
                        } @else {
                            Support
                        }</a></li>
                    <li class="breadcrumb-item active text-dark"
                        aria-current="page">{{ article?.Title | render | async }}
                    </li>
                </ol>
            </nav>
        </div>
    </div>


    <div class="">
        <h1 class="text-dark mt-4 display-6">{{ article?.Title | render | async }}</h1>

        <p class="mt-3"><span class="text-gray-700"><font-awesome-icon icon="far fa-clock"></font-awesome-icon>
            @if(localeManager.getCurrentLocale() === LocaleEnum.French) {
            Date de publication:
        }@else{
            Published on:
        }</span>  {{ article?.LastModified | render | async }}</p>
    </div>

    <div class="row">
        <div class="col-12">
            <app-image [imageContentModel]="article?.LeadPicture" class="img-fluid picture-lead" [loading]="LoadingEnum.Eager" [fetchPriority]="FetchPriorityEnum.High"></app-image>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-12 col-xl-9" [innerHTML]="article?.Article | render | async">
        </div>
        <div class="col-12 col-xl-3">
            <!--            <p class="text-gray-700">5 minutes read</p>-->
            <p class="h6 mb-3">
                @if(localeManager.getCurrentLocale() === LocaleEnum.French) {
                Partager cet article
            } @else {
                Share this post
            }</p>
            <a class="btn btn-light" id="post-to-linkedin" [href]="linkedInUrl" target="_blank">
                <font-awesome-icon
                        icon="fab fa-linkedin"></font-awesome-icon>
            </a>
            <a class="btn btn-light ms-1" id="post-to-twitter" [href]="twitterUrl" target="_blank">
                <font-awesome-icon
                        icon="fab fa-twitter"></font-awesome-icon>
            </a>
            <!--            <a class="btn btn-light ms-1"><font-awesome-icon icon="fas fa-envelope"></font-awesome-icon> </a>-->
        </div>
    </div>
</div>

<div class="container-fluid p-0">
    <app-footer></app-footer>
</div>