<app-header>
    <div class="row">
        <div class="col-12">
            <h1 class="text-center text-white">{{ CalculationsContentResources["calculations.body.hero.title"] | render | async }}</h1>
            <h6 class="text-center text-white mt-5 mb-8">{{ CalculationsContentResources["calculations.body.hero.subtitle"] | render | async }}</h6>
        </div>
    </div>
</app-header>

<div class="container mt-5">
    @for (feature of CalculationsContentResources.CalculationsBodyFeatures; track $index) {
        <div class="card p-5">
            <h2 class="">{{ feature.title | render | async }}</h2>
            <div class="row">
                <div class="col-12 col-md-6 {% if loop.index is odd %} order-first {% else %} order-last {% endif %}"
                     [class.order-first]="!$even"
                     [class.order-last]="$even"
                     [innerHTML]="feature.description | render | async">
                </div>
                <div
                        [class.order-first]="$even"
                        [class.order-last]="!$even"
                        class=" d-none d-md-block col-md-6">
                    <app-image
                            [imageContentModel]="feature.image"
                            [fetchPriority]="FetchPriorityEnum.Low"
                            [loading]="LoadingEnum.Lazy"
                            [class]="'img-fluid'"></app-image>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="d-grid mt-3">
                        <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.Calculations) + '/' + (feature.callToAction?.calculationKeyname | render | async)"
                           class="btn btn-primary">{{ feature.callToAction?.displayText | render | async }}</a>

                    </div>
                </div>
            </div>
        </div>
    }

</div>