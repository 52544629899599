import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {CardContentModel} from "../models/card.content-model";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {CalculationKeynameRoutePathConstants} from "../constants/calculation-keyname-route-path.constants";
import {LocaleEnum} from "../../enums/locale.enum";

export const CardsContentResources = {

    SalaryDividends: new CardContentModel({
        title: new TextContentModel(
            {"fr": "Rémunération: salaire ou dividendes", "en": "Compensation: salary or dividends"}
        ),
        image: new ImageContentModel(
            {
                "url": new TextContentModel({
                    "fr": "images/illustrations/dividends_vs_salary.svg",
                    "en": "images/illustrations/dividends_vs_salary.svg",
                })
            }
        ),
        description: new MarkdownContentModel(
            {
                "fr": "Le calcul « Rémunération : Salaire vs Dividende » permet de calculer la proportion optimale de dividendes et de salaires que doit verser une société afin de minimiser la charge fiscale. Le calcul effectue plusieurs simulations tel que :\n1. Salaire seulement\n1. Dividende seulement\n1. Salaire et dividende combiné\n\nDe plus, le calcul permet 2 points de départs différents, soit :\n1. Le coût de vie personnel de l’entrepreneur\n1. Les liquidités corporatives à décaisser\n",
                "en": "The \"Compensation: Salary vs Dividend\" calculation allows determining the optimal proportion of dividends and salaries a company should pay to minimize the tax burden. This tool performs several simulations, including:\n1. Salary only\n1. Dividend only\n1. Combined salary and dividend\n\nFurthermore, this tool allows for two different starting points:\n1. The entrepreneur's personal cost of living\n1. Corporate liquidity to be disbursed.\n"
            }
        ),
        callToAction: {
            displayText:new TextContentModel(
                {
                    "fr": "Découvrir l'outil \"Rémunération: salaire ou dividendes\"",
                    "en": "Discover the \"Compensation: salary or dividends\""
                }
            ),
            calculationKeyname: new TextContentModel(
                {
                    fr: CalculationKeynameRoutePathConstants[LocaleEnum.French].SALARY_DIVIDEND,
                    en: CalculationKeynameRoutePathConstants[LocaleEnum.English].SALARY_DIVIDEND
                }
            )
        }

    }),

    CarOwnership: new CardContentModel({
        title: new TextContentModel(
            {
                "fr": "Détention automobile: personnelle ou corporative",
                "en": "Vehicle Ownership: Personal vs Corporate ownership"
            }
        ),
        image: new ImageContentModel(
            {
                "url": new TextContentModel({
                    "fr": "images/illustrations/car_ownership.svg",
                    "en": "images/illustrations/car_ownership.svg",
                })
            }
        ),
        description: new MarkdownContentModel(
            {
                "fr": "Le calcul « Détention automobile: personnelle ou corporative » permet de calculer le coût fiscal réel d’une automobile selon les différents scénarios suivants :\n\n1. Achat par l’actionnaire\n1. Achat par la société\n1. Location par l’actionnaire\n1. Location par la société\n\nLe calcul prend aussi en compte les frais d’utilisation du véhicule. Tous les scénarios sont présentés sous la forme des coûts corporatifs engendrés par l’achat ou la location afin d’avoir les mêmes bases de comparaison.\n\nVous pouvez ainsi comparer tous les scénarios de détention automobile possibles, de les comparer entre eux et de déterminer le scénario le plus **avantageux fiscalement** pour votre client.\n",
                "en": "The \"Vehicle Ownership: Personal or Corporate\" calculation allows determining the actual tax cost of a vehicle according to the following different scenarios:\n1. Purchase by the shareholder\n1. Purchase by the company\n1. Lease by the shareholder\n1. Lease by the company\n\nThe calculation also takes into account the vehicle's operating expenses. All scenarios are presented in the form of corporate costs incurred by the purchase or lease to ensure a fair basis for comparison.\n\nYou can thus compare all possible car ownership scenarios, compare them against each other, and determine the scenario that is most tax advantageous for your client.\n"
            }
        ),
        callToAction: {
            displayText: new TextContentModel(
                {"fr": "Découvrir l'outil \"Détention automobile\"", "en": "Discover the \"Vehicle Ownership\" tool"}
            ),
            calculationKeyname: new TextContentModel(
                {
                    fr: CalculationKeynameRoutePathConstants[LocaleEnum.French].VEHICLE_OWNERSHIP,
                    en: CalculationKeynameRoutePathConstants[LocaleEnum.English].VEHICLE_OWNERSHIP
                }
            )
        },
    }),

    Rollover: new CardContentModel({
        title: new TextContentModel(
            {"fr": "Roulement fiscal", "en": "Tax Rollover"}
        ),
        image: new ImageContentModel(
            {
                url: new TextContentModel({
                    "fr": "images/illustrations/rollover.svg",
                    "en": "images/illustrations/rollover.svg",
                })
            }
        ),
        description: new MarkdownContentModel(
            {
                "fr": "Le roulement fiscal consiste à transférer un bien à une société canadienne dans le but de reporter de son imposition. L’outil Roulement Fiscal d’Optitax offre plusieurs avantages tels que:\n\n1. Calcul adéquat des conséquences fiscales selon la transaction **(roulement interne ou externe, application de 84.1 LIR)**\n1. Calcul des caractéristiques fiscales des actions vendues et reçues en contrepartie\n1. Possibilité de calculer automatiquement la somme convenue minimale et/ou la contrepartie autre qu’en actions maximale.\n1. Détection d’erreurs tel qu’aucune émission d’actions en contrepartie, somme convenue qui n’est pas dans les limites admissibles, etc.\n1. Production automatique des formulaires de roulement (**T2057, TP-518**)\n",
                "en": "\"Tax rollover\" refers to the transfer of an asset to a Canadian corporation with the purpose of deferring its taxation. Optitax's Tax Rollover tool offers several advantages, such as:\n\n1. Accurate calculation of the tax consequences based on the transaction **(internal or external rollover, application of section 84.1 of the Income Tax Act)**.\n1. Calculation of the tax attributes of the shares sold and received as consideration.\n1. Automatic calculation of the minimum agreed amount and/or the maximum consideration other than shares.\n1. Error detection, such as no issuance of shares as consideration or an agreed amount that falls outside the eligible limits.\n1. Automatic generation of rollover forms (**T2057, TP-518**).\n"
            }
        ),
        callToAction: {
            displayText: new TextContentModel(
                {"fr": "Découvrir l'outil \"Roulement\"", "en": "Discover the Tax Rollover"}
            ),
            calculationKeyname: new TextContentModel(
                {
                    fr: CalculationKeynameRoutePathConstants[LocaleEnum.French].ROLLOVER,
                    en: CalculationKeynameRoutePathConstants[LocaleEnum.English].ROLLOVER
                }
            )
        },
    }),

    SharesSelling: new CardContentModel({
        title: new TextContentModel(
            {"fr": "Vente d'actions", "en": "Stocks Sale"}
        ),
        image: new ImageContentModel(
            {
                "url": new TextContentModel({
                    "fr": "images/illustrations/shares_selling.svg",
                    "en": "images/illustrations/shares_selling.svg",
                })
            }
        ),
        description: new MarkdownContentModel(
            {
                "fr": "L'outils \"Vente d'actions\" permet de calculer les conséquences fiscales de la vente des actions d'une société tel qu'un gain ou une perte en capital. L’outils \"Vente d'actions\" d’Optitax offre plusieurs avantages tel que:\n\n1. Calcul complet des conséquences fiscales selon que l’article 84.1 s’applique ou non à la transaction\n1. Calcul des caractéristiques fiscales des actions vendues et reçues en contrepartie, s’il y a lieu\n1. L'outils détermine aussi le montant du dividende réputé lorsque les conditions de l'article 84.1 L.I.R. sont rencontrées.\n",
                "en": "The \"Stock Sale\" tool allows calculating the tax consequences of selling company shares, such as a capital gain or loss. Optitax's \"Stock Sale\" tool offers several advantages, such as:\n\n1. Comprehensive calculation of the tax consequences based on whether or not section 84.1 of the Income Tax Act applies to the transaction.\n1. Calculation of the tax attributes of the shares sold and received as consideration, if applicable.\n1. The tool also determines the deemed dividend amount when the conditions of section 84.1 of the Income Tax Act are met.\n"
            }
        ),
        callToAction: {
            displayText: new TextContentModel(
                {"fr": "Découvrir l'outil \"Vente d'actions\"", "en": "Discover the \"Stocks Sale\" tool"}
            ),
            calculationKeyname: new TextContentModel(
                {
                    "fr": CalculationKeynameRoutePathConstants[LocaleEnum.French].SECTION_84_1,
                    "en": CalculationKeynameRoutePathConstants[LocaleEnum.English].SECTION_84_1
                }
            )
        }
    })
}
