import {TextContentModel} from "./text.content-model";
import {ImageContentModel} from "./image.content-model";
import {MarkdownContentModel} from "./markdown.content-model";

export class CardContentModel {
    title: TextContentModel;

    image?: ImageContentModel;

    description?: MarkdownContentModel;

    callToAction?: {
        displayText?: TextContentModel,
        calculationKeyname?: TextContentModel;
    };

    constructor(options: CardContentModel) {
        this.title = options.title;
        this.image = options.image;
        this.description = options.description
        this.callToAction = options.callToAction;
    }
}
