import {ErrorMessage} from "@pristine-ts/class-validator";
import {ConstraintErrorKeynameEnum} from "@pristine-ts/class-validator/dist/types/enums/constraint-error-keyname.enum";
import {validationErrorMessagesConstant} from "../constants/validation-error-messages.constant";
import {ErrorMessageBuilderMetadataInterface} from "../interfaces/error-message-builder-metadata.interface";

export class ErrorMessageBuilder {
    static build(constraintKeyname: ConstraintErrorKeynameEnum, value: any, propertyKey: string, target: any, constraints: any, metadata?: ErrorMessageBuilderMetadataInterface): ErrorMessage {

        let message: string = validationErrorMessagesConstant[constraintKeyname][metadata?.language ?? "fr"];
        // todo, they will only be replaced once, even if they appear more than once.

        for(const constraintKey in constraints) {
            // todo: Check the type and json serialize it if array, object. If date, use date-fns format
            message = message.replace(`%${constraintKey}%`, constraints[constraintKey] + "");
        }

        // todo: Check the type and json serialize it if array, object. If date, use date-fns format
        message = message.replace(`%value%`, value + "");

        if(metadata && metadata.hasOwnProperty("error_context")) {
            for(const key in metadata.errorContext) {
                // todo: Check the type and json serialize it if array, object. If date, use date-fns format
                message = message.replace(`%${key}%`, metadata.errorContext + "");
            }
        }


        return {
            keyname: constraintKeyname,
            message
        };
    }
}