var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { DataMappingNodeTypeEnum } from "../enums/data-mapping-node-type.enum";
import { DataNormalizerAlreadyAdded } from "../errors/data-normalizer-already-added.error";
import { DataMappingSourcePropertyNotFoundError } from "../errors/data-mapping-source-property-not-found.error";
import { ArrayDataMappingNodeInvalidSourcePropertyTypeError } from "../errors/array-data-mapping-node-invalid-source-property-type.error";
export class DataMappingLeaf {
  constructor(root, parent, type = DataMappingNodeTypeEnum.Leaf) {
    this.root = root;
    this.parent = parent;
    this.type = type;
    /**
     * This property contains an array of Normalizers to apply sequentially when mapping this property.
     */
    this.normalizers = [];
    /**
     * This property contains an array of Normalizers that must be excluded from normalizers defined by parents.
     */
    this.excludedNormalizers = new Set();
    /**
     * This method specified whether it's possible that this element not be present in the `source` object.
     */
    this.isOptional = false;
  }
  /**
   * This is a setter for `sourceProperty`.
   * @param sourceProperty
   */
  setSourceProperty(sourceProperty) {
    this.sourceProperty = sourceProperty;
    return this;
  }
  /**
   * This is a setter for `destinationProperty`.
   * @param destinationProperty
   */
  setDestinationProperty(destinationProperty) {
    this.destinationProperty = destinationProperty;
    return this;
  }
  /**
   * This is a setter for `isOptional`.
   * @param isOptional
   */
  setIsOptional(isOptional) {
    this.isOptional = isOptional;
    return this;
  }
  /**
   * This methods adds a normalizer but checks that this normalizer hasn't been added already (either at the root) or
   * directly on this leaf.
   *
   * @param normalizerUniqueKey
   * @param options
   */
  addNormalizer(normalizerUniqueKey, options) {
    if (this.hasNormalizer(normalizerUniqueKey)) {
      throw new DataNormalizerAlreadyAdded("The data normalizer '" + normalizerUniqueKey + "' has already been added to the leaf with destination property: '" + this.destinationProperty + "'.", normalizerUniqueKey, options);
    }
    if (this.root.hasNormalizer(normalizerUniqueKey)) {
      throw new DataNormalizerAlreadyAdded("The data normalizer '" + normalizerUniqueKey + "' has already been added to the root and cannot be also added to the leaf with destination property: '" + this.destinationProperty + "'.", normalizerUniqueKey, options);
    }
    this.normalizers.push({
      key: normalizerUniqueKey,
      options
    });
    return this;
  }
  /**
   * This method simply returns whether the normalizer was already added to this node.
   * @param normalizerUniqueKey
   */
  hasNormalizer(normalizerUniqueKey) {
    return this.normalizers.find(element => element.key === normalizerUniqueKey) !== undefined;
  }
  /**
   * This method adds a normalizer that must be excluded from the normalizers applied at a higher level.à
   * @param normalizerUniqueKey
   */
  excludeNormalizer(normalizerUniqueKey) {
    if (this.excludedNormalizers.has(normalizerUniqueKey)) {
      throw new DataNormalizerAlreadyAdded("The EXCLUDED data normalizer '" + normalizerUniqueKey + "' has already been added to this source property: '" + this.sourceProperty + "'.", normalizerUniqueKey);
    }
    this.excludedNormalizers.add(normalizerUniqueKey);
    return this;
  }
  /**
   * This method adds this node to its parent and returns the parent.
   */
  end() {
    // todo: Validate that we actually have all the properties needed (sourceProperty and destinationProperty) for example.
    this.parent.addNode(this);
    return this.parent;
  }
  /**
   * This method maps the `sourceProperty` from the `source` object and maps it to the `destinationProperty` of the
   * `destination` object while applying the normalizers.
   *
   * @param source
   * @param destination
   * @param normalizersMap
   */
  map(source, destination, normalizersMap, options) {
    return __awaiter(this, void 0, void 0, function* () {
      if (source.hasOwnProperty(this.sourceProperty) === false) {
        if (this.isOptional) {
          return;
        }
        throw new DataMappingSourcePropertyNotFoundError("The property '" + this.sourceProperty + "' isn't found in the Source object and isn't marked as Optional. If you want to ignore this property, use the 'setIsOptional(true)' method in the builder.", this.sourceProperty);
      }
      const normalizers = this.root.normalizers.filter(element => this.excludedNormalizers.has(element.key) === false);
      normalizers.push(...this.normalizers);
      if (this.type === DataMappingNodeTypeEnum.ScalarArray) {
        // This means that the source[propertyKey] contains an array of objects and each object should be mapped
        const array = source[this.sourceProperty];
        if (Array.isArray(array) === false) {
          throw new ArrayDataMappingNodeInvalidSourcePropertyTypeError(`According to your schema, the property '${this.sourceProperty}' in the source object must contain an Array of Scalar. Instead, it contains: '${typeof array}'.`, this.sourceProperty);
        }
        destination[this.destinationProperty] = [];
        for (let value of array) {
          normalizers.forEach(element => {
            const normalizer = normalizersMap[element.key];
            value = normalizer.normalize(value, element.options);
          });
          destination[this.destinationProperty].push(value);
        }
        return;
      }
      let value = source[this.sourceProperty];
      normalizers.forEach(element => {
        const normalizer = normalizersMap[element.key];
        value = normalizer.normalize(value, element.options);
      });
      destination[this.destinationProperty] = value;
      return;
    });
  }
  /**
   * This method imports a schema.
   *
   * @param schema
   */
  import(schema) {
    this.normalizers = schema.normalizers;
    this.excludedNormalizers = new Set();
    if (schema.hasOwnProperty("excludedNormalizers")) {
      for (const item in schema.excludedNormalizers) {
        this.excludeNormalizer(item);
      }
    }
    this.isOptional = schema.isOptional;
    this.sourceProperty = schema.sourceProperty;
    this.destinationProperty = schema.destinationProperty;
  }
  /**
   * This method exports this node.
   */
  export() {
    const excludedNormalizers = {};
    for (const element of this.excludedNormalizers.values()) {
      excludedNormalizers[element] = true;
    }
    return {
      "_type": this.type,
      "sourceProperty": this.sourceProperty,
      "destinationProperty": this.destinationProperty,
      "isOptional": this.isOptional,
      "normalizers": this.normalizers,
      "excludedNormalizers": excludedNormalizers
    };
  }
}
