import {
  customValidator,
  IsBoolean,
  IsNotEmpty,
  IsString,
  ValidateNested,
  ErrorMessage,
  IsOptional
} from "@pristine-ts/class-validator";
import {translationBaseKey} from "../../../decorators/translation-base-key.decorator";
import {CalculationRunOptions} from "../calculation-run.options";
import {advancedFormControl} from "../../../decorators/advanced-form-control.decorator";
import {CalculationKeynameEnum} from "../../../enums/calculations/calculation-keyname.enum";
import {FieldTypeEnum} from "../../../enums/field-type.enum";
import {advancedFormArray} from "../../../decorators/advanced-form-array.decorator";
import {RolloverPropertyTransferredOptions} from "./rollover-property-transferred.options";
import {RolloverSharesClassOptions} from "./rollover-shares-class.options";
import {RolloverShareholderOptions} from "./rollover-shareholder.options";
import {RolloverNonShareConsiderationOptions} from "./rollover-non-share-consideration.options";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";
import {Type} from "class-transformer";
import {CalculationDate} from "../../../models/calculation.date";
import {type} from "@pristine-ts/data-mapping-common";

@translationBaseKey("rollover-options")
export class RolloverOptions extends CalculationRunOptions {
  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.Rollover;

  @advancedFormControl({
    labelTitle: {
      fr: "Nom de la société cessionnaire",
      en: "Name of the transferee corporation"
    },
    tooltip: {
      fr: "Indiquez le nom de la société à qui les biens seront transférés dans le cadre de la transaction de roulement.",
      en: "Specify the name of the corporation to whom the assets will be transferred in the rollover transaction.",
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  buyerCorporation: string;

  @type(() => new CalculationDate())
  @advancedFormControl({
    labelTitle: {
      fr: "Date de signature",
      en: "Signature date"
    },
    tooltip: {
      fr: "Indiquez la date à laquelle le contrat de roulement a été signé.",
      en: "Specify the date when the rollover agreement was signed.",
    },
    fieldType: FieldTypeEnum.CalculationDate
  })
  @ValidateNested()
  @IsOptional()
  signatureDate?: CalculationDate;

  @type(() => new CalculationDate())
  @advancedFormControl({
    labelTitle: {
      fr: "Date limite de production",
      en: "Production deadline"
    },
    tooltip: {
      fr: "Indiquez la date limite de production des formulaires de roulement T2057 et TP-518.",
      en: "Specify the deadline for filing the T2057 and TP-518 rollover forms.",
    },
    fieldType: FieldTypeEnum.CalculationDate
  })
  @ValidateNested()
  @IsOptional()
  productionDeadline?: CalculationDate;

  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous remplir les formulaires de roulement T2057 et TP-518?",
      en: "Do you want to fill out T2057 and TP-518 rollover forms?"
    },
    tooltip: {
      fr: "Indiquez si vous souhaitez remplir les formulaires de roulement T2057 et TP-518 pour cette transaction.",
      en: "Specify if you want to fill out the T2057 and TP-518 rollover forms for this transaction.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isThereRolloverForm: boolean = false;

  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 1,
    elementClassType: RolloverSharesClassOptions,
  })
  @ValidateNested()
  sharesClasses: RolloverSharesClassOptions[] = [];

  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 1,
    elementClassType: RolloverPropertyTransferredOptions,
  })
  @ValidateNested()
  propertiesTransferred: RolloverPropertyTransferredOptions[] = [];

  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 1,
    elementClassType: RolloverShareholderOptions,
  })
  @ValidateNested()
  @customValidator(async (value: RolloverShareholderOptions[]) => {
    if (value.filter(item => item.isSeller).length !== 1) {
      return {
        message: "Il doit y avoir exactement un seul actionnaire qui est vendeur.",
        keyname: "EXACTLY_ONE_SELLER",
      } as ErrorMessage;
    }
    return null;
  })
  shareholders: RolloverShareholderOptions[] = [];

  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 0,
    elementClassType: RolloverNonShareConsiderationOptions,
  })
  @ValidateNested()
  nonShareConsiderations: RolloverNonShareConsiderationOptions[] = [];
}
