import {Component, OnInit} from '@angular/core';
import {LocaleManager} from "../../managers/locale.manager";
import {RouteManager} from "../../managers/route.manager";
import {PreferenceManager} from "../../managers/preference.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {IndexContentResources} from "../../content/resources/index.content-resources";
import {RouteEnum} from "../../enums/route.enum";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {LoadingEnum} from "../../enums/loading.enum";
import {KeywordsContentResources} from "../../content/resources/keywords.content-resources";
import {BaseComponent} from "../../components/base.component";
import {Meta, Title} from "@angular/platform-browser";
import {HeaderStyleEnum} from "../../enums/header-style.enum";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent extends BaseComponent {

  constructor(
      localeManager: LocaleManager,
      routeManager: RouteManager,
      contentManager: ContentManager,
      titleService: Title,
      meta: Meta,
      private readonly preferenceManager: PreferenceManager,
  ) {
    super(localeManager,
        routeManager,
        contentManager,
        titleService,
        meta,
        IndexContentResources
    )
  }

  protected readonly IndexContentResources = IndexContentResources;
  protected readonly RouteEnum = RouteEnum;
  protected readonly FetchPriorityEnum = FetchPriorityEnum;
  protected readonly LoadingEnum = LoadingEnum;
  protected readonly KeywordsContentResources = KeywordsContentResources;
  protected readonly HeaderStyleEnum = HeaderStyleEnum;
}
