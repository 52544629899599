import {translationBaseKey} from "../../../../../decorators/translation-base-key.decorator";
import {CalculationRunOptions} from "../../../calculation-run.options";
import {CalculationKeynameEnum} from "../../../../../enums/calculations/calculation-keyname.enum";
import {advancedFormControl} from "../../../../../decorators/advanced-form-control.decorator";
import {FieldTypeEnum} from "../../../../../enums/field-type.enum";
import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumber, IsOptional,
  IsString,
  Max,
  Min,
  validateIf
} from "@pristine-ts/class-validator";
import {CompanyTypeEnum} from "../../../../../enums/company-type.enum";
import {ErrorMessageBuilder} from "../../../../../builders/error-message.builder";
import {ValidationGroupEnum} from "../../../../../enums/validation-group.enum";

@translationBaseKey("income-tax-corporation-quebec-options")
export class CorporateIncomeTaxQuebecOptions extends CalculationRunOptions {

  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.CorporateTaxQuebec;

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre d'heures rémunérés total de la société pour l'année actuelle",
      en: "Company's total paid hours for the current year",
    },
    tooltip: {
      fr: "Le nombre total d'heures rémunérées de la société pour l'année en cours. Cela inclut les heures rémunérées aux employés et associés.",
      en: "The total number of paid hours of the company for the current year. This includes the paid hours to employees and associates.",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsNumber(undefined, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: CorporateIncomeTaxQuebecOptions, root, currentPath) => object.isManufacturingSectorActivity === false)
  currentPaidHours: number;
  //heuresRemunereesActuelles

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre d'heures rémunérés total de la société incluant ceux des sociétés associés pour l'année précédente",
      en: "Company's total paid hours including those of associated companies for the previous year",
    },
    tooltip: {
      fr: "Le nombre total d'heures rémunérées de la société, y compris celles des sociétés associées, pour l'année précédente. Cela est utilisé dans le calcul de certains crédits d'impôt.",
      en: "The total number of paid hours of the company, including those of associated companies, for the previous year. This is used in the calculation of certain tax credits.",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsNumber(undefined, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: CorporateIncomeTaxQuebecOptions, root, currentPath) => object.isManufacturingSectorActivity === false)
  previousPaidHours: number;
  //heuresRemunereesPrecedentes

  @advancedFormControl({
    labelTitle: {
      fr: "Dividendes totaux de sociétés canadiennes imposables",
      en: "Total dividends from taxable Canadian corporations",
    },
    tooltip: {
      fr: "Le montant total des dividendes reçus de sociétés canadiennes imposables au cours de l'année. Ces dividendes sont pris en compte dans le calcul du revenu imposable de la société.",
      en: "The total amount of dividends received from taxable Canadian corporations during the year. These dividends are considered in the calculation of the company's taxable income.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({
    groups: [ValidationGroupEnum.SalaryDividend, ValidationGroupEnum.IncomeTaxCorporation, ValidationGroupEnum.IncomeTaxCorporationFederal]
  })
  taxableCanadianCorporateDividend: number;
  //dividende_SCI_total

  @advancedFormControl({
    labelTitle: {
      fr: "Revenu de bien autres que gain/perte en capital? (Provient du fédéral)",
      en: "Income from property other than capital gain/loss? (Comes from the federal government)",
    },
    tooltip: {
      fr: "Le revenu provenant de biens détenus par la société qui ne sont pas liés aux gains ou aux pertes en capital. Il est rapporté séparément dans la déclaration d'impôt.",
      en: "The income from properties owned by the company that are not related to capital gains or losses. It is reported separately in the tax return.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({
    groups: [ValidationGroupEnum.SalaryDividend, ValidationGroupEnum.IncomeTaxCorporation, ValidationGroupEnum.IncomeTaxCorporationFederal],
  })
  propertyIncomeExceptCapitalGain: number;
  //revenu_biens_autres_excepte_gain_perte

  @advancedFormControl({
    labelTitle: {
      fr: "Gain en capital moins les pertes en capital (inscrire 0 si négatif)? (provient du fédéral)",
      en: "Capital gain minus the capital losses (enter 0 if negative)? (from federal)",
    },
    tooltip: {
      fr: "La différence entre le gain en capital et la perte en capital réalisée par la société au cours de l'année. Si cette différence est négative, la valeur zéro doit être indiquée.",
      en: "The difference between the capital gain and capital loss realized by the company during the year. If this difference is negative, the value zero should be indicated.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({
    groups: [ValidationGroupEnum.SalaryDividend, ValidationGroupEnum.IncomeTaxCorporation, ValidationGroupEnum.IncomeTaxCorporationFederal],
  })
  gainLossExcedent: number;
  //excedent_gain_perte

  @advancedFormControl({
    labelTitle: {
      fr: "Revenu d'entreprise gagné à l'extérieur du Canada",
      en: "Business income earned outside of Canada",
    },
    tooltip: {
      fr: "Le revenu d'entreprise généré par la société à l'extérieur du Canada. Ce revenu est rapporté séparément dans la déclaration d'impôt.",
      en: "The business income generated by the company outside of Canada. This income is reported separately in the tax return.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  taxableIncomeOutsideCanada: number = 0;
  //revenu_imposable_exterieur_Canada

  @advancedFormControl({
    labelTitle: {
      fr: "La société exerce-t-elle des activités du secteur primaire ou manufacturier?",
      en: "Is the company engaged in activities in the primary or manufacturing sector?",
    },
    tooltip: {
      fr: "Indique si la société exerce des activités dans le secteur primaire ou manufacturier. Cette information est utilisée pour déterminer l'éligibilité à certains crédits d'impôt.",
      en: "Indicates whether the company is engaged in activities in the primary or manufacturing sector. This information is used to determine eligibility for certain tax credits.",
    },
    fieldType: FieldTypeEnum.Boolean,
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isManufacturingSectorActivity: boolean;
  //activite_secteur_manufacturier

  @advancedFormControl({
    labelTitle: {
      fr: "Dividendes totaux de sociétés canadiennes",
      en: "Total dividends from Canadian corporations",
    },
    tooltip: {
      fr: "Le montant total des dividendes reçus de toutes les sociétés canadiennes par la société. Ces dividendes sont pris en compte dans le calcul du revenu imposable.",
      en: "The total amount of dividends received from all Canadian corporations by the company. These dividends are considered in the calculation of taxable income.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({
    groups: [ValidationGroupEnum.SalaryDividend, ValidationGroupEnum.IncomeTaxCorporation, ValidationGroupEnum.IncomeTaxCorporationFederal],
  })
  tCCDividend: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Capital imposable des sociétés associées de l'année actuelle ou l'année précédente, selon le cas (voir 125(5.1))",
      en: "Taxable capital of the connected corporations in the current or previous year, as the case may be (see 125(5.1))",
    },
    tooltip: {
      fr: "Le montant du capital imposable détenu par les sociétés associées de la société pour l'année en cours ou l'année précédente, selon le cas. Ce montant est utilisé dans le calcul de certains crédits d'impôt.",
      en: "The amount of taxable capital held by the associated corporations of the company for the current year or previous year, as the case may be. This amount is used in the calculation of certain tax credits.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({
    groups: [ValidationGroupEnum.SalaryDividend, ValidationGroupEnum.IncomeTaxCorporation, ValidationGroupEnum.IncomeTaxCorporationFederal],
  })
  totalTaxableIncome: number;
}
