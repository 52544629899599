import {
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsInt,
  IsString,
  ValidateIf,
  ValidateNested,
  ArrayMinSize
} from "@pristine-ts/class-validator";
import {advancedFormControl} from "../../../decorators/advanced-form-control.decorator";
import {translationBaseKey} from "../../../decorators/translation-base-key.decorator";
import {FieldTypeEnum} from "../../../enums/field-type.enum";
import {RolloverPropertyTransferredNonShareConsiderationOptions} from "./rollover-property-transferred-non-share-consideration.options";
import {v4 as uuid} from 'uuid';
import {RolloverPropertyTransferredSharesClassOptions} from "./rollover-property-transferred-shares-class.options";
import {RolloverPropertyTransferredSection84_1Options} from "./rollover-property-transferred-section-84_1.options";
import {advancedFormGroup} from "../../../decorators/advanced-form-group.decorator";
import {RolloverPropertyTypeEnum} from "../../../enums/calculations/rollover/rollover-property-type.enum";
import {RolloverPrecisePropertyTypeEnum} from "../../../enums/calculations/rollover/rollover-precise-property-type.enum";
import {RolloverPropertyRevenueTypeEnum} from "../../../enums/calculations/rollover/rollover-property-revenue-type.enum";
import {advancedFormArray} from "../../../decorators/advanced-form-array.decorator";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-property-transferred-options")
export class RolloverPropertyTransferredOptions {
  @advancedFormControl({
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "Description du bien",
      en: "Description of the property"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  title: string;

  @advancedFormControl({
    labelTitle: {
      fr: "Juste valeur marchande du bien",
      en: "Fair market value of the property"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  fairMarketValue: number;

  @advancedFormControl({
    labelTitle: {
      fr: "S'agit-il d'un bien immeuble?",
      en: "Is it a real estate property?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.isVerifyPropertyEligibility)
  isRealEstate: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Propriétaire non résident?",
      en: "Owner a non-resident?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.isVerifyPropertyEligibility && o.isRealEstate && o.propertyType === "INVENTORY_PROPERTY")
  isNonResident: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "S'agit-il d'un compte client?",
      en: "Is this a customer account?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.isVerifyPropertyEligibility)
  isCustomerAccount: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que vous avez choisi l'article 22?",
      en: "Was section 22 chosen?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.isVerifyPropertyEligibility)
  isArticle22?: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Revenu selon méthode de comptabilité de caisse?",
      en: "Business income on cash method of accounting?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.isVerifyPropertyEligibility)
  isCashAccounting?: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Type de biens transférés",
      en: "Type of assets being transferred"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    selectKeyValues: [
      {
        key: {
          fr: "Avoir minier",
          en: "Mineral asset"
        },
        value: RolloverPrecisePropertyTypeEnum.mineralAsset
      },
      {
        key: {
          fr: "Valeurs ou titres de créance",
          en: "Debt obligations"
        },
        value: RolloverPrecisePropertyTypeEnum.debtObligations
      },
      {
        key: {
          fr: "Titre de créance déterminé (institution financière)",
          en: "Specific debt obligations"
        },
        value: RolloverPrecisePropertyTypeEnum.specificDebtObligations
      },
      {
        key: {
          fr: "Immobilisation qui est un bien immeuble détenu par un non-résident",
          en: "Capital property owned by a non-resident"
        },
        value: RolloverPrecisePropertyTypeEnum.capitalPropertyThatIsRealPropertyOwnedByNonResident
      },
      {
        key: {
          fr: "Immobilisation ammortissable",
          en: "Depreciable property"
        },
        value: RolloverPropertyTypeEnum.depreciableProperty
      },
      {
        key: {
          fr: "Immobilisation non ammortissable",
          en: "Non depreciable property"
        },
        value: RolloverPropertyTypeEnum.nonDepreciableProperty
      },
      {
        key: {
          fr: "Bien en inventaire",
          en: "Inventory property"
        },
        value: RolloverPropertyTypeEnum.inventoryProperty
      },
      {
        key: {
          fr: "Second fonds de l'Agri-Investissement",
          en: "Agri investment"
        },
        value: RolloverPrecisePropertyTypeEnum.agriInvestment
      },
    ],
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsEnum(RolloverPropertyTypeEnum, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  propertyType: RolloverPropertyTypeEnum;

  @advancedFormControl({
    labelTitle: {
      fr: "Somme convenue du bien",
      en: "Agreed amount of the property"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.isDoPerfectRollover === false)
  agreedAmount?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Prix de base rajusté du bien (PBR)",
      en: "Adjusted cost base (ACB) of the property"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  adjustedCostBase: number;

  @advancedFormControl({
    labelTitle: {
      fr: "FNACC du bien",
      en: "UCC of the property"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.propertyType === "DEPRECIABLE_PROPERTY")
  underpreciatedCapitalCostAfterAdditionsAndDispositions?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Bien amortissable est le seul bien de sa catégorie d'amortissement?",
      en: "Depreciable property the last of its category?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.propertyType === "DEPRECIABLE_PROPERTY")
  isDepreciablePropertyTheLastOfItsCategory?: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous calculer le nombre d'actions privilégiées?",
      en: "Should calculate number of preferred shares?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isCalculateNumberOfPreferredShares: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Actions cédées ont des privilèges, droits, conditions et restrictions identiques?",
      en: "Transferred shares have identical privileges, rights, conditions, and restrictions?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.propertyType === "NON_DEPRECIABLE_PROPERTY" && o.areShares)
  isSameSharesConditions?: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce un roulement interne?",
      en: "Is it an internal rollover?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.propertyType === "NON_DEPRECIABLE_PROPERTY" && o.areShares)
  isInternalRollOver: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous faire un roulement parfait?",
      en: "Do you want to make a perfect rollover?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isDoPerfectRollover: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer le CAA maximale?",
      en: "Calculate maximum non-share consideration?"
    },
    tooltip: {
      fr: "Voulez-vous que le logiciel calcule le CAA maximale afin de ne pas avoir de conséquences fiscales?"
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isCalculateMaximumNonShareConsideration: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Type de revenus généré par le bien transféré",
      en: "Type of revenue generated by the transferred property"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    selectKeyValues: [
      {
        key: {
          fr: "Revenue d'entreprise",
          en: "Company revenue"
        },
        value: RolloverPropertyRevenueTypeEnum.companyRevenue
      },
      {
        key: {
          fr: "Revenue de biens",
          en: "Property revenue"
        },
        value: RolloverPropertyRevenueTypeEnum.propertyRevenue
      },
    ],
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsEnum(RolloverPropertyRevenueTypeEnum, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.propertyType === "DEPRECIABLE_PROPERTY")
  propertyRevenueType?: RolloverPropertyRevenueTypeEnum;

  @advancedFormControl({
    labelTitle: {
      fr: "Capital versé des actions cédées",
      en: "Paid-up capital of the transferred shares"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.propertyType === "NON_DEPRECIABLE_PROPERTY" && o.areShares)
  paidUpCapitalOfTransferredShares?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Bien transféré est une action d’une société?",
      en: "Transferred property is a share of a corporation?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => o.propertyType === "NON_DEPRECIABLE_PROPERTY")
  areShares: boolean; // todo: find a better name

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que le bien génère des revenus d'entreprise?",
      en: "Does property generate business income?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o => {
    return o.propertyType === "MINERAL_ASSET" ||
        o.propertyType === "DEBT_OBLIGATIONS" ||
        o.propertyType === "SPECIFIC_DEBT_OBLIGATIONS" ||
        o.propertyType === "AGRI_INVESTMENT";
  })
      //todo add this in the calculation
  isBusinessIncome?: boolean;

  @advancedFormGroup({
    classType: RolloverPropertyTransferredSection84_1Options,
  })
  @ValidateNested()
  @ValidateIf(o => o.propertyType === "NON_DEPRECIABLE_PROPERTY" && o.areShares === true)
  section84_1?: RolloverPropertyTransferredSection84_1Options;

  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 1,
    elementClassType: RolloverPropertyTransferredNonShareConsiderationOptions,
  })
  @ValidateNested()
  nonShareConsiderations: RolloverPropertyTransferredNonShareConsiderationOptions[] = [];

  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 1,
    elementClassType: RolloverPropertyTransferredSharesClassOptions,
  })
  @ArrayMinSize(1)
  @ValidateNested()
  sharesClasses: RolloverPropertyTransferredSharesClassOptions[] = [];

  // UI only options
  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous vérifier l'admissibilité du bien transféré au choix du roulement de l'article 85 LIR?",
      en: "Do you want to verify if the property is eligible to rollover under article 85 ?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isVerifyPropertyEligibility: boolean;
}
