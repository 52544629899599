import {RouteManager} from "../managers/route.manager";
import {LocaleManager} from "../managers/locale.manager";
import {ContentManager} from "../content/managers/content.manager";
import {Subscription} from "rxjs";
import {Component, Directive} from "@angular/core";
import {ContentResourceInterface} from "../content/interfaces/content-resource.interface";
import {Meta, Title} from "@angular/platform-browser";

@Directive()
export abstract class BaseComponent {
    get content(): ContentResourceInterface | undefined {
        return this._content;
    }

    set content(value: ContentResourceInterface) {
        this._content = value;

        this.setTitleAndMeta();
    }

    protected readonly subscriptions: Subscription[] = [];

    private _content?: ContentResourceInterface;

    protected constructor(
        public readonly localeManager: LocaleManager,
        public readonly routeManager: RouteManager,
        public readonly contentManager: ContentManager,
        public readonly titleService: Title,
        public readonly meta: Meta,
        content?: ContentResourceInterface,
    ) {
        this._content = content;
    }

    ngOnInit() {
        this.setTitleAndMeta();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    async setTitleAndMeta() {
        if (!this.content) {
            return;
        }

        this.meta.updateTag({
            name: 'description',
            content: await this.contentManager.render(this.content.Meta.Description)
        });
        this.meta.updateTag({
            name: 'keywords',
            content: await this.contentManager.render(this.content.Meta.Keywords)
        });
        this.titleService.setTitle(await this.contentManager.render(this.content.Title));
    }

    registerSubscription(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }
}
