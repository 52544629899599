import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {CardsContentResources} from "./cards.content-resources";
import {CardContentModel} from "../models/card.content-model";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export const IndexContentResources: {
    IndexBodyFeatures: {
        title: TextContentModel,
        image: ImageContentModel,
        description: TextContentModel
    }[],
    BodyThirdSectionFeatures: CardContentModel[],
    BodyFourthSectionFeatures: { title: TextContentModel, subtitle: TextContentModel, iconStyle: TextContentModel }[],
} & ContentResourceInterface & { [id in string]: any } = {
    LastModified: new TextContentModel(
        {"fr": "2024-12-03", "en": "2024-12-03"}
    ),

    Title: new TextContentModel({
        "fr": "Optitax - Ne faites plus vos opérations fiscales dans Excel",
        "en": "Optitax - More powerful and precise than Excel"
    }),

    Meta: {
        Description: new TextContentModel({
            "fr": "Optitax est un logiciel web qui offre des outils utiles et performants dans le  domaine de la fiscalité. Nous offrons des calculs variés, allant d’un calcul simple,  tel qu’un rachat d’actions, à des calculs complexes, tel que la détention personnelle ou  corporative d’un véhicule. La mission d’Optitax est de développer une gamme complète d’outils  fiscaux afin que les cabinets comptables ou autres professionnels n’aient pas à développer leurs propres  outils à l’interne et puissent se concentrer entièrement sur leur pratique.\n",
            "en": "Optitax is a web-based software that offers useful and powerful tools in the field of taxation. We provide a  range of calculations, from simple ones like share buybacks to complex calculations such as personal or  corporate vehicle ownership. Optitax's mission is to develop a comprehensive range of tax tools so that  accounting firms and other professionals do not have to develop their own tools internally and can fully focus on their practice.\n"
        }),

        Keywords: new TextContentModel(
            {
                "fr": "Optitax, Logiciel de gestion fiscale, Outils de calcul fiscal, Logiciel fiscal en ligne, Solutions de fiscalité, Logiciel de comptabilité, Outils financiers pour professionnels, Logiciel de planification fiscale, Gestion fiscale des entreprises, Calculs fiscaux personnels, Solutions fiscales efficaces",
                "en": "Optitax, Tax management software, Tax calculation tools, Web-based tax software, Taxation solutions, Accounting software, Financial tools for professionals, Tax planning software, Corporate tax management, Personal tax calculations, Efficient tax solutions"
            }
        ),
    },

    "index.body.hero.title": new TextContentModel(
        {
            "fr": "Ne faites plus vos opérations fiscales dans Excel",
            "en": "More powerful and precise than Excel. Tax Specialists, here's Optitax."
        }
    ),

    "index.body.hero.subtitle": new MarkdownContentModel(
        {
            "fr": "* Effectuez un Roulement simple ou complexe en quelques secondes\n* Déterminez **au dollar près** le salaire ou le dividende optimal que votre client devrait se verser\n* Comparez les impacts fiscaux d'un achat ou d'une location d'un véhicule pour votre client\n",
            "en": "* Do a simple of complex Tax Rollover in seconds\n* Identify the optimal ratio of salary and dividends for your client\n* Compare the tax impacts of buying or leasing a vehicle\n"
        }
    ),

    "index.body.hero.list-of-calculations": new TextContentModel(
        {"fr": "Consultez la liste de nos outils", "en": "See all the tax tools"}
    ),

    "index.body.second-section.title": new TextContentModel(
        {"fr": "Qui sommes-nous?", "en": "Who are we?"}
    ),

    "index.body.second-section.description": new MarkdownContentModel(
        {
            "fr": "**Nicolas L’Homme, CPA, M. Fisc.** et **Antoine Noël, Ph.D. Économie** ([professeur d’économie à l’université Laval](https://www.fss.ulaval.ca/actualites/nomination-de-m-antoine-noel-en-tant-que-professeur-adjoint-au-departement-deconomique)) ont décidé de fonder Optitax afin de révolutionner le monde de la fiscalité en automatisant des calculs fiscaux avancés tels que:\n\n* [Roulement fiscal](/outils-fiscaux/roulement-fiscal) \n* [Détention personnelle ou corporative d’un véhicule](/outils-fiscaux/detention-automobile-personelle-ou-corporative)\n* [Salaire ou dividendes](/outils-fiscaux/remuneration-salaire-ou-dividendes)\n\nOptitax développe une gamme d’outils fiscaux qui permettent aux fiscalistes et comptables de sauver temps et argent en plus d'offrir une précision inégalée à leurs clients.\n",
            "en": "**Nicolas L’Homme, CPA, M. Fisc.** and **Antoine Noël, Ph.D. Economics** ([Economics professor at Université Laval](https://www.fss.ulaval.ca/actualites/nomination-de-m-antoine-noel-en-tant-que-professeur-adjoint-au-departement-deconomique)) founded Optitax to transform world by automating complex tax calculations such as:\n\n* [Rollover](/tax-tools/rollover) \n* [Vehicle ownership vs leasing](/tax-tools/car-ownership)\n* [Salary vs dividends](/tax-tools/salary-vs-dividends)\n\nOptitax offers a wide range of tax tools and calculations for Tax Pros allowing you to save time, reduce costs and offer a precision unheard of.\n"
        }
    ),

    "index.body.second-section.call-to-action": new TextContentModel(
        {"fr": "Essayez Optitax gratuitement pendant 14 jours!", "en": "Try Optitax for free for 14 days!"}
    ),

    IndexBodyFeatures: [
        {
            title: new TextContentModel(
                {"fr": "Outils complexes", "en": "Advanced tax tools"}
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/brain.svg",
                        en: "images/illustrations/brain.svg",
                    }),
                }
            ),
            description: new TextContentModel(
                {
                    "fr": "Optitax est le seul outil qui vous permet de calculer un roulement ou une analyse salaire-dividendes avec un résultat au dollar près.",
                    "en": "Optitax is the only software that can do a Tax Rollover or Salary vs dividends analysis with a result precise to the dollar amount."
                }
            ),
        },
        {
            title: new TextContentModel(
                {"fr": "Économie de temps", "en": "Save time"}
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/form-audit.svg",
                        en: "images/illustrations/form-audit.svg",
                    }),
                }
            ),
            description: new TextContentModel(
                {
                    "fr": "Optitax optimise et réduit votre entrée de données en ne vous montrant que les questions pertinentes.",
                    "en": "Optitax optimizes and reduces data entry by presenting you only the relevant questions."
                }
            ),
        }
    ],

    "index.body.third-section.title": new TextContentModel(
        {"fr": "Des outils fiscaux puissants", "en": "Powerful tax calculations"}
    ),

    "index.body.third-section.call-to-action": new TextContentModel(
        {"fr": "Consultez la liste complète de nos outils fiscaux", "en": "See all our tax tools"}
    ),

    BodyThirdSectionFeatures: [
        CardsContentResources.SalaryDividends,
        CardsContentResources.CarOwnership,
        CardsContentResources.Rollover,
        CardsContentResources.SharesSelling,
    ],

    "index.body.fourth-section.title": new TextContentModel(
        {"fr": "Êtes-vous prêts à l'essayer?", "en": "Give it a try today!"}
    ),

    "index.body.fourth-section.description": new TextContentModel(
        {
            "fr": "Optitax est gratuit pendant 14 jours et ce, sans carte de crédit! En 2023, dîtes au revoir à Excel et intégrez Optitax à vos processus d’affaires.          \n",
            "en": "Optitax is free for 14 days, without a credit card! In 2023, say goodbye to Excel and integrate Optitax in your business.\n"
        }
    ),

    "index.body.fourth-section.call-to-action": new TextContentModel(
        {"fr": "Débutez gratuitement maintenant!", "en": "Start for free now!"}
    ),

    "index.body.fourth-section.call-to-action-2": new TextContentModel(
        {"fr": "Essayez Optitax dès aujourd'hui!", "en": "Start for free now!"}
    ),

    BodyFourthSectionFeatures: [
        {
            title: new TextContentModel(
                {"fr": "Exportez vos résultats en format Excel", "en": "Export the results in .xls or .xlsx"}
            ),
            subtitle: new TextContentModel(
                {
                    "fr": "Tous vos résultats peuvent être exportés facilement dans un fichier Excel à l’aide d’un seul clic",
                    "en": "All the results can easily be exported to Excel in one click."
                }
            ),
            iconStyle: new TextContentModel({
                "fr": "fa-file-excel",
                "en": "fa-file-excel"
            }),
        },
        {
            title: new TextContentModel(
                {"fr": "Support par clavardage", "en": "Chat support"}
            ),
            subtitle: new TextContentModel(
                {
                    "fr": "Que vous ayez des questions fiscales ou des questions sur le logiciel, utilisez la bulle de clavardage directement disponible dans l’application.",
                    "en": "Whether you have tax questions or general inquiries about our software, use our integrated chat to exchange with us in real-time."
                }
            ),
            iconStyle: new TextContentModel(
                {"fr": "fa-comment", "en": "fa-comment"}
            ),
        },
        {
            title: new TextContentModel(
                {
                    "fr": "Remplissage automatique des formulaires de Revenu Québec et l'Agence de Revenu du Canada",
                    "en": "Revenu Québec and CRA forms are filled automatically for you"
                }
            ),
            subtitle: new TextContentModel(
                {
                    "fr": "Notre fonction avancée vous permet d’exporter directement vos résultats dans le formulaire pdf des deux paliers de gouvernement.",
                    "en": "Optitax directly exports the results into the appropriate government forms."
                }
            ),
            iconStyle: new TextContentModel(
                {"fr": "fa-file-pdf", "en": "fa-file-pdf"}
            ),
        },
        {
            title: new TextContentModel(
                {"fr": "Accédez à vos calculs sur tous vos appareils", "en": "Our Tax Tools are available on all your devices."}
            ),
            subtitle: new TextContentModel(
                {
                    "fr": "Notre outil est totalement web. Ainsi, vous pouvez y accéder sur tous vos ordinateurs ou appareils mobiles (iPhone, iPad, etc.)",
                    "en": "Optitax is web-based, meaning that it can be acessed on any device (iPhone, iPad, etc.) using a browser."
                }
            ),
            iconStyle: new TextContentModel(
                {"fr": "fa-browser", "en": "fa-browser"}
            ),
        }
    ],

    "index.body.sixth-section.title": new TextContentModel(
        {"fr": "Avez-vous des questions?", "en": "Any questions?"}
    ),

    "index.body.sixth-section.call-to-action.display-text": new TextContentModel(
        {"fr": "Clavardage en ligne", "en": "Chat online"}
    ),

    "index.body.sixth-section.call-to-action.icon-class": new TextContentModel(
        {"fr": "fa fa-comment"}
    ),

    "index.body.sixth-section.call-to-action-2.display-text": new TextContentModel(
        {"fr": "Courriel", "en": "Email"}
    ),

    "index.body.sixth-section.call-to-action-2.icon-class": new TextContentModel(
        {"fr": "fa fa-envelope"}
    ),

    "index.body.sixth-section.call-to-action2": new TextContentModel(
        {"fr": "Avez-vous des questions?", "en": "Any questions?"}
    ),

    "index.body.sixth-section.description": new TextContentModel(
        {
            "fr": "Il nous fera plaisir de répondre à toutes vos questions. N’hésitez pas à nous contacter par clavardage en ligne ou par courriel",
            "en": "We will answer any questions you have! Do not hesitate to contact us by chat or email."
        }
    ),
}
