import {Inject, Injectable} from "@angular/core";
import {LifecycleListenerInterface, LifecycleListenerInterfaceToken} from "../interfaces/lifecycle-listener.interface";
import {forkJoin} from "rxjs";

@Injectable()
export class LifecycleManager {
    constructor(@Inject(LifecycleListenerInterfaceToken) private readonly lifecycleListeners: LifecycleListenerInterface[]) {
    }

    public async firstLoad() {
        for (const lifecycleListener of this.lifecycleListeners) {
            try {
                await lifecycleListener.onFirstLoad()
            } catch (e) {
                console.error("An error occurred while calling the onFirstLoad method of a lifecycle listener", e);
            }
        }
    }
}