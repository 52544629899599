import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ImageContentModel} from "../../content/models/image.content-model";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {LoadingEnum} from "../../enums/loading.enum";
import {TextRenderer} from "../../content/renderers/text.renderer";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss'
})
export class ImageComponent {
  get imageContentModel(): ImageContentModel | undefined {
    return this._imageContentModel;
  }

  @Input()
  set imageContentModel(value: ImageContentModel | undefined) {
    this._imageContentModel = value;

    if (this._imageContentModel?.url) {
      this.textRenderer.render(this._imageContentModel.url).then(value => {
        this.url = value;
      })
    }
  }

  private _imageContentModel?: ImageContentModel;

  @Input()
  url?: string | null;

  @Input()
  fetchPriority: FetchPriorityEnum = FetchPriorityEnum.Low;

  @Input()
  loading: LoadingEnum = LoadingEnum.Lazy;

  @Input()
  imageClass: string = "";

  constructor(
      public readonly textRenderer: TextRenderer,
      ) {
  }

  getUrl() {
    return this.url;
  }
}
