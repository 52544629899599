import {advancedFormControl} from "../../../../../decorators/advanced-form-control.decorator";
import {FieldTypeEnum} from "../../../../../enums/field-type.enum";
import {IsInt, IsNotEmpty, IsNumber, IsString} from "@pristine-ts/class-validator";
import {translationBaseKey} from "../../../../../decorators/translation-base-key.decorator";
import {ErrorMessageBuilder} from "../../../../../builders/error-message.builder";
import {v4 as uuid} from "uuid";

@translationBaseKey("corporate-tax-federal-connected-corporations-options")
export class CorporateTaxFederalConnectedCorporationOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Nom de la société rattachée",
      en: "Name of the connected corporation",
    },
    tooltip: {
      fr: "Le nom de la société rattachée pour laquelle les calculs fiscaux sont effectués.",
      en: "The name of the connected corporation for which the tax calculations are performed.",
    },
    fieldType: FieldTypeEnum.String,
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  title: string;

  @advancedFormControl({
    labelTitle: {
      fr: "Identifiant de la société rattachée",
      en: "ID of the connected corporation",
    },
    tooltip: {
      fr: "L'identifiant unique de la société rattachée, généré automatiquement lors de la création.",
      en: "The unique identifier of the connected corporation, automatically generated during creation.",
    },
    fieldType: FieldTypeEnum.String,
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "RTDND de la société rattachée",
      en: "Non-Eligible Dividend Refund of the connected corporation",
    },
    tooltip: {
      fr: "Le montant du remboursement au titre de dividendes non déterminés (RTDND) pour la société rattachée. Ce montant est utilisé dans les calculs fiscaux.",
      en: "The amount of Non-Eligible Dividend Refund (RTDND) for the connected corporation. This amount is used in tax calculations.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  dividendRefundNonEligible: number;

  @advancedFormControl({
    labelTitle: {
      fr: "RTDD de la société rattachée",
      en: "Eligible Dividend Refund of the connected corporation",
    },
    tooltip: {
      fr: "Le montant du remboursement au titre de dividendes déterminés (RTDD) pour la société rattachée. Ce montant est utilisé dans les calculs fiscaux.",
      en: "The amount of Eligible Dividend Refund (RTDD) for the connected corporation. This amount is used in tax calculations.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  dividendRefundEligible: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Dividendes ordinaires reçus de cette société",
      en: "Ordinary dividends received from this corporation",
    },
    tooltip: {
      fr: "Le montant total des dividendes ordinaires reçus de la société rattachée au cours de l'année. Ces dividendes sont soumis à un traitement fiscal spécifique.",
      en: "The total amount of ordinary dividends received from the connected corporation during the year. These dividends are subject to specific tax treatment.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  nonEligibleDividendReceived: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Dividendes déterminés reçus de cette société",
      en: "Eligible dividends received from this corporation",
    },
    tooltip: {
      fr: "Le montant total des dividendes déterminés reçus de la société rattachée au cours de l'année. Ces dividendes peuvent être admissibles à des taux d'imposition réduits.",
      en: "The total amount of eligible dividends received from the connected corporation during the year. These dividends may qualify for lower tax rates.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  eligibleDividendReceived: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Dividende total versé par la société rattachée",
      en: "Total dividend paid by the connected corporation",
    },
    tooltip: {
      fr: "Le montant total des dividendes versés par la société rattachée au cours de l'année. Ces dividendes sont utilisés dans les calculs fiscaux.",
      en: "The total amount of dividends paid by the connected corporation during the year. These dividends are used in tax calculations.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  totalDividendPaid: number;
}