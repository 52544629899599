import { translationBaseKey} from "../../../decorators/translation-base-key.decorator";
import { CalculationRunOptions} from "../calculation-run.options";
import { CalculationKeynameEnum} from "../../../enums/calculations/calculation-keyname.enum";
import { advancedFormControl} from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum} from "../../../enums/field-type.enum";
import {
  IsBoolean,
  IsInt,
  IsNotEmpty,
  Max,
  Min, ValidateIf,
  ValidateNested,
} from '@pristine-ts/class-validator';
import { advancedFormArray} from "../../../decorators/advanced-form-array.decorator";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";
import { IncomeTaxPersonalOptions } from "../income-tax/personal/income-tax-personal.options";
import { CorporateIncomeTaxOptions } from "../income-tax/corporate/corporate-income-tax.options";
import { SalaryContributionsOptions } from "../salary-contributions/salary-contributions.options";
import {
  SalaryContributionsOptionsInSalaryDividendOptions
} from "./salary-contributions-options-in-salary-dividend.options";
import {advancedFormGroup} from "../../../decorators/advanced-form-group.decorator";
import {type} from "@pristine-ts/data-mapping-common";
import {
  QppPensionOptionsInSalaryDividendOptions
} from '../qpp-pension/qpp-pension-options-in-salary-dividend.options';

@translationBaseKey("salary-dividend-options")
export class SalaryDividendOptions extends CalculationRunOptions {

  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.SalaryDividend;

  @advancedFormControl({
    labelTitle: {
      fr: "Quelle est l'année?",
      en: "What is the year?"
    },
    selectKeyValues:[
      {
        key: {
          fr: "2023",
        },
        value: 2023
      },
      {
        key: {
          fr: "2024",
        },
        value: 2024
      }
    ],
    tooltip: {
      fr: "L'année fiscale pour laquelle les calculs sont effectués.",
      en: "The tax year for which the calculations are performed."
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @Min(2023, {buildErrorMessage: ErrorMessageBuilder.build})
  @Max(2024, {buildErrorMessage: ErrorMessageBuilder.build})
  year: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous maximiser le versement de dividendes déterminés?",
      en: "Do you want to maximize the payment of eligible dividends?"
    },
    tooltip: {
      fr: "Indique si vous souhaitez maximiser le versement de dividendes déterminés admissibles pour réduire l'impôt à payer.",
      en: "Indicates whether you want to maximize the payment of eligible dividends to reduce the tax payable."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isEligibleDividendMaximized: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous prendre en compte l'impôt de la partie IV dans l'analyse salaire-dividende?",
      en: "Do you want to take Part IV tax into account in the wage-dividend analysis?"
    },
    tooltip: {
      fr: "Indique si vous souhaitez prendre en compte l'impôt de la partie IV lors de l'analyse entre le salaire et les dividendes.",
      en: "Indicates whether you want to take Part IV tax into account during the analysis between salary and dividends."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isPartIVTaxUsed: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous prendre en compte l'augmentation de la pension RRQ dans le calcul?",
      en: "Do you want to take QPP pension increase into account in the wage-dividend analysis?"
    },
    tooltip: {
      fr: "Indique si vous souhaitez prendre en compte l'augmentation de la pension RRQ lors de l'analyse entre le salaire et les dividendes.",
      en: "Indicates whether you want to take QPP pension increase into account during the analysis between salary and dividends."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isQppPensionCalculated: boolean;

  @advancedFormControl({
  labelTitle: {
    fr: "Est-ce que le salaire de l'actionnaire est assujetti à l'assurance-emploi?",
    en: "Is the shareHolder salary subject to employment insurance?"
  },
  tooltip: {
    fr: "Indiquez si le salarié est assujetti à l'assurance-emploi. Cela peut avoir un impact sur les déductions fiscales.",
    en: "Specify whether the employee is subject to employment insurance. This may have implications for tax deductions.",
  },
  fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isShareHolderSubjectToEI: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Quel est le coût de la vie?",
      en: "What is the cost of living?"
    },
    tooltip: {
      fr: "",
      en: ""
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  costOfLiving: number;

  @type(() => new IncomeTaxPersonalOptions())
  @advancedFormGroup({
    classType: IncomeTaxPersonalOptions,
  })
  @ValidateNested()
  incomeTaxPersonalOptions: IncomeTaxPersonalOptions;

  @type(() => new SalaryContributionsOptionsInSalaryDividendOptions())
  @advancedFormGroup({
    classType: SalaryContributionsOptionsInSalaryDividendOptions,
  })
  @ValidateNested()
  salaryContributionsOptions: SalaryContributionsOptionsInSalaryDividendOptions;

  @type(() => new QppPensionOptionsInSalaryDividendOptions())
  @advancedFormGroup({
    classType: QppPensionOptionsInSalaryDividendOptions,
  })
  @ValidateNested()
  @ValidateIf(object => object.isQppPensionCalculated === true)
  qppPensionOptions?: QppPensionOptionsInSalaryDividendOptions;

  @type(() => new CorporateIncomeTaxOptions())
  @advancedFormGroup({
    classType: CorporateIncomeTaxOptions,
  })
  @ValidateNested()
  corporateIncomeTaxOptions: CorporateIncomeTaxOptions;
}
