import {LifecycleListenerInterface} from "../interfaces/lifecycle-listener.interface";
import {Injectable} from "@angular/core";
import {RampUpApiController} from "../api-controllers/ramp-up.api-controller";

@Injectable()
export class PlatformRampUpLifecycleListener implements LifecycleListenerInterface {
    constructor(private readonly rampUpApiController: RampUpApiController) {
    }

    async onFirstLoad(): Promise<void> {
        await this.rampUpApiController.rampUp();
    }
}
