import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {CardsContentResources} from "./cards.content-resources";
import {
    IndividualCalculationContentResourceInterface
} from "../interfaces/individual-calculation-content-resource.interface";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export const RemunerationSalaireOuDividendesContentResources: IndividualCalculationContentResourceInterface & ContentResourceInterface & { [id in string]: any } = {


    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "Rémunération: salaire ou dividendes - Optitax", "en": "Compensation: Salary vs Dividends - Optitax"}
    ),

    Meta: {
        Description: new TextContentModel(
            {"fr": "Optitax est un logiciel web qui offre des outils utiles et performants dans le  domaine de la fiscalité. Nous offrons des calculs variés, allant d’un calcul simple,  tel qu’un rachat d’actions, à des calculs complexes, tel que la détention personnelle ou  corporative d’un véhicule. La mission d’Optitax est de développer une gamme complète d’outils  fiscaux afin que les cabinets comptables ou autres professionnels n’aient pas à développer leurs propres  outils à l’interne et puissent se concentrer entièrement sur leur pratique.\n"}
        ),
        Keywords: new TextContentModel(
            {"fr": "comptable, accounting, fiscalité, optimiser votre fiscalité"}
        )
    },

    Hero: {
        Title: new TextContentModel(
            {"fr": "Rémunération: Salaire ou Dividendes?", "en": "Compensation: salary or dividends"}
        ),
        Subtitle: new TextContentModel(
            {"fr": "Outil fiscal conçu spécifiquement pour le Québec", "en": "Software built specifically for Québec"}
        ),
        Description: new MarkdownContentModel(
            {
                "fr": "Est-il plus avantageux de verser un salaire ou de verser des dividendes? Serait-il plus avantageux pour votre client de se verser un salaire suivi ensuite d'un dividendes? \nOptitax répondra de façon précise à ces questions!\n",
                "en": "Is it more advantageous to pay a salary or distribute dividends? Would it be more advantageous for your client to receive a salary followed by dividends?\nOptitax will provide a precise answer to these questions!\n"
            }
        ),
        CallToAction: new TextContentModel(
            {"fr": "Calculez la rémunération optimale maintenant!", "en": "Calculate the optimal compensation now!"}
        ),
        Image: new ImageContentModel(
            {
                url: new TextContentModel({
                    fr: "images/illustrations/dividends_vs_salary.svg",
                    en: "images/illustrations/dividends_vs_salary.svg",
                })
            }
        ),
    },

    MainFeature: {
        Title: new TextContentModel(
            {
                "fr": "Identifiez la rémunération optimale en 3 étapes!",
                "en": "Calculate the optimal compensation in 3 steps!"
            }
        ),
        Description: CardsContentResources.SalaryDividends.description!,
        Image: new ImageContentModel(
            {
                url: new TextContentModel({
                    fr: "images/illustrations/man-success.svg",
                    en: "images/illustrations/man-success.svg",
                }),
            }
        ),
        Steps: [
            {
                Title: new TextContentModel(
                    {
                        "fr": "Entrez les informations fiscales de votre client",
                        "en": "Enter the related tax information for your client"
                    }
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Notre calcul optimise selon la situation fiscale particulière de chacun de vos clients, c'est pourquoi nous avons besoin des informations fiscales.",
                        "en": "Our software tailors the calculation to your client's specific situation."
                    }
                ),
            },
            {
                Title: new TextContentModel(
                    {
                        "fr": "Spécifiez la situation spécifique de votre client",
                        "en": "Specify the specific situation of your client"
                    }
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Entrez le train de vie personnel de l'entrepreneur ainsi que les liquidités corporatives à décaisser. Ces informations permettront à Optitax d'identifier le scénario le plus avantageux fiscalement pour votre client.",
                        "en": "Enter the entrepreneur's personal cost of living as well as the corporate liquidity to be disbursed. This information will allow Optitax to identify the most tax advantageous scenario for your client."
                    }
                ),
            },
            {
                Title: new TextContentModel(
                    {
                        "fr": "Comparez tous les scénarios et présentez le scénario optimal à votre client!",
                        "en": "Compare all the scenarios and present the most advantageous one to your client!"
                    }
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Optitax vous donne une vue d'ensemble qui vous permet de comparer tous les scénarios et d'identifier le scénario le plus alléchant fiscalement pour votre client.",
                        "en": "Optitax provides you at a glance with all the possible scenarios and their unique tax consequences. Choose the most interesting one for your client!"
                    }
                ),
            }
        ]
    },

    Features: [
        {
            Title: new TextContentModel(
                {"fr": "Des outils fiscaux adaptés au Québec", "en": "Tool specifically built for Québec"}
            ),
            Description: new TextContentModel(
                {
                    "fr": "Optitax est une firme québécoise qui développe des outils fiscaux pour les cabinets comptables québécois",
                    "en": "Optitax is a Québec-based technologies comppany that develops a powerful software that does advanced tax calculations for accounting firms."
                }
            ),
            CallToAction: new TextContentModel(
                {"fr": "Effectuez ce calcul maintenant", "en": "Try this tool now."}
            ),
            Image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/man-phone.svg",
                        en: "images/illustrations/man-phone.svg",
                    })
                }
            ),
        },
        {
            Title: new TextContentModel(
                {"fr": "Notre solution est accessible sur tous vos appareils", "en": "Available on all your devices"}
            ),
            Description: new TextContentModel(
                {
                    "fr": "Optitax est disponible sur tous vos appareils: Windows, Mac OS, iPhone, Android et iPad!",
                    "en": "Optitax is available on all your devices: Windows, Mac OS, iPhone, Android et iPad!"
                }
            ),
            CallToAction: new TextContentModel(
                {"fr": "Effectuez ce calcul maintenant", "en": "Try this tool now"}
            ),
            Image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/devices.svg",
                        en: "images/illustrations/devices.svg",
                    }),
                }
            ),
        }
    ],
}
