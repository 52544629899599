import {translationBaseKey} from "../../../../decorators/translation-base-key.decorator";
import {CalculationRunOptions} from "../../calculation-run.options";
import {advancedFormControl} from "../../../../decorators/advanced-form-control.decorator";
import {CalculationKeynameEnum} from "../../../../enums/calculations/calculation-keyname.enum";
import {advancedFormArray} from "../../../../decorators/advanced-form-array.decorator";
import {CorporateIncomeTaxQuebecOptions} from "./quebec/corporate-income-tax-quebec.options";
import {
  IsDate,
  IsEnum,
  IsInt,
  IsNotEmpty, IsNumber,
  IsOptional,
  IsString, Max, Min,
  validateIf,
  ValidateNested,
} from '@pristine-ts/class-validator';
import {CorporateIncomeTaxFederalOptions} from "./federal/corporate-income-tax-federal.options";
import {FieldTypeEnum} from "../../../../enums/field-type.enum";
import {
  CorporateTaxFederalConnectedCorporationOptions
} from "./federal/corporate-tax-federal-connected-corporation.options";
import {ErrorMessageBuilder} from "../../../../builders/error-message.builder";
import {CompanyTypeEnum} from "../../../../enums/company-type.enum";
import {advancedFormGroup} from "../../../../decorators/advanced-form-group.decorator";
import {array, type} from "@pristine-ts/data-mapping-common";
import {ValidationGroupEnum} from "../../../../enums/validation-group.enum";
import {CalculationDate} from "../../../../models/calculation.date";
import {Type} from "class-transformer";

@translationBaseKey("income-tax-corporation-options")
export class CorporateIncomeTaxOptions extends CalculationRunOptions {

  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.CorporateTax;

  @advancedFormGroup({
    classType: CorporateIncomeTaxQuebecOptions,
  })
  @ValidateNested()
  @IsOptional()
  incomeTaxCorporationQuebec?: CorporateIncomeTaxQuebecOptions;

  @advancedFormGroup({
    classType: CorporateIncomeTaxFederalOptions,
  })
  @ValidateNested()
  @IsOptional()
  incomeTaxCorporationFederal?: CorporateIncomeTaxFederalOptions;

  @advancedFormControl({
    labelTitle: {
      fr: "Année fiscale",
      en: "Fiscal year",
    },
    tooltip: {
      fr: "L'année fiscale de la société pour laquelle le calcul de l'impôt est effectué.",
      en: "The fiscal year of the company for which the tax calculation is performed.",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({groups: [ValidationGroupEnum.SalaryDividend]})
  fiscalYear: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Date de début de l'exercice",
      en: "Start date of the fiscal year",
    },
    tooltip: {
      fr: "La date de début de l'exercice financier de la société.",
      en: "The start date of the company's fiscal year.",
    },
    fieldType: FieldTypeEnum.CalculationDate,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateNested()
  @type(() => new CalculationDate())
  fiscalYearStart: CalculationDate;
  //date_debut_exercice

  @advancedFormControl({
    labelTitle: {
      fr: "Date de fin de l'exercice",
      en: "Fiscal year end date",
    },
    tooltip: {
      fr: "La date de fin de l'exercice financier de la société.",
      en: "The end date of the company's fiscal year.",
    },
    fieldType: FieldTypeEnum.CalculationDate,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateNested()
  @type(() => new CalculationDate())
  fiscalYearEnd: CalculationDate;
  //date_fin_exercice

  @advancedFormControl({
    labelTitle: {
      fr: "Type de société",
      en: "Type of company",
    },
    tooltip: {
      fr: "Le type de société (par exemple: société privée sous contrôle canadien toute l'année, société publique, société privée).",
      en: "The type of company (e.g., Canadian-controlled private corporation all year long, public company, private company).",
    },
    selectKeyValues: [
      {
        key: {
          fr: "Société privée sous contrôle canadien toute l'année",
          en: "Canadian-controlled private corporation all year long",
        },
        value: CompanyTypeEnum.ccpcAllYear,
        //sPCC_annee_complete
      },
      {
        key: {
          fr: "Société publique",
          en: "Public Company",
        },
        value: CompanyTypeEnum.publicCompany,
        //societe_publique
      },
      {
        key: {
          fr: "Société privée",
          en: "Private Company",
        },
        value: CompanyTypeEnum.privateCompany,
        //societe_privee
      },
    ],
    fieldType: FieldTypeEnum.String,
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsEnum(CompanyTypeEnum, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  companyType: CompanyTypeEnum;

  @advancedFormControl({
    labelTitle: {
      fr: "Dans le cas ou le salaire simulé engendre une perte pour la société, quel est le taux d'imposition de la société quant au report de cette perte?",
      en: "If the simulated salary causes a loss for the corporation, what it the tax rate for the loss.",
    },
    tooltip: {
      fr: "Le logiciel a besoin du taux d’imposition lors d’un report de perte lorsque le salaire simulé par le logiciel plus les cotisations de l’employeur équivalente engendre une perte dans l’exercice qui est reporté à un autre exercice. Le logiciel pourra calculé l’économie d’impôt relatif au report de perte.",
      en: "The software needs the tax rate when carrying forward a loss when the salary simulated by the software plus the equivalent employer contributions results in a loss in the fiscal year that is carried forward to another fiscal year. The software will be able to calculate the tax savings relating to loss carryover.",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsNumber(undefined,{buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @Min(0, {buildErrorMessage: ErrorMessageBuilder.build})
  @Max(100, {buildErrorMessage: ErrorMessageBuilder.build})
  lossTaxRate: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Capital imposable des sociétés associées de l'année actuelle ou l'année précédente, selon le cas (voir 125(5.1))",
      en: "Taxable capital of associated corporations in the current year or the previous year, as the case may be (see 125(5.1))",
    },
    tooltip: {
      fr: "Le montant du capital imposable détenu par les sociétés associées de la société pour l'année en cours ou l'année précédente, selon le cas. Ce montant est utilisé dans le calcul de certains crédits d'impôt.",
      en: "The amount of taxable capital held by the associated corporations of the company for the current year or previous year, as the case may be. This amount is used in the calculation of certain tax credits.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: CorporateIncomeTaxOptions, root, currentPath) => object.companyType !== CompanyTypeEnum.ccpcAllYear)
  totalTaxableCapital: number;
  //capital_imposable_total

  @advancedFormControl({
    labelTitle: {
      fr: "Revenus d'entreprises",
      en: "Business revenues",
    },
    tooltip: {
      fr: "Les revenus totaux générés par l'entreprise pour l'année fiscale.",
      en: "The total revenues generated by the business for the fiscal year.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  businessIncome: number;
  //rEEA

  @advancedFormControl({
    labelTitle: {
      fr: "Revenu de placement total ajusté de la société pour l'année précédente",
      en: "Corporation's total adjusted investment income for the previous year",
    },
    tooltip: {
      fr: "Le montant du revenu de placement total ajusté de la société pour l'année fiscale précédente.",
      en: "The amount of the corporation's total adjusted investment income for the previous fiscal year.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: CorporateIncomeTaxOptions, root, currentPath) => object.companyType !== CompanyTypeEnum.ccpcAllYear)
  adjustedInvestmentIncome: number;
  //revenu_placement_total_ajuste_precedent

  @advancedFormControl({
    labelTitle: {
      fr: "Dons",
      en: "Donations",
    },
    tooltip: {
      fr: "Le montant total des dons effectués par la société pour l'année fiscale.",
      en: "The total amount of donations made by the corporation for the fiscal year.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  donations: number = 0;
  //dons

  // @todo: Eventually, this should be specific in federal and provincial. See discussion on January 9th, 2024
  @advancedFormControl({
    labelTitle: {
      fr: "Solde du report des pertes en capital à la fin de l’année d’imposition précédente",
      en: "Capital loss carryforward balance",
    },
    tooltip: {
      fr: "Le montant total du solde du report des pertes en capital pour l'année fiscale. Le solde appliqué sera utilisé autant au fédéral qu'au provincial.",
      en: "The total amount of the capital loss carryforward balance for the fiscal year. The balance will be applied both for federal and provincial.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  capitalLossBalance: number;
  //report_Pc

  // @todo: Eventually, this should be specific in federal and provincial. See discussion on January 9th, 2024
  @advancedFormControl({
    labelTitle: {
      fr: "Solde du report des pertes autres qu’en capital à la fin de l’année d’imposition précédente",
      en: "Non-capital loss carryforward balance",
    },
    tooltip: {
      fr: "Le montant total du solde du report des pertes autres qu'en capital pour l'année fiscale. Le solde appliqué sera utilisé autant au fédéral qu'au provincial.",
      en: "The total amount of the non-capital loss carryforward balance for the fiscal year. The balance will be applied both for federal and provincial.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  nonCapitalLossBalance: number;
  //report_PAQC

  @advancedFormControl({
    labelTitle: {
      fr: "Plafond des affaires de la société",
      en: "Company's business limit",
    },
    tooltip: {
      fr: "Le plafond des affaires de la société pour l'année fiscale.",
      en: "The company's business limit for the fiscal year.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: CorporateIncomeTaxOptions, root, currentPath) => object.companyType === CompanyTypeEnum.ccpcAllYear)
  assignedBusinessLimit?: number;
  //plafondAttribue125_3_2

  @array(CorporateTaxFederalConnectedCorporationOptions)
  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 1,
    elementClassType: CorporateTaxFederalConnectedCorporationOptions,
  })
  @ValidateNested()
  connectedCorporations: CorporateTaxFederalConnectedCorporationOptions[] = [];
}
