<app-header>
    <div class="row hero">
        <div class="col-12">
            <h1 class="text-center text-white display-2">{{ SecurityContentResources["security.body.title"] | render | async }}</h1>

            <div class="hero-image d-flex justify-content-center">
                <app-image style="height: 300px;" [imageContentModel]="SecurityContentResources['security.body.image']"
                           [fetchPriority]="FetchPriorityEnum.High" [loading]="LoadingEnum.Eager"
                           [imageClass]="'img-fluid'"></app-image>
            </div>
        </div>
    </div>
</app-header>
<div class="container">

    <div class="row">
        <div class="col-12">
            <div class="mt-5" [innerHTML]="SecurityContentResources['security.body.subtitle'] | render | async "></div>
        </div>
    </div>

    <div class="features">
        @for (feature of SecurityContentResources.Features; track $index) {
            <div class="row mt-5">
                <div class="col-12 col-lg-6" [class.order-0]="$index % 2 === 0" [class.order-1]="$index % 2 !== 0">
                    <h2>{{ feature.title | render | async }}</h2>

                    <div [innerHTML]="feature.description | render | async"></div>
                </div>
                <div class="col-12 col-lg-6 d-flex justify-content-center" [class.order-1]="$index % 2 === 0"
                     [class.order-0]="$index % 2 !== 0">
                    <app-image [imageContentModel]="feature.image" [fetchPriority]="FetchPriorityEnum.High"
                               [loading]="LoadingEnum.Eager" [imageClass]="'img-fluid'"></app-image>
                </div>
            </div>
        }
    </div>

</div>

