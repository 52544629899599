import {BehaviorSubject, Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class EventManager {
    public readonly eventStream = new Subject<string>();

    publishEvent(event: string) {
        this.eventStream.next(event);
    }
}