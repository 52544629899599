import {Component} from '@angular/core';
import {StudentsContentResources} from "../../content/resources/students.content-resources";
import {LoadingEnum} from "../../enums/loading.enum";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {RouteManager} from "../../managers/route.manager";
import {RouteEnum} from "../../enums/route.enum";
import {BaseComponent} from "../../components/base.component";
import {LocaleManager} from "../../managers/locale.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-students',
    templateUrl: './students.component.html',
    styleUrl: './students.component.scss'
})
export class StudentsComponent extends BaseComponent {

    constructor(
        localeManager: LocaleManager,
        routeManager: RouteManager,
        contentManager: ContentManager,
        titleService: Title,
        meta: Meta,
    ) {
        super(localeManager,
            routeManager,
            contentManager,
            titleService,
            meta,
            StudentsContentResources
        )
    }

    protected readonly StudentsContentResources = StudentsContentResources;
    protected readonly LoadingEnum = LoadingEnum;
    protected readonly FetchPriorityEnum = FetchPriorityEnum;

    protected readonly RouteEnum = RouteEnum;
}
