import {Injectable} from "@angular/core";
import {OptitaxFileModel} from "../../../../../../../common/src/models/optitax-file.model";
import {CalculationResponse} from "../../../../../../../common/src/responses/calculation.response";
import {DataMapper} from "@pristine-ts/data-mapping-common";
import {LoggingService} from "../services/logging.service";

@Injectable()
export class OptitaxFileManager {
    constructor(
        private readonly dataMapper: DataMapper,
        private readonly loggingService: LoggingService
    ) {
    }

    async importOptitaxFile(): Promise<OptitaxFileModel> {
        // Show File Picker
        if ("showOpenFilePicker" in window) {
            const pickerOpts = {
                types: [
                    {
                        description: "Optitax Files",
                        accept: {
                            "optitax/*": [".optitax"],
                        },
                    },
                ],
                excludeAcceptAllOption: true,
                multiple: false,
            };

            // @ts-ignore
            const [fileHandle] = await window.showOpenFilePicker(pickerOpts);

            // Transform file in JSON
            try {
                const file = await fileHandle.getFile();
                const json = JSON.parse(await file.text());
                if (!json || json["_type"] !== "__optitax_file__") {
                    throw new Error("The file is not a valid Optitax file.");
                }

                return await this.dataMapper.autoMap(json, OptitaxFileModel, {
                    logErrors: true,
                    isOptionalDefaultValue: true,
                    excludeExtraneousValues: true,
                });
            } catch (e) {
                this.loggingService.error("The file is not a valid Optitax file.", e);
                throw new Error("The file is not a valid Optitax file.");
            }

        }

        this.loggingService.error("The file picker is not supported by the browser.");
        throw new Error("The file picker is not supported by the browser.");
    }

    exportOptitaxFile(calculationResponse: CalculationResponse) {
        const optitaxFileModel = new OptitaxFileModel();
        optitaxFileModel.version = "0.1";
        optitaxFileModel.title = calculationResponse.title;
        optitaxFileModel.calculationKeyname = calculationResponse.calculationKeyname;
        if(calculationResponse.options) {
            optitaxFileModel.options = calculationResponse.options.options;
        }
        optitaxFileModel.createdAt = calculationResponse.createdAt;
        optitaxFileModel.updatedAt = calculationResponse.updatedAt;

        return optitaxFileModel
    }
}
