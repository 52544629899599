import {AfterViewInit, Component} from '@angular/core';
import {GetStartedContentResources} from "../../content/resources/get-started.content-resources";
import {LocaleManager} from "../../managers/locale.manager";
import {LocaleEnum} from "../../enums/locale.enum";
import {BaseComponent} from "../../components/base.component";
import {RouteManager} from "../../managers/route.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrl: './get-started.component.scss'
})
export class GetStartedComponent extends BaseComponent implements AfterViewInit {

    constructor(
        localeManager: LocaleManager,
        routeManager: RouteManager,
        contentManager: ContentManager,
        titleService: Title,
        meta: Meta,
) {
        super(localeManager,
            routeManager,
            contentManager,
            titleService,
            meta,
            GetStartedContentResources,
        )
    }

    ngAfterViewInit(): void {
        this.loadHubspotForm();
    }

    loadHubspotForm() {
        const locale = this.localeManager.getCurrentLocale()

        // @ts-ignore
        if(typeof hbspt === 'undefined') {
            return;
        }

        switch (locale) {
            case LocaleEnum.French:
                // @ts-ignore
                hbspt.forms.create({
                    region: "na1",
                    portalId: "20373920",
                    formId: "1b8265b7-3445-423d-b8e1-34edb0bb4ffd",
                    target: "#get_started_hubspot_form"
                });
                break;
            case LocaleEnum.English:
                // @ts-ignore
                hbspt.forms.create({
                    region: "na1",
                    portalId: "20373920",
                    formId: "1b8265b7-3445-423d-b8e1-34edb0bb4ffd",
                    target: "#get_started_hubspot_form"
                });
                break;
        }
    }

    protected readonly GetStartedContentResources = GetStartedContentResources;
}
