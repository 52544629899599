import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export const GetStartedContentResources: ContentResourceInterface & { [id in string]: any } = {


    LastModified: new TextContentModel(
        {"fr": "2024-11-26", "en": "2024-11-26"}
    ),

    Title: new TextContentModel(
        {"fr": "Débutez gratuitement maintenant", "en": "Get started now"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Débutez gratuitement dès maintenant à utiliser Optitax, sans carte de crédit!\n",
                "en": "Start using Optitax now for free, no credit card required.\n"
            }
        ),

        Keywords: new TextContentModel(
            {
                "fr": "optitax, débutez maintenant gratuitement, sans carte de crédit, essai gratuit, 14 jours",
                "en": "optitax, get started, free trial, 14 days, no credit card required"
            }
        ),
    },
    
    "get-started.body.access-your-account": new TextContentModel(
        {
            "fr": "Accédez à votre compte",
            en: "Access your account",
        }
    ),

    "get-started.body.call-to-action": new TextContentModel(
        {
            "fr": "Je suis déjà client",
            en: "Already a customer"
        }
    ),

    "get-started.body.title": new TextContentModel(
        {
            "fr": "Merci de votre intérêt pour Optitax",
            en: "Thank you for your interest in Optitax"
        }
    ),

    "get-started.body.subtitle": new TextContentModel(
        {
            "fr": "Afin de débuter le processus d’activation, vous devez commencer par remplir le formulaire suivant. Vous serez ensuite inviter à vous créer un compte dans Optitax.",
            en: "To start the activation process, you must first fill out the following form. You will then be invited to create an account in Optitax."
        }
    ),
}
