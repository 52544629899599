import {TextContentModel} from "./text.content-model";
import {BaseContentModel} from "./base.content-model";
import {ContentTypeEnum} from "../enums/content-type.enum";

export class ImageContentModel extends BaseContentModel {
    url: TextContentModel;

    alt?: TextContentModel;

    constructor(options: Partial<ImageContentModel>) {
        super(ContentTypeEnum.Image);

        if (!options.url) {
            throw new Error("The 'url' options is required for an ImageContentModel.");
        }

        this.url = options.url;
        this.alt = options.alt;
    }
}
