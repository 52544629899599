import {IsNotEmpty, IsInt, IsString, ValidateIf} from "@pristine-ts/class-validator";
import {advancedFormControl} from "../../../decorators/advanced-form-control.decorator";
import {translationBaseKey} from "../../../decorators/translation-base-key.decorator";
import {FieldTypeEnum} from "../../../enums/field-type.enum";
import { v4 as uuid } from 'uuid';
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("section-84_1-share-class-options")
export class Section84_1ShareClassOptions {
    @advancedFormControl({
        fieldType: FieldTypeEnum.String,
    })
    @IsString({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    id: string = uuid();

    @advancedFormControl({
        labelTitle: {
            fr: "Capital versé fiscal de la catégorie d'actions émises avant l'émission des nouvelles actions",
            en: "Tax paid-up capital of the particular class of shares issued before the new shares are issued",
        },
        tooltip: {
            fr: "Montant du capital versé fiscal de la catégorie d'actions émises avant l'émission des nouvelles actions.",
            en: "Amount of tax paid-up capital of the particular class of shares issued before the new shares are issued.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    initialPaidUpCapital: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Nombre d'actions de la catégorie émis en contrepartie",
            en: "Number of shares of the class issued for consideration",
        },
        tooltip: {
            fr: "Nombre total d'actions de la catégorie émises en contrepartie de la transaction.",
            en: "Total number of shares of the class issued as consideration for the transaction.",
        },
        fieldType: FieldTypeEnum.Number,
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    numberOfSharesIssued: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Nombre d'actions de la catégorie d'actions concernées de l'acheteur avant l'émission des actions",
            en: "Number of shares of the relevant class of shares of the purchaser before the issuance of the shares",
        },
        tooltip: {
            fr: "Nombre d'actions de la catégorie d'actions concernées détenues par l'acheteur avant l'émission des nouvelles actions.",
            en: "Number of shares of the relevant class of shares held by the purchaser before the issuance of the new shares.",
        },
        fieldType: FieldTypeEnum.Number,
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    numberOfSharesBeforeShareIssuance: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Augmentation du capital versé légal au titre de la catégorie d'actions de l'acheteur due à l'émission des nouvelles actions",
            en: "Increase in legal paid-up capital for the given class of shares of the purchaser due to the issuance of the new shares",
        },
        tooltip: {
            fr: "Montant de l'augmentation du capital versé légal de la catégorie d'actions de l'acheteur suite à l'émission des nouvelles actions.",
            en: "Amount of the increase in legal paid-up capital for the given class of shares of the purchaser due to the issuance of the new shares.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    increasePaidUpCapitalDueToShareIssuance: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Prix de base rajusté de la catégorie d'actions émises avant l'émission des nouvelles actions",
            en: "Adjusted cost base of the particular class of shares issued before the new shares are issued",
        },
        tooltip: {
            fr: "Montant du prix de base rajusté de la catégorie d'actions émises avant l'émission des nouvelles actions.",
            en: "Amount of the adjusted cost base of the particular class of shares issued before the new shares are issued.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    initialAdjustedCostBase: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Juste valeur marchande des actions émises en contrepartie",
            en: "Fair market value of the shares issued as consideration",
        },
        tooltip: {
            fr: "Montant de la juste valeur marchande des actions émises en contrepartie de la transaction.",
            en: "Amount of the fair market value of the shares issued as consideration for the transaction.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    fairMarketValueOfSharesIssued: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Description de la catégorie d'actions",
            en: "Description of the share class",
        },
        tooltip: {
            fr: "Description de la catégorie d'actions émises.",
            en: "Description of the particular class of shares issued.",
        },
    })
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsString({buildErrorMessage: ErrorMessageBuilder.build})
    title: string;
}

