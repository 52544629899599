import {Injectable} from "@angular/core";
import {TextContentModel} from "../models/text.content-model";
import {LocaleManager} from "../../managers/locale.manager";
import {LocaleEnum} from "../../enums/locale.enum";
import {HtmlContentModel} from "../models/html.content-model";

@Injectable()
export class HtmlRenderer {

    constructor(
        private readonly localeManager: LocaleManager) {
    }

    async render(contentModel: HtmlContentModel): Promise<string> {
        switch (this.localeManager.getCurrentLocale()) {
            case LocaleEnum.French:
                return contentModel.fr;
            case LocaleEnum.English:
                return contentModel.en;
        }
    }
}
