import {AdvancedFormControlDecoratorOptionsInterface} from "./advanced-form-control-decorator-options.interface";

export const advancedFormControl = (formControl: AdvancedFormControlDecoratorOptionsInterface) => {
    return (
        target: Object,
        propertyKey: string | symbol,
    ) => {
        if(target.constructor.prototype.hasOwnProperty("__metadata__") === false) {
            target.constructor.prototype["__metadata__"] = {}
        }

        if(target.constructor.prototype["__metadata__"].hasOwnProperty("advancedForms") === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"] = {}
        }

        if(target.constructor.prototype["__metadata__"]["advancedForms"].hasOwnProperty("properties") === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"]["properties"] = {}
        }

        if(target.constructor.prototype["__metadata__"]["advancedForms"]["properties"].hasOwnProperty(propertyKey) === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey] = {}
        }

        if(target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey].hasOwnProperty("advancedFormControl") === false) {
            target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey]["advancedFormControl"] = {};
        }

        target.constructor.prototype["__metadata__"]["advancedForms"]["properties"][propertyKey]["advancedFormControl"] = formControl;
    }
}