import {Injectable} from "@angular/core";
import {TextContentModel} from "../models/text.content-model";
import {LocaleManager} from "../../managers/locale.manager";
import {LocaleEnum} from "../../enums/locale.enum";
import {Marked} from "marked";
import {MarkdownContentModel} from "../models/markdown.content-model";

@Injectable()
export class MarkdownRenderer {

    constructor(
        private readonly localeManager: LocaleManager) {
    }

    async render(contentModel: MarkdownContentModel): Promise<string> {
        const markdownService = new Marked();

        let content = "";
        switch (this.localeManager.getCurrentLocale()) {
            case LocaleEnum.French:
                content = await markdownService.parse(contentModel.fr);
                break;
            case LocaleEnum.English:
                content = await markdownService.parse(contentModel.en);
                break;
        }

        return content;
    }
}
