import {Inject, Injectable} from "@angular/core";
import {LogModel} from "../models/log.model";
import {SeverityTypeEnum} from "../enums/severity-type.enum";

@Injectable()
export class LoggingService {
    constructor(@Inject("environment.logging.severityType") private readonly severityType: SeverityTypeEnum) {
    }

    success(message: string, extra?: any) {
        this.log(new LogModel(SeverityTypeEnum.Success, message, extra))
    }
    warning(message: string, extra?: any) {
        this.log(new LogModel(SeverityTypeEnum.Warning, message, extra))
    }
    info(message: string, extra?: any) {
        this.log(new LogModel(SeverityTypeEnum.Info, message, extra))
    }
    debug(message: string, extra?: any) {
        this.log(new LogModel(SeverityTypeEnum.Debug, message, extra))
    }
    error(message: string, extra?: any) {
        this.log(new LogModel(SeverityTypeEnum.Error, message, extra))
    }

    log(log: LogModel) {
        this.output(log);
    }

    private output(log: LogModel) {

        if(log.severityTypeEnum < this.severityType) {
            // Don't log if the severity is less than the configured severity
            return;
        }

        switch (log.severityTypeEnum) {
            case SeverityTypeEnum.Debug:
                // Todo: debug does not log in the browser
                console.log(log.message, log.extra);
                break;
            case SeverityTypeEnum.Success:
                console.log(log.message, log.extra, 'color:Green');
                break;
            case SeverityTypeEnum.Info:
                console.log(log.message, log.extra);
                break;
            case SeverityTypeEnum.Warning:
                console.warn(log.message, log.extra);
                break;
            case SeverityTypeEnum.Error:
            case SeverityTypeEnum.Critical:
                console.error(log.message, log.extra);
        }
    }
}