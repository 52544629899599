import {
  IsBoolean,
  IsNotEmpty,
  IsInt,
  IsString,
  ValidateNested,
  IsOptional,
  ValidateIf, IsDate
} from "@pristine-ts/class-validator";
import { v4 as uuid } from 'uuid';
import { IncomeTaxPersonalChildCampOptions} from "./income-tax-personal-child-camp.options";
import { advancedFormControl } from "../../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../../enums/field-type.enum";
import { translationBaseKey} from "../../../../decorators/translation-base-key.decorator";
import { advancedFormArray } from "../../../../decorators/advanced-form-array.decorator";
import {ErrorMessageBuilder} from "../../../../builders/error-message.builder";
import { IncomeTaxPersonalChildFederalOptions } from "./federal/income-tax-personal-child-federal.options";
import { IncomeTaxPersonalChildQuebecOptions } from "./quebec/income-tax-personal-child-quebec.options";
import {advancedFormGroup} from "../../../../decorators/advanced-form-group.decorator";
import {type} from "@pristine-ts/data-mapping-common"
import {array} from "@pristine-ts/data-mapping-common";
import {CalculationDate} from "../../../../models/calculation.date";
import {Type} from "class-transformer";

@translationBaseKey("income-tax-child-options")
export class IncomeTaxPersonalChildOptions {
  @advancedFormControl({
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "Prénom",
      en: "First name"
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  firstName: string;

  @advancedFormControl({
    labelTitle: {
      fr: "Nom de famille",
      en: "Last name"
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  lastName: string;

  @array(IncomeTaxPersonalChildCampOptions)
  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 0,
    elementClassType: IncomeTaxPersonalChildCampOptions,
  })
  @ValidateNested()
  camps: IncomeTaxPersonalChildCampOptions[] = [];

  @advancedFormControl({
    labelTitle: {
      fr: "Date de naissance de votre enfant",
      en: "Child Birthdate"
    },
    tooltip: {
      fr: "Indiquez la date de naissance de votre enfant. La date de naissance est utilisée pour déterminer l'admissibilité à certains crédits d'impôt.",
      en: "Specify your child's birthdate. The birthdate is used to determine eligibility for certain tax credits.",
    },
    fieldType: FieldTypeEnum.CalculationDate
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateNested()
  @type(() => new CalculationDate())
  birthdate: CalculationDate;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que votre enfant est handicapé?",
      en: "Is your child disabled?"
    },
    tooltip: {
      fr: "Indiquez si votre enfant est handicapé. Le statut d'invalidité de l'enfant peut avoir des conséquences fiscales spécifiques.",
      en: "Specify if your child is disabled. The child's disability status may have specific tax implications.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isDisabled: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Revenu net de votre enfant?",
      en: "Child's net income?"
    },
    tooltip: {
      fr: "Indiquez le revenu net de votre enfant. Le revenu net peut être utilisé pour déterminer l'admissibilité à certains crédits d'impôt.",
      en: "Specify your child's net income. The net income may be used to determine eligibility for certain tax credits.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  netIncome: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que votre enfant est aux études?",
      en: "Is your child studying?"
    },
    tooltip: {
      fr: "Indiquez si votre enfant est aux études. Le statut d'étudiant peut avoir des répercussions sur certaines déductions fiscales.",
      en: "Specify if your child is studying. The student status may have implications for certain tax deductions.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional()
  isStudying?: boolean = false;

  @type(() => new IncomeTaxPersonalChildFederalOptions())
  @advancedFormGroup({
    classType: IncomeTaxPersonalChildFederalOptions
  })
  @ValidateNested()
  @IsOptional()

  // todo: we know currentPath's last part will be this class. Remove our part and get our parent from the path.
  @ValidateIf((object: any, root: any, currentPath: string): boolean => {

    /*
    The code below is a replacement for this since we don't know for sure if the instanceof will work.
    if(root instanceof IncomeTaxPersonalOptions) {
      return root.isCareExpensesCalculated;
    } else if(root instanceof SalaryDividendOptions) {
      return root.incomeTaxPersonalOptions.isCareExpensesCalculated;
    }
     */

    if(root.hasOwnProperty("isCareExpensesCalculated")) {
      return root["isCareExpensesCalculated"];
    } else if(root.hasOwnProperty("incomeTaxPersonalOptions") && root["incomeTaxPersonalOptions"].hasOwnProperty("isCareExpensesCalculated")) {
      return root["incomeTaxPersonalOptions"]["isCareExpensesCalculated"];
    }

    return false;
  })
  federal?: IncomeTaxPersonalChildFederalOptions;

  @type(() => new IncomeTaxPersonalChildQuebecOptions())
  @advancedFormGroup({
    classType: IncomeTaxPersonalChildQuebecOptions
  })
  @ValidateNested()
  @IsOptional()
  quebec?: IncomeTaxPersonalChildQuebecOptions;
}
