import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export type PricingBoxFeature = {
    title: TextContentModel;
}

export type PricingBox = {
    price: TextContentModel;
    unit: TextContentModel;
    users: TextContentModel;
    subtitle: TextContentModel;
    callToAction: TextContentModel;
    subtitle2: TextContentModel;

    features: PricingBoxFeature[];
}

export type PricingTable = {
    title: TextContentModel;
    singleUser: {
        title: TextContentModel;
        monthlyPricing: TextContentModel;
    };
    users25: {
        title: TextContentModel;
        monthlyPricing: TextContentModel;
        popular: TextContentModel;
    };
    enterprise: {
        title: TextContentModel;
    };
    categories: {
        accessCalculations: TextContentModel;
        salaryVsDividends: {
            title: TextContentModel;
            popup: TextContentModel;
        };
        carOwnership: {
            title: TextContentModel;
            popup: TextContentModel;
        };
        rollover: {
            title: TextContentModel;
            popup: TextContentModel;
        };
        sharesSelling: {
            title: TextContentModel;
            popup: TextContentModel;
        };
        clientPortal: {
            title: TextContentModel;
            access: TextContentModel;
            questionnaire: {
                title: TextContentModel;
                tax2022: TextContentModel;
                number: TextContentModel;
                numberOfClients: TextContentModel;
            };
        };
        securedHosting: TextContentModel;
        customColors: TextContentModel;
        dedicatedCloudHosting: TextContentModel;
        internalToolsIntegration: TextContentModel;
        chat: TextContentModel;
    };
}

export const PricingContentResources: {
    PricingBoxes: PricingBox[],
    PricingTable: PricingTable,
    PricingBodyFaqQuestions: { title: TextContentModel, description: TextContentModel, }[],
} & ContentResourceInterface & { [id in string]: any } = {


    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "Nos tarifs", "en": "Pricing"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Optitax offre trois forfaits adaptés: Individuel, 25 utilisateurs et Entreprise. Optitax offre aussi un  forfait gratuit pour les étudiants.\n",
                "en": "Optitax offers three tailored packages: Individual, 25 users, and Enterprise. Optitax also provides a free package for students.\n"
            }
        ),

        Keywords: new TextContentModel(
            {
                "fr": "Optitax,forfaits adaptés,Forfait Individuel,Forfait 25 utilisateurs,Forfait Entreprise,forfait gratuit pour les étudiants,logiciel fiscal,solutions fiscales,planification fiscale,gestion fiscale,services fiscaux pour les étudiants,forfaits logiciels fiscaux,solutions fiscales personnalisées,forfaits fiscaux abordables,logiciel fiscal pour les particuliers",
                "en": "Optitax,tailored packages,Individual package,25 users package,Enterprise package,free package for students,tax software,tax solutions,tax planning,tax management,student tax services,tax software packages,personalized tax solutions,affordable tax packages,tax software for individuals"
            }
        ),
    },
    
    "pricing.body.hero.title": new TextContentModel(
        {"fr": "Choisissez le forfait qui convient à vos besoins!", "en": "Choose the plan that fits your needs!"}
    ),

    PricingBoxes: [
        {
            price: new TextContentModel(
                {"fr": "89$", "en": "$89"}
            ),
            unit: new TextContentModel(
                {"fr": "mois", "en": "month"}
            ),
            users: new TextContentModel(
                {"fr": "1 utilisateur", "en": "1 user"}
            ),
            subtitle: new TextContentModel(
                {"fr": "Pour les comptables indépendants", "en": "Self-employed accountant"}
            ),
            features: [
                {
                    title: new TextContentModel(
                        {"fr": "1 utilisateur", "en": "1 user"}
                    )
                },
                {
                    title:  new TextContentModel(
                        {"fr": "Accès à tous les calculs", "en": "Access to all the tools"}
                    )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Accès au portail client", "en": "Access to client portal"}
                    )
                },
                {
                    title: new TextContentModel(
                        {
                            "fr": "Accès aux questionnaires de récolte d'information client",
                            "en": "Client questionnaires to gather tax information"
                        }
                    )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Support par clavardage", "en": "Online chat support"}
                    )
                }
            ],
            callToAction: new TextContentModel(
                {"fr": "Essai gratuit durant 14 jours", "en": "Free 14-day trial"}
            ),
            subtitle2: new TextContentModel(
                {"fr": "Aucune carte de crédit requise", "en": "No credit card needed"}
            ),
        },
        {
            price: new TextContentModel(
                {"fr": "199$", "en": "$199"}
            ),
            unit: new TextContentModel(
                {"fr": "mois", "en": "month"}
            ),
            users: new TextContentModel(
                {"fr": "25 utilisateurs", "en": "25 users"}
            ),
            subtitle: new TextContentModel(
                {"fr": "Pour les cabinets comptables", "en": "Accounting firms"}
            ),
            features: [
                {
                    title: new TextContentModel(
                        {"fr": "25 utilisateurs", "en": "25 users"}
                    )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Accès à tous les calculs", "en": "Access to all the tools"}
                    )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Accès au portail client", "en": "Access to client portal"}
                    )
                },
                {
                    title: new TextContentModel(
                        {
                            "fr": "Accès aux questionnaires de récolte d'information client",
                            "en": "Client questionnaires to gather tax information"
                        }
                    )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Support par clavardage", "en": "Online chat support"}
                    )
                }
            ],
            callToAction: new TextContentModel(
                {"fr": "Essai gratuit durant 14 jours", "en": "Free 14-day trial"}
            ),
            subtitle2: new TextContentModel(
                {"fr": "Aucune carte de crédit requise", "en": "No credit card needed"}
            ),
        },
        {
            price: new TextContentModel(
                {"fr": "Entreprise", "en": "Enterprise"}
            ),
            unit: new TextContentModel(
                {"fr": ""}
            ),
            users: new TextContentModel(
                {"fr": "Illimité", "en": "Unlimited"}
            ),
            subtitle: new TextContentModel(
                {"fr": "Pour les grands cabinets", "en": "Large accounting firms"}
            ),
            features: [
                {
                    title: new TextContentModel(
                        {"fr": "Nombre illimité d'utilisateurs", "en": "Unlimited users"}
                    )
                }, {
                title: new TextContentModel(
                    {"fr": "Accès à tous les calculs", "en": "Access to all the tools"}
                )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Accès au portail client", "en": "Access to client portal"}
                    )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Personnalisation à vos couleurs d'entreprise", "en": "Customized to your colours"}
                    )
                },
                {
                    title: new TextContentModel(
                        {
                            "fr": "Accès aux questionnaires de récolte d'information client",
                            "en": "Client questionnaires to gather tax information"
                        }
                    )
                },
                {
                    title: new TextContentModel(
                        {"fr": "Support par clavardage et téléphone", "en": "Online chat and phone support"}
                    )
                }
            ],
            callToAction: new TextContentModel(
                {"fr": "Contactez-nous", "en": "Contact-us"}
            ),
            subtitle2: new TextContentModel(
                {"fr": ""}
            ),
        }
    ],

    PricingTable: {
        title: new TextContentModel(
            {"fr": "Choisissez le forfait qui vous convient le mieux", "en": "Choose the plan that works for you"}
        ),
        singleUser: {
            title: new TextContentModel(
                {"fr": "1 utilisateur", "en": "1 user"}
            ),
            monthlyPricing: new TextContentModel(
                {"fr": "89$ / mois", "en": "$89 / month"}
            )
        },
        users25: {
            title: new TextContentModel(
                {"fr": "25 utilisateurs", "en": "25 users"}
            ),
            monthlyPricing: new TextContentModel(
                {"fr": "199$ / mois", "en": "$199 / month"}
            ),
            popular: new TextContentModel(
                {"fr": "Populaire", "en": "Most popular"}
            ),
        },
        enterprise: {
            title: new TextContentModel(
                {"fr": "Entreprise", "en": "Enterprise"}
            ),
        },
        categories: {
            accessCalculations: new TextContentModel(
                {"fr": "Accès aux outils fiscaux suivants:", "en": "Access to the following tax tools:"}
            ),
            salaryVsDividends: {
                title: new TextContentModel(
                    {"fr": "Salaire vs dividendes", "en": "Salary vs dividends"}
                ),
                popup: new TextContentModel(
                    {
                        "fr": "L’outil salaire vs dividende détermine le montant précis idéal qui minimise les conséquences fiscales",
                        "en": "The Salary vs Dividends tools identifies the optimal amount that minimizes tax consequences"
                    }
                ),
            },
            carOwnership: {
                title: new TextContentModel(
                    {
                        "fr": "Détention automobile: personnelle ou corporative",
                        "en": "Vehicle Ownership: Personal vs Corporate ownership"
                    }
                ),
                popup: new TextContentModel(
                    {
                        "fr": "Le calcul « Détention automobile: personnelle ou corporative » permet de calculer le coût fiscal réel d’une automobile selon si elle est achetée, louée soit par l'employé, soit par la compagnie.",
                        "en": "The Vehicle Ownership tool identifies the real cost of owning a vehicle whether it's purchased, financed, leased by either the employee or the corporation."
                    }
                ),
            },
            rollover: {
                title: new TextContentModel(
                    {"fr": "Roulement fiscal", "en": "Tax Rollover"}
                ),
                popup: new TextContentModel(
                    {
                        "fr": "Le roulement fiscal consiste à transférer un bien à une société canadienne dans le but de reporter de son imposition.",
                        "en": "The tax rollover consists"
                    }
                ),
            },
            sharesSelling: {
                title: new TextContentModel(
                    {"fr": "Vente d'actions", "en": "Shares selling"}
                ),
                popup: new TextContentModel(
                    {
                        "fr": "L'outils \"Vente d'actions\" permet de calculer les conséquences fiscales de la vente des actions d'une société tel qu'un gain ou une perte en capital.",
                        "en": "The Shares selling tool calculates the tax consequences of selling shares."
                    }
                ),
            },
            clientPortal: {
                title: new TextContentModel(
                    {"fr": "Portail client Illimité", "en": "Unlimited access to client portal"}
                ),
                access: new TextContentModel(
                    {
                        "fr": "Accès au portail client vous permettant de récolter les informations de vos clients",
                        "en": "Client portal access allow you to gather tax information from your clients"
                    }
                ),
                questionnaire: {
                    title: new TextContentModel(
                        {
                            "fr": "Questionnaires spécifiques et complets de prise d’information",
                            "en": "Client questionnaires et information gathering"
                        }
                    ),
                    tax2022: new TextContentModel(
                        {"fr": "Questionnaires d’impôt 2022", "en": "2022 Tax questionnaires"}
                    ),
                    number: new TextContentModel(
                        {"fr": "Nombre de questionnaires", "en": "Number of questionnaires"}
                    ),
                    numberOfClients: new TextContentModel(
                        {"fr": "Nombre de clients", "en": "Number of clients"}
                    ),
                }
            },
            securedHosting: new TextContentModel(
                {"fr": "Hébergement sécurisé", "en": "Secured hosting"}
            ),
            customColors: new TextContentModel(
                {
                    "fr": "Personnalisation à vos couleurs et votre style",
                    "en": "Customization to your colours and style"
                }
            ),
            dedicatedCloudHosting: new TextContentModel(
                {"fr": "Environnement infonuagique dédié", "en": "Dedicated cloud hosting"}
            ),
            internalToolsIntegration: new TextContentModel(
                {"fr": "Intégration avec vos outils internes", "en": "Integration with your internal tools"}
            ),
            chat: new TextContentModel(
                {"fr": "Support par clavardage", "en": "Online chat"}
            ),
        }
    },

    "pricing.body.faq.title": new TextContentModel(
        {"fr": "Questions fréquemment posées", "en": "Frequently Asked Questions"}
    ),

    PricingBodyFaqQuestions: [
        {
            title: new TextContentModel(
                {"fr": "Comment pouvez-vous offrir des prix aussi bas?", "en": "How can you offer such low prices?"}
            ),
            description: new TextContentModel(
                {
                    "fr": "Notre solution innovante est propulsée par les services infonuagiques. Ainsi, nous économisons énormément et sommes capables de vous retransmettre ces économies de coût. C’est pourquoi nos tarifs sont de loin les plus bas au Québec et au Canada.\n",
                    "en": "Our innovative solution is powered by cloud services for a much lower cost than our competitors and we are able to pass these cost savings on to you. That's why our rates are by far the lowest in Quebec and Canada.\n"
                }
            ),
        },
        {
            title: new TextContentModel(
                {
                    "fr": "J’ai plus que 25 utilisateurs mais je ne suis pas prêt à passer au forfait Entreprise, que puis-je faire?",
                    "en": "I have more than 25 uses but I'm not ready to switch to the Enterprise plan. What can I do?"
                }
            ),
            description: new TextContentModel(
                {
                    "fr": "Écrivez nous par le clavardage et nous vous proposons un tarif adapté extrêmement compétitif.\n",
                    "en": "Chat with us (or email us) and we will propose you a very competitive price.\n"
                }
            ),
        },
        {
            title: new TextContentModel(
                {
                    "fr": "Nous sommes un petit cabinet de moins de 5 utilisateurs mais nous ne sommes pas prêts à passer au forfait 25 utilisateurs, y a t’il un entre deux?",
                    "en": "We are a small accounting firm of less than 5 users but we are not ready to move to the 25 users plan. Is there an in-between?"
                }
            ),
            description: new TextContentModel(
                {
                    "fr": "Absolument! Écrivez nous par le clavardage et nous vous proposerons un tarif adapté qui saura répondre à vos désirs.\n",
                    "en": "Absolutely! Message us (chat or email) and we will propose you a very competitive price.\n"
                }
            ),
        },
        {
            title: new TextContentModel(
                {
                    "fr": "Est-ce possible de faire un essai gratuit de plus que 14 jours?",
                    "en": "Is it possible to do a free-trial for longer than 14 days."
                }
            ),
            description: new TextContentModel(
                {
                    "fr": "Tout à fait! Nous pouvons rallonger votre période d’essai sans problème afin de vous donner le temps de bien tester notre logiciel. À la fin de la période d’essai, contactez nous par clavardage et nous rallongerons votre période d’essai.\n",
                    "en": "Absolutely! You can extend your free trial without any issues to allow you to properly test our software. At the end of the free trial, contact us by chat and we will extend your trial.\n"
                }
            ),
        },
        {
            title:new TextContentModel(
                {
                    "fr": "Vais-je perdre mes données immédiatement à la fin de ma période d’accès?",
                    "en": "Will I lose all my data immediately once my free trial ends?"
                }
            ),
            description: new TextContentModel(
                {
                    "fr": "Non. Nous allons conserver vos données pour au moins 90 jours après la période d’essai. Après quoi, sans forfait actif, il est possible que vos données soient effacées. Néanmoins, la meilleure façon d’éviter une perte de données est de vous abonné rapidement!\n",
                    "en": "No. We will conserve your data for at least 90 days after your free trial. After, without any active license, it's possible that your data might be erased. To prevent data loss, keep an active license!\n"
                }
            ),
        },
        {
            title: new TextContentModel(
                {"fr": "Offrez-vous des codes promotionnels?", "en": "Do you offer discount?"}
            ),
            description: new TextContentModel(
                {
                    "fr": "Nous vous invitons à nous suivre sur les réseaux sociaux, il arrive que nous partagions des promotions! Sinon, n’hésitez pas à nous contacter si vous avez des besoins particuliers, nous trouverons une solution qui répondra à vos besoins.\n",
                    "en": "We invite you to follow us on social media, we often share promotions. In any case, do not hesitate to contact us if you have any questions or specific needs. We will find something that fits your needs.\n"
                }
            ),
        }
    ]
}
