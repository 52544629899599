import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export type FrequentlyAskedQuestion = {
    question: TextContentModel;
    answer: TextContentModel;
}

export const StudentsContentResources: {

    FrequentlyAskedQuestions: FrequentlyAskedQuestion[],
} & ContentResourceInterface & { [id in string]: any } = {


    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "Optitax - Gratuit pour les étudiants", "en": "Optitax - Free for students"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Découvrez comment Optitax propose des solutions complètes de gestion fiscale spécialement conçues pour les  étudiants. Accédez à des ressources, des outils et des tutoriels précieux pour améliorer votre compréhension  de la comptabilité fiscale. Bénéficiez d'études de cas réelles et d'exemples de déclarations fiscales pour  développer des compétences pratiques. Donnez-vous les connaissances et l'expertise nécessaires pour exceller  dans le monde de la fiscalité. Commencez votre parcours avec Optitax dès aujourd'hui.\n",
                "en": "Discover how Optitax provides comprehensive tax management solutions tailored specifically for students.  Access valuable resources, tools, and tutorials to enhance your understanding of tax accounting. Benefit from  real-life case studies and examples of tax filings to develop practical skills. Empower yourself with the  knowledge and expertise needed to excel in the world of taxation. Start your journey with Optitax today.\n"
            }
        ),

        Keywords: new TextContentModel(
            {
                "fr": "Logiciel de gestion fiscale pour étudiants, Ressources fiscales pour étudiants, Outils fiscaux pour étudiants, Étudiants en comptabilité fiscale, Aide fiscale pour étudiants, Formation fiscale pour étudiants, Études de cas fiscaux pour étudiants, Exemples de déclarations fiscales pour étudiants, Tutoriels fiscaux pour étudiants, Programmes d'apprentissage en fiscalité pour étudiants",
                "en": "Tax management software for students, Tax resources for students, Tax tools for students, Tax accounting students, Tax assistance for students, Tax training for students, Tax case studies for students, Examples of tax filings for students, Tax tutorials for students, Tax learning programs for students"
            }
        ),
    },

    "students.body.hero.title": new TextContentModel(
        {"fr": "Optitax est gratuit pour les étudiants!", "en": "Optitax is free for students!"}
    ),

    "students.body.hero.subtitle": new TextContentModel(
        {
            "fr": "Tous les étudiants en comptabilité (collégial ou universitaire) sont elligibles à recevoir une licence gratuite d'Optitax.\nPour ce faire, vous n'avez qu'à cliquer sur le bouton ci-dessous. Par la suite, vous serez invité à vous créer un compte et ensuite à remplir un formulaire.\n",
            "en": "All the accounting students are elligible for a free license of Optitax.\nTo get started, click on the button below. You will be asked to create an account and then you will out a form.\n"
        }
    ),

    "students.body.hero.call-to-action": new TextContentModel(
        {"fr": "Obtenir une licence gratuite", "en": "Redeem free license"}
    ),

    FrequentlyAskedQuestionsTitle: new TextContentModel({
        fr: "Foire aux questions",
        en: "Frequently asked questions",
    }),

    FrequentlyAskedQuestions: [
        {
            question: new TextContentModel(
                {
                    "fr": "Mon CEGEP n'est pas supporté, que puis-je faire?",
                    "en": "My CEGEP is not supported, what can I do?"
                }
            ),
            answer: new MarkdownContentModel(
                {
                    "fr": "Contactez-nous par clavardage ou courriel et nous trouverons une solution!",
                    "en": "Chat with us and we will find a solution!"
                }
            ),
        },
        {
            question: new TextContentModel(
                {
                    "fr": "Quels sont les programmes collégiaux ou universitaires supportés?",
                    "en": "What are the supported CEGEP or university programs?"
                }
            ),
            answer: new MarkdownContentModel(
                {
                    "fr": "À l'heure actuelle, les universités et CEGEP suportés sont ceux-ci:\n**Université**:\n\n  * Université Laval\n  * Université de Montréal\n  * Université du Québec à Montréal\n\n**CEGEP**:\n  \n  * Édouard-Montpetit\n  * Collège Champlain Saint-Lambert\n",
                    "en": "At the moment, the universities and CEGEP supported are:\n**Universities**:\n\n  * Université Laval\n  * Université de Montréal\n  * Université du Québec à Montréal\n\n**CEGEP**:\n  \n  * Édouard-Montpetit\n  * Champlain College Saint-Lambert\n"
                }
            ),
        },
        {
            question: new TextContentModel(
                {
                    "fr": "Est-ce que tous les outils sont offerts aux étudiants?",
                    "en": "Are all the tools available to students?"
                }
            ),
            answer: new MarkdownContentModel(
                {
                    "fr": "Oui! Tous les outils sont disponibles. C'est l'équivalent d'une license [1 utilisateur](/tarifs) qui vous est offerte gratuitement!",
                    "en": "Yes! All the tools are available. We give you the [individual](/pricing) license for free!"
                }
            )
        }
    ],
}
