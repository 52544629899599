import { advancedFormControl } from "../../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../../enums/field-type.enum";
import { translationBaseKey} from "../../../../decorators/translation-base-key.decorator";
import { IsInt, IsNotEmpty, IsNumber, IsString } from "@pristine-ts/class-validator";
import { v4 as uuid } from 'uuid';
import {ErrorMessageBuilder} from "../../../../builders/error-message.builder";

@translationBaseKey("income-tax-child-camp-options")
export class IncomeTaxPersonalChildCampOptions {
  @advancedFormControl({
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre de semaines passé à cette colonie par votre enfant",
      en: "Number of weeks spent at this camp by your child"
    },
    tooltip: {
      fr: "Indiquez le nombre de semaines que votre enfant a passé à cette colonie de vacances. Ce nombre est utilisé pour déterminer les frais de garde admissibles aux crédits d'impôt.",
      en: "Specify the number of weeks that your child spent at this summer camp. This number is used to determine eligible childcare expenses for tax credits.",
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  numberOfWeeks: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Frais de garde associés à cette colonie?",
      en: "Care expenses associated with this camp?"
    },
    tooltip: {
      fr: "Indiquez le montant des frais de garde associés à cette colonie de vacances. Ces frais peuvent être pris en compte pour les crédits d'impôt sur les frais de garde.",
      en: "Specify the amount of childcare expenses associated with this summer camp. These expenses may be considered for childcare tax credits.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  careExpenses: number;
}
