import { TypeEnum, TypeUtils } from "@pristine-ts/metadata";
import { BaseNormalizer } from "./base.normalizer";
export const NumberNormalizerUniqueKey = "PRISTINE_NUMBER_NORMALIZER";
export class NumberNormalizer extends BaseNormalizer {
  getUniqueKey() {
    return NumberNormalizerUniqueKey;
  }
  normalize(source, options) {
    const typeEnum = TypeUtils.getTypeOfValue(source);
    options = this.getOptions(options);
    switch (typeEnum) {
      case TypeEnum.String:
        const value = parseFloat(source);
        if (isNaN(value) === false) {
          return value;
        }
        break;
      case TypeEnum.Number:
        return source;
    }
    if (typeEnum === undefined || typeEnum === TypeEnum.Null) {
      if ((options === null || options === void 0 ? void 0 : options.ignoreUndefined) === false) {
        return 0;
      }
      return undefined;
    }
  }
}
