import {ExportResourceEnum} from "../../../../../../../common/src/enums/export-resource.enum";
import {
    ExportResourceProviderInterface
} from "../../../../../../../common/src/interfaces/export-resource-provider.interface";
import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ExportResourceProvider implements ExportResourceProviderInterface {

    constructor(private readonly httpClient: HttpClient) {
    }

    private getUrls(): string {
        return `/assets/pdfs/`;
    }

    async getResource(resource: ExportResourceEnum): Promise<ArrayBuffer> {
        const url = `/assets/${resource}`;

        return new Promise<ArrayBuffer>((resolve, reject) => {
            this.httpClient.get(url, {responseType: 'arraybuffer'}).subscribe((response) => {
                resolve(response);
            });
        });
    }
}
