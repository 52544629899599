import {ValidationErrorMessagesInterface} from "../interfaces/validation-error-messages.interface";

export const validationErrorMessagesConstant: ValidationErrorMessagesInterface = {
    // Array
    "ARRAY_CONTAINS" : {
        en: "The values you have entered ('%value%') must at least contain the following values:'%values%'.",
        fr: "Les valeurs que vous avez entré ('%value%') doivent au moins contenir les valeurs suivantes: '%values%'.",
    },
    "ARRAY_INVALID": {
        en: "This field must be a list. It's currently of type '%type%'.",
        fr: "Ce champ doit être une liste plutôt que '%type%'.",
    },
    "ARRAY_MAX_SIZE": {
        en: "The maximum number of elements that can be entered in this list is %max%, you currently have %length%.",
        fr: "Le nombre maximum d'éléments pour cette liste est de %max%. Vous en avez entré %length%.",
    },
    "ARRAY_MIN_SIZE": {
        en: "The minimum number of elements that can be entered in this list is %min%, you currently have %length%.",
        fr: "Le nombre minimum d'éléments pour cette liste est de %min%. Vous en avez entré %length%.",
    },
    "ARRAY_NOT_CONTAINS": {
        en: "The values you have entered ('%value%') must not contain the following values:'%values%'.",
        fr: "Les valeurs que vous avez entré ('%value%') ne peuvent contenir aucune des valeurs suivantes: '%values%'.",
    },
    "ARRAY_NOT_EMPTY": {
        en: "This list cannot be empty and it currently is.",
        fr: "La liste ne peut être vide alors qu'elle l'est présentement.",
    },
    "ARRAY_UNIQUE": {
        en: "This list must contain only unique elements and it currently doesn't.",
        fr: "Cette liste doit contenir uniquement des éléments différents alors qu'elle en contient des identiques.",
    },

    // Common
    "ALLOW": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "EQUALS": {
        en: "This field must be set to '%expectedValue%'. Current value: '%value%'.",
        fr: "Ce champ doit être '%expectedValue%' plutôt que sa valeur actuelle: '%value'.",
    },
    "IS_DEFINED": {
        en: "This field must be defined. It currently isn't.",
        fr: "Ce champ doit être défini alors qu'il ne l'est pas.",
    },
    "IS_EMPTY": {
        en: "This field must be empty.",
        fr: "Ce champ doit être vide.",
    },
    "IS_IN": {
        en: "This field must be one of the following values: '%possibleValues%'. Current value: '%value%'.",
        fr: "Ce champ doit correspondre à une des valeurs suivantes: '%possibleValues%' plutôt que sa valeur actuelle: '%value%'.",
    },
    "IS_LATITUDE": {
        en: "This field must be a Latitude ([-90, 90]). Current value: '%value%'.",
        fr: "Ce champ doit être une Latitude ([-90, 90]) plutôt que sa valeur actuelle: '%value'.",
    },
    "IS_LAT_LONG": {
        en: "This field must be a 'latitude,longitude' string (ex: '-34.50,174.50'). Current value: '%value'.",
        fr: "Ce champ doit comprendre la latitude et longitude ensemble séparé par une virgule (ex '-34.50,174.50') plutôt que sa valeur actuelle: '%value'.",
    },
    "IS_LONGITUDE": {
        en: "This field must be a Longitude ([-180, 180]). Current value: '%value%'.",
        fr: "Ce champ doit être une Longitude ([-180, 180]) plutôt que sa valeur actuelle: '%value'.",
    },
    "IS_NOT_EMPTY": {
        en: "This field must not be empty.",
        fr: "Ce champ ne doit pas être vide.",
    },
    "IS_NOT_IN": {
        en: "This field must NOT be one of the following values: '%possibleValues%'. Current value: '%value%'.",
        fr: "Ce champ NE DOIT PAS correspondre à une des valeurs suivantes: '%possibleValues%'. Valeur actuelle: '%value%'.",
    },
    "IS_OPTIONAL": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "NOT_EQUALS": {
        en: "This field must NOT be set to '%expectedValue%'. Current value: '%value%'.",
        fr: "Ce champ NE DOIT PAS correspondre à '%expectedValue%'. Valeur actuelle: '%value'.",
    },

    // Date
    "INVALID_DATE": {
        en: "This field must be a Date.",
        fr: "Ce champ doit être une Date.",
    },
    "MAX_DATE": {
        en: "This field cannot have a Date later than '%maxDate%'.",
        fr: "Ce champ ne peut contenir une date ultérieure à '%maxDate%'.",
    },
    "MIN_DATE": {
        en: "This field cannot have a Date sooner than '%minDate%'.",
        fr: "Ce champ ne peut contenir une date antérieure à '%minDate%'.",
    },

    // Number
    "IS_DIVISIBLE_BY": {
        en: "This field must be divisible by '%numberToDivideBy%'. '%value%' cannot be divided by '%numberToDivideBy%'.",
        fr: "Ce champ doit être divisible par '%numberToDivideBy%'. '%value%' ne peut être divisée par '%numberToDivideBy%'.",
    },
    "IS_NEGATIVE": {
        en: "This field must be smaller than 0. It's currently 0 or positive.",
        fr: "Ce champ doit être plus petit que 0.",
    },
    "IS_POSITIVE": {
        en: "This field must be greater than 0. It's currently 0 or negative.",
        fr: "Ce champ doit être plus grand que 0.",
    },
    "MAX": {
        en: "This field must not be greater than '%maxNumber%'.",
        fr: "Ce champ ne peut être supérieur à '%maxNumber%'.",
    },
    "MIN": {
        en: "This field must not be smaller than '%minNumber%'.",
        fr: "Ce champ ne peut être inférieur à '%minNumber%'.",
    },

    // Object
    "IS_INSTANCE": {
        en: "This field must be an instance of '%targetTypeConstructor%'. It currently isn't.",
        fr: "Ce champ doit être du type '%targetTypeConstructor%' alors qu'il ne l'est pas.",
    },
    "IS_NOT_EMPTY_OBJECT": {
        en: "This field must be a non-empty object. It's currently empty.",
        fr: "Ce champ ne peut être un objet vide alors qu'il l'est présentement.",
    },

    // String
    "CONTAINS": {
        en: "This field must contain this text: '%seedToContain%'.",
        fr: "Ce champ doit contenir le texte suivant: '%seedToContain%'.",
    },
    "IS_ALPHA": {
        en: "This field must contain only letters (a-zA-Z).",
        fr: "Ce champ ne doit contenir que des lettres (a-zA-Z).",
    },
    "IS_ALPHANUMERIC": {
        en: "This field must contain only letters and numbers.",
        fr: "Ce champ ne doit contenir que des chiffres et lettres.",
    },
    "IS_ASCII": {
        en: "This field must contain only ASCII characters. Current value: '%value%'.",
        fr: "Ce champ ne doit contenir que des caractères ASCII. Valeur actuelle: '%value%'.",
    },
    "IS_BASE32": {
        en: "This field must contain a 'base32' encoded string. Current value: '%value%'.",
        fr: "Ce champ ne doit contenir qu'une chaine encodée en 'base32'. Valeur actuelle: '%value%'.",
    },
    "IS_BASE64": {
        en: "This field must contain  a 'base64' encoded string. Current value: '%value%'.",
        fr: "Ce champ ne doit contenir  qu'une chaine encodée en 'base64'. Valeur actuelle: '%value%'.",
    },
    "IS_BIC": {
        en: "This field must contain a BIC or SWIFT code. Current value: '%value%'.",
        fr: "Ce champ ne doit contenir qu'un code BIC ou SWIFT. Valeur actuelle: '%value%'.",
    },
    "IS_BOOLEAN_STRING": {
        en: "This field must contain either 'true' or 'false'.",
        fr: "Ce champ ne doit contenir que 'true' (vrai) ou 'false' (faux).",
    },
    "IS_BTC_ADDRESS": {
        en: "This field must contain a valid Bitcoin address. Current value: '%value%'.",
        fr: "Ce champ doit contenir une adresse Bitcoin valide. Valeur actuelle: '%value%'.",
    },
    "IS_BYTE_LENGTH": {
        en: "This field must be between '%minLength%' and '%maxLength%' characters.'. Current value: '%value%'.",
        fr: "Ce champ doit être entre '%minLength%' et '%maxLength%' caractères de long . Valeur actuelle: '%value%'.",
    },
    "IS_CREDIT_CARD": {
        en: "This field must contain a valid credit card number.",
        fr: "Ce champ doit contenir un numéro de carte de crédit valide.",
    },
    "IS_CURRENCY": {
        en: "This field must contain a valid currency.",
        fr: "Ce champ doit contenir une monnaie valide.",
    },
    "IS_DATA_URI": {
        en: "This field must contain a valid data URI format. Current value: '%value%'.",
        fr: "Ce champ doit contenir une URI 'data'. Valeur actuelle: '%value%'.",
    },
    "IS_DATE_STRING": {
        en: "This field must contain a valid date string (ex: 2022-09-27 18:00:00.000).",
        fr: "Ce champ doit contenir une date dans un format valide (ex: 2022-09-27 18:00:00.000).",
    },
    "IS_DECIMAL": {
        en: "This field must contain a valid decimal number",
        fr: "Ce champ doit contenir un nombre à virgules.",
    },
    "IS_EAN": {
        en: "This field must contain an EAN (European Article Number). Current value: '%value%'.",
        fr: "Ce champ doit contenir un EAN (European Article Number). Valeur actuelle: '%value%'.",
    },
    "IS_EMAIL": {
        en: "This field must contain a valid email address.",
        fr: "Ce champ doit contenir une adresse électronique valide.",
    },
    "IS_ETHEREUM_ADDRESS": {
        en: "This field must contain a valid Ethereum address. Current value: '%value%'.",
        fr: "Ce champ doit contenir un adresse Ethereum valide. Valeur actuelle: '%value%'.",
    },
    "IS_FIREBASE_PUSH_ID": {
        en: "This field must contain a valid Firebase Push Id. Current value: '%value%'.",
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_FQDN": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_FULL_WIDTH": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_HALF_WIDTH": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_HASH": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_HEXADECIMAL": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_HEXA_COLOR": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_HSL": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_IBAN": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_IDENTITY_CARD": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_IP": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_ISBN": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_ISIN": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_ISO_8601": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_ISO_31661_ALPHA_2": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_ISO_31661_ALPHA_3": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_ISRC": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_ISSN": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_JSON": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_JWT": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_LOCALE": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_LOWERCASE": {
        en: "This field must contain only lowercase characters. Current value: '%value%'.",
        fr: "Ce champ doit contenir uniquement des caractères en minuscules. Valeur actuelle: '%value%'.",
    },
    "IS_MAC_ADDRESS": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_MAGNET_URI": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_MILITARY_TIME": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_MOBILE_PHONE": {
        en: "This field must contain a valid phone number. Current value: '%value%'.",
        fr: "Ce champ doit contenir un numéro de téléphone valide. Valeur actuelle: '%value%'.",
    },
    "IS_MONGO_ID": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_MULTI_BYTE": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_MYME_TYPE": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_NUMBER_STRING": {
        en: "This field must contain a valid number. Current value: '%value%'.",
        fr: "Ce champ doit un chiffre valide. Valeur actuelle: '%value%'.",
    },
    "IS_OCTAL": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_PASSPORT_NUMBER": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_PHONE_NUMBER": {
        en: "This field must contain a valid phone number. Current value: '%value%'.",
        fr: "Ce champ doit contenir un numéro de téléphone valide. Valeur actuelle: '%value%'.",
    },
    "IS_PORT": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_POSTAL_CODE": {
        en: "This field must contain a valid Postal Code. Current value: '%value%'.",
        fr: "Ce champ doit un code postal valide. Valeur actuelle: '%value%'.",
    },
    "IS_RFC3339": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_RGB_COLOR": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_SEM_VER": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_SURROGATE_PAIR": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "IS_UPPERCASE": {
        en: "This field must contain only uppercase characters.",
        fr: "Ce champ doit contenir uniquement des caractères en majuscules.",
    },
    "IS_URL": {
        en: "This field must contain a valid URL.", // todo
        fr: "Ce champ doit contenir une URL valide.", // todo
    },
    "IS_UUID": {
        en: "This field must contain a valid UUID. Current value: '%value%'.", // todo
        fr: "Ce champ doit contenir un UUID valide. Valeur actuelle: '%value%'.", // todo
    },
    "IS_VARIABLE_WIDTH": {
        en: "This field must contain. Current value: '%value%'.", // todo
        fr: "Ce champ doit. Valeur actuelle: '%value%'.", // todo
    },
    "LENGTH": {
        en: "This field must be between '%minLength%' and '%maxLength%' characters.",
        fr: "Ce champ doit être entre '%minLength%' et '%maxLength%' caractères de long.",
    },
    "MATCHES": {
        en: "This field must match this regular expression: '%pattern%'.",
        fr: "Ce champ doit correspondre à l'expression régulière suivante: '%pattern%'.", // todo
    },
    "MAX_LENGTH": {
        en: "This field must be shorter than or equal to '%maxLength%' characters.",
        fr: "Ce champ doit être plus court que le maximum de '%maxLength%' caractères.",
    },
    "MIN_LENGTH": {
        en: "This field must be longer than or equal to '%minLength%' characters.'.",
        fr: "Ce champ doit être plus long que le minimum de '%minLength%' caractères.",
    },
    "NOT_CONTAINS": {
        en: "This field must NOT contain this text: '%seedToContain%'.",
        fr: "Ce champ NE DOIT PAS contenir le texte suivant: '%seedToContain%'.",
    },

    // Typechecker
    "IS_ARRAY": {
        en: "This field must be a list. It's currently of type '%type%'.",
        fr: "Ce champ doit être une liste plutôt que '%type%'.",
    },
    "IS_BOOLEAN": {
        en: "This field must be a Yes or No.",
        fr: "Ce champ doit être Oui ou Non.",
    },
    "IS_DATE": {
        en: "This field must be a Date.",
        fr: "Ce champ doit être une Date.",
    },
    "IS_ENUM": {
        en: "This field must contain one of the suggested values",
        fr: "Ce champ doit contenir une des valeurs suggérées.",
    },
    "IS_INT": {
        en: "This field must be an integer.",
        fr: "Ce champ doit être un nombre entier.",
    },
    "IS_NUMBER": {
        en: "This field must be a number.",
        fr: "Ce champ doit être un nombre.",
    },
    "IS_OBJECT": {
        en: "This field must be an object. It's currently of type '%type%'.",
        fr: "Ce champ doit être un objet plutôt que '%type%'.",
    },
    "IS_STRING": {
        en: "This field must be a character string.",
        fr: "Ce champ doit être une chaine de caractères.",
    },

}