import { TypeEnum, TypeUtils } from "@pristine-ts/metadata";
import { format } from "date-fns";
import { BaseNormalizer } from "./base.normalizer";
export const StringNormalizerUniqueKey = "PRISTINE_STRING_NORMALIZER";
export class StringNormalizer extends BaseNormalizer {
  getUniqueKey() {
    return StringNormalizerUniqueKey;
  }
  normalize(source, options) {
    const typeEnum = TypeUtils.getTypeOfValue(source);
    options = this.getOptions(options);
    if (typeEnum === undefined || typeEnum === TypeEnum.Null) {
      if ((options === null || options === void 0 ? void 0 : options.ignoreUndefined) === false) {
        return "";
      }
      return undefined;
    }
    switch (typeEnum) {
      case TypeEnum.String:
        return source;
      case TypeEnum.Number:
        return "" + source;
      case TypeEnum.Boolean:
        return source ? "true" : "false";
      case TypeEnum.Date:
        if (options === null || options === void 0 ? void 0 : options.dateFormat) {
          return format(source, options.dateFormat);
        }
        // This will return the default format we want: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toJSON
        return source.toJSON();
      case TypeEnum.Array:
        return source.map(item => this.normalize(item, options));
      case TypeEnum.Symbol:
        return source.toString();
      case TypeEnum.Object:
        if (source.hasOwnProperty("toString") === true) {
          return source.toString();
        }
        try {
          return JSON.stringify(source);
        } catch (e) {
          return "" + source;
        }
      // For now, let's do that for other types. We can make it better eventually.
      default:
        return "" + source;
    }
  }
}
