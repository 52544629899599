import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class PreferenceManager {

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    getDoNotShowLanguageSwitcherBar(): boolean {
        if (!isPlatformBrowser(this.platformId)) {
            return false;
        }

        return localStorage.getItem("do-not-show-language-switcher-bar") != null;
    }

    setDoNotShowLanguageSwitcherBar() {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        localStorage.setItem("do-not-show-language-switcher-bar", "true");
    }
}
