import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {CardsContentResources} from "./cards.content-resources";
import {
    IndividualCalculationContentResourceInterface
} from "../interfaces/individual-calculation-content-resource.interface";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export const VenteActionsContentResources: IndividualCalculationContentResourceInterface & ContentResourceInterface & { [id in string]: any } = {

    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "Optitax - Ne faites plus vos opérations fiscales dans Excel", "en": "Optitax -"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Optitax est un logiciel web qui offre des outils utiles et performants dans le  domaine de la fiscalité. Nous offrons des calculs variés, allant d’un calcul simple,  tel qu’un rachat d’actions, à des calculs complexes, tel que la détention personnelle ou  corporative d’un véhicule. La mission d’Optitax est de développer une gamme complète d’outils  fiscaux afin que les cabinets comptables ou autres professionnels n’aient pas à développer leurs propres  outils à l’interne et puissent se concentrer entièrement sur leur pratique.\n",
                "en": "Optitax is a web software that offers useful and powerful tools in the field of taxation. We offer various calculations, ranging from a simple calculation, such as a share buyback, to complex calculations, such as the personal or corporate ownership of a vehicle. Optitax's mission is to develop a complete range of tax tools so that accounting firms or other professionals do not have to develop their own tools internally and can focus entirely on their practice."
            }
        ),
        Keywords: new TextContentModel(
            {
                "fr": "comptable, accounting, fiscalité, optimiser votre fiscalité",
                "en": "accounting, tax, optimize your tax"
            }
        ),
    },

    Hero: {
        Title: new TextContentModel(
            {"fr": "Vente d'actions", "en": "Stocks Sale"}
        ),
        Subtitle: new TextContentModel(
            {"fr": "Outil fiscal conçu spécifiquement pour le Québec", "en": "Software built specifically for Québec"}
        ),
        Description: new TextContentModel(
            {
                "fr": "Calculer les conséquences fiscales de l'article 84.1 n'aura jamais été aussi facile et rapide.",
                "en": "Calculating the tax consequences of section 84.1 has never been easier and faster."
            }
        ),
        CallToAction: new TextContentModel(
            {
                "fr": "Procédez à une vente d'actions maintenant",
                "en": "Calculate the tax implications of a Stocks Sale now!"
            }
        ),
        Image: new ImageContentModel(
            {
                url: new TextContentModel({
                    fr: "images/illustrations/shares_selling.svg",
                    en: "images/illustrations/shares_selling.svg",
                }),
            }
        ),
    },

    MainFeature: {
        Title: new TextContentModel(
            {
                "fr": "Avec Optitax, effectuez une vente d'actions en 2 étapes simples!",
                "en": "Calculate the Stocks Sale tax implications in 2 simple steps!"
            }
        ),
        Description: CardsContentResources.SharesSelling.description!,
        Image: new ImageContentModel(
            {
                url: new TextContentModel({
                    fr: "images/illustrations/man-success.svg",
                    en: "images/illustrations/man-success.svg",
                }),
            }
        ),
        Steps: [
            {
                Title: new TextContentModel(
                    {"fr": "Remplissez le formulaire Optitax", "en": "Fill out the fields in Optitax"}
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Notre système de formulaire intelligent vous affiche uniquement les champs nécessaires à remplir en activant et désactivant en temps réel les champs selon les informations entrées. Vous n’avec donc qu’à entrer uniquement les champs absolument nécessaires.",
                        "en": "Our intelligent form system displays only the necessary fields for filling out by activating and deactivating fields in real time based on the entered information. Therefore, you only need to enter the absolutely necessary fields"
                    }
                ),
            },
            {
                Title: new TextContentModel(
                    {
                        "fr": "Consultez immédiatement les résultats",
                        "en": "Immediately see the results"
                    }
                ),
                Description: new TextContentModel(
                    {
                        "fr": "Une fois que vous avez rentré les informations, en quelques secondes, vous pourrez constater le résultat et revenir effectuer des changements au besoin.",
                        "en": "Once you have entered the information, within a few seconds, you will be able to see the result and make any necessary changes."
                    }
                ),
            },
        ]
    },

    Features: [
        {
            Title: new TextContentModel(
                {"fr": "Des outils fiscaux adaptés au Québec", "en": "Tool specifically built for Québec"}
            ),
            Description: new TextContentModel(
                {
                    "fr": "Optitax est une firme québécoise qui développe des outils fiscaux pour les cabinets comptables québécois",
                    "en": "Optitax is a Québec-based technologies comppany that develops a powerful software that does advanced tax calculations for accounting firms."
                }
            ),
            CallToAction: new TextContentModel(
                {"fr": "Effectuez ce calcul maintenant", "en": "Try this tool now."}
            ),
            Image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        "fr": "images/illustrations/man-phone.svg",
                        "en": "images/illustrations/man-phone.svg",
                    })
                }
            ),
        },
        {
            Title: new TextContentModel(
                {"fr": "Notre solution est accessible sur tous vos appareils", "en": "Available on all your devices"}
            ),
            Description: new TextContentModel(
                {
                    "fr": "Optitax est disponible sur tous vos appareils: Windows, Mac OS, iPhone, Android et iPad!",
                    "en": "Optitax is available on all your devices: Windows, Mac OS, iPhone, Android et iPad!"
                }
            ),
            CallToAction: new TextContentModel(
                {"fr": "Effectuez ce calcul maintenant", "en": "Try this tool now"}
            ),
            Image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        "fr": "images/illustrations/devices.svg",
                        "en": "images/illustrations/devices.svg",
                    }),
                }
            ),
        },
        {
            Title: new TextContentModel(
                {
                    "fr": "Génération de PDFs finaux du gouvernement",
                    "en": "Download the generated and filled CRA and Revenu Quebec PDFs"
                }
            ),
            Description: new TextContentModel(
                {
                    "fr": "Optitax génère les formulaires de roulement avec les résultats. Les formulaires **T2057** et **TP-518** seront générés avec les résultats calculés par Optitax.",
                    "en": "Optitax generates the rollover forms along with the results. The T2057 and TP-518 forms will be generated with the results calculated by Optitax."
                }
            ),
            CallToAction: new TextContentModel(
                {"fr": "Effectuez ce calcul maintenant", "en": "Try this tool now"}
            ),
            Image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/pdf-download.svg",
                        en: "images/illustrations/pdf-download.svg",
                    })
                }
            ),
        }
    ],
}
