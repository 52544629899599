import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import { IsBoolean, IsNotEmpty, IsString } from "@pristine-ts/class-validator";
import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { v4 as uuid } from 'uuid';
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-shareholder-options")
export class RolloverShareholderOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Nom de l'actionnaire",
      en: "Shareholder's Name"
    },
    tooltip: {
      fr: "Ce champ représente le nom de l'actionnaire, c'est-à-dire l'individu ou l'entité qui détient les actions. Il est essentiel pour identifier le propriétaire des actions et le relier à cette classe d'actions spécifique. Ce nom est utilisé pour des fins de référence et de suivi.",
      en: "This field represents the name of the shareholder, i.e., the individual or entity holding the shares. It is essential for identifying the owner of the shares and linking them to this specific share class. This name is used for reference and tracking purposes."
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  title: string;

  @advancedFormControl({
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "Vendeur des actions?",
      en: "Is this shareholder the seller of the shares?"
    },
    tooltip: {
      fr: "Ce champ indique si l'actionnaire est le vendeur des actions. Si activé, cela signifie que cet actionnaire est en train de vendre ses actions. Si désactivé, cela signifie qu'il ne vend pas ses actions pour le moment.",
      en: "This field indicates whether the shareholder is the seller of the shares. If enabled, it means that this shareholder is selling their shares. If disabled, it means that they are not currently selling their shares."
    },
    fieldType: FieldTypeEnum.Boolean,
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isSeller: boolean;
}
