import {Component, Input, OnDestroy, OnInit} from "@angular/core";

@Component({
    selector: 'font-awesome-icon',
    templateUrl: './font-awesome-icon.component.html',
    styleUrls: ['./font-awesome-icon.component.scss'],
    host: {
        ngSkipHydration: 'true',
    }
})
export class FontAwesomeIconComponent {
    @Input()
    icon: string | null = '';
}
