import {Inject, Injectable} from "@angular/core";
import {FlagEnum} from "../enums/flag.enum";

@Injectable()
export class FlagManager {
    constructor(@Inject("environment.flags") private readonly flags: { [key in FlagEnum]: boolean }) {
    }

    isActive(flag: FlagEnum): boolean {
        // Check first if flag is overriden in local storage
        const isActive: string | null = window.localStorage.getItem(this.getLocalstorageKey(flag));

        if (isActive == null) {
            return this.flags[flag] ?? false;
        }

        return isActive === "true";
    }

    activate(flag: FlagEnum) {
        window.localStorage.setItem(this.getLocalstorageKey(flag), "true");
    }

    deactivate(flag: FlagEnum) {
        window.localStorage.setItem(this.getLocalstorageKey(flag), "false");
    }

    private getLocalstorageKey(flag: FlagEnum): string {
        return "flag." + flag;
    }
}