import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {FlagManager} from "../managers/flag.manager";

@Injectable({
    providedIn: 'root'
})
export class FlagEnabledGuard implements CanActivate {
    constructor(private router: Router,
                private flagManager: FlagManager) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return new Observable<boolean | UrlTree>((observer) => {
            if(next.data.hasOwnProperty("flag")) {
                return observer.next(this.flagManager.isActive(next.data.flag));
            }

            return observer.next(true);
        });
    }

}