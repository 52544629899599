<footer>
    <div class="container">
        <div class="row information">
            <div class="col-12 col-lg-6">
                <h3>{{ FooterContentResources['footer.main.title'] | render | async }}</h3>
                <p>{{ FooterContentResources['footer.main.subtitle'] | render | async }}</p>
                <br>

                <!-- todo: Fix the logo is way too big -->
                <app-image [url]="'images/logos/logo-blue.png'"
                           [fetchPriority]="FetchPriorityEnum.Low"
                           [loading]="LoadingEnum.Lazy"
                           class="logo"
                ></app-image>

            </div>
            <div class="col-12 col-lg-6 text-end">
                <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.ContactUs)"
                   class="btn btn-light contact-us">{{ FooterContentResources['footer.main.contact-us'] | render | async }}</a>
                <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted)"
                   class="btn btn-primary start-now mt-4 mt-md-0 mt-lg-4 mt-xl-0">{{ FooterContentResources['footer.main.get-started'] | render | async }}</a>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-lg-4">
                <ul class="list-unstyled languages">
                    <li><a [routerLink]="this.routeManager.getCurrentRouteInLocale(LocaleEnum.French)">Français</a></li>
                    <li><a [routerLink]="this.routeManager.getCurrentRouteInLocale(LocaleEnum.English)">English</a></li>
                </ul>
            </div>

            <div class="col-xs-12 col-lg-4">
                <h4>{{ FooterContentResources['footer.main.calculations-title'] | render | async }}</h4>
                <ul class="list-unstyled">
                    @for(feature of FooterContentResources.Calculations;track $index) {
                        <li>
                            <a [routerLink]="'/' + this.routeManager.getRouteInCurrentLocale(RouteEnum.Calculations) + '/' + (feature.callToAction?.calculationKeyname | render | async)">{{ feature.title | render | async }}</a>
                        </li>
                    }
                </ul>
            </div>

            <div class="col-xs-12 col-lg-4">
                <ul class="list-unstyled languages">
                    <li>
                        <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.Support)">{{ FooterContentResources['footer.main.support'] | render | async }}</a>
                    </li>
                    <li>
                        <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.ContactUs)">{{ FooterContentResources['footer.main.contact-us'] | render | async }}</a>
                    </li>
                    <li>
                        <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.Students)">{{ FooterContentResources['footer.main.students'] | render | async }}</a>
                    </li>
                    <li>
                        <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.AboutUs)">{{ FooterContentResources['footer.main.about-us'] | render | async }}</a>
                    </li>
                    <li>
                        <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.Security)">{{ FooterContentResources['footer.main.security'] | render | async }}</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="social-medias text-center">
            <a href="https://www.youtube.com/channel/UCE9F15PvVhyLwrLQ9CVLuiA">
                <font-awesome-icon icon="fab fa-youtube fa-2x"></font-awesome-icon>
            </a>
        </div>

        <div class="row">
            <div class="col-12">
                <p class="text-center">Copyright &#64; 2024 Optitax Technologies Inc.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <p class="text-center"><a
                        [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.TermsAndConditions)">{{ FooterContentResources['footer.main.terms-and-conditions'] | render | async }}</a>
                </p>
            </div>
        </div>
    </div>
</footer>
