import {ContentTypeEnum} from "../enums/content-type.enum";
import {BaseContentModel} from "./base.content-model";

export class TextContentModel extends BaseContentModel {

    public fr: string;

    public en: string;

    constructor(options?: Partial<TextContentModel>) {
        super(ContentTypeEnum.Text);

        this.fr = options?.fr ?? "";
        this.en = options?.en ?? "";
    }
}
