import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  ValidateIf,
  ValidateNested,
  IsString,
  IsInt, IsOptional
} from "@pristine-ts/class-validator";
import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import { advancedFormArray } from "../../../decorators/advanced-form-array.decorator";
import { v4 as uuid } from 'uuid';
import { RolloverSharesClassShareholderDistributionOptions } from "./rollover-shares-class-shareholder-distribution.options";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-shares-class-options")
export class RolloverSharesClassOptions {
  @advancedFormControl({
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "Nom de la catégorie d'actions",
      en: "Name of the share class"
    },
    tooltip: {
      fr: "Ce champ représente le nom de la catégorie d'actions. Il identifie la classe d'actions spécifique à laquelle les actions appartiennent. Ce nom est utilisé pour des fins de référence et de suivi des droits associés à cette classe d'actions.",
      en: "This field represents the name of the share class. It identifies the specific class of shares to which the shares belong. This name is used for reference and tracking purposes of the rights associated with this share class."
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  title: string;

  // Property that only exists because it is used in legal.
  // We decided to simplify isRightToResidual = isRightToParticipate if not provided.
  @IsOptional()
  isRightToResidual?: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Droit de vote des actions?",
      en: "Do the shares have voting rights?"
    },
    tooltip: {
      fr: "Ce champ indique si les actions ont le droit de vote. Si activé, les actionnaires de cette classe d'actions ont le pouvoir de voter sur certaines décisions importantes de l'entreprise. Si désactivé, ils n'ont pas ce droit.",
      en: "This field indicates whether the shares have voting rights. If enabled, the shareholders of this share class have the power to vote on certain significant decisions of the company. If disabled, they do not have this right."
    },
    fieldType: FieldTypeEnum.Boolean,
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isRightToVote: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Droit de participation des actions?",
      en: "Do the shares have participation rights?"
    },
    tooltip: {
      fr: "Ce champ indique si les actions ont le droit de participation. Si activé, les actionnaires de cette classe d'actions ont le droit de participer aux bénéfices de l'entreprise sous forme de dividendes ou de distributions de capital. Si désactivé, ils n'ont pas ce droit.",
      en: "This field indicates whether the shares have participation rights. If enabled, the shareholders of this share class have the right to participate in the company's profits in the form of dividends or capital distributions. If disabled, they do not have this right."
    },
    fieldType: FieldTypeEnum.Boolean,
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isRightToParticipate: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de rachat par action",
      en: "Redemption value per share"
    },
    tooltip: {
      fr: "Ce champ représente la valeur de rachat par action si les actions de cette classe ne donnent pas droit à la participation. Il s'agit du montant auquel l'actionnaire peut racheter chaque action. Ce montant est utilisé pour déterminer le coût de rachat si l'actionnaire décide de vendre ses actions.",
      en: "This field represents the redemption value per share if the shares of this class do not have participation rights. It is the amount at which the shareholder can redeem each share. This value is used to determine the redemption cost if the shareholder decides to sell their shares."
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o =>
      o.isRightToParticipate === false
  )
  redemptionValue?: number;

  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 0,
    elementClassType: RolloverSharesClassShareholderDistributionOptions,
  })
  @ValidateNested()
  shareholdersDistribution: RolloverSharesClassShareholderDistributionOptions[] = [];
}

