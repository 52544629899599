import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {CardsContentResources} from "./cards.content-resources";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";
import {CardContentModel} from "../models/card.content-model";

export const CalculationsContentResources: {
    CalculationsBodyFeatures: CardContentModel[]
} & ContentResourceInterface & { [id in string]: any } = {

    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "Nos outils fiscaux", "en": "Calculations"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Découvrez notre gamme complète d'outils de gestion fiscale performants proposés par Optitax. Explorez une  variété d'outils puissants conçus pour simplifier les calculs fiscaux complexes, des situations de base telles  que la vente d'actions aux scénarios plus élaborés comme la détention personnelle ou corporative de véhicules.  Avec Optitax, vous pouvez améliorer votre efficacité. Profitez de notre interface conviviale et de nos  fonctionnalités robustes pour optimiser vos processus de planification fiscale et de conformité. Donnez à  votre cabinet comptable les outils avancés offerts par Optitax.\n",
                "en": "Explore our comprehensive suite of powerful tax management tools offered by Optitax. Discover a wide range of  tax tools designed to simplify complex tax calculations, from basic scenarios like stock  selling to intricate situations such as personal or corporate vehicle ownership. With Optitax, you can  streamline your tax workflow and enhance your efficiency. Take advantage of our user-friendly interface and  robust features to optimize your tax planning and compliance processes. Empower your tax practice with the  advanced tools and capabilities provided by Optitax. Start leveraging our cutting-edge solutions today.\n"
            }
        ),

        Keywords: new TextContentModel(
            {
                "fr": "gestion fiscale, outils fiscaux, calculateurs fiscaux, roulement, vente d'actions, détention personnelle, détention corporative, crédits d'impôt, déduction des dépenses d'affaires, planification fiscale, conformité fiscale, efficacité fiscale, interface conviviale, solutions fiscales, expertise fiscale",
                "en": "tax management, rollover, stocks selling, vehicle ownership, tax tools, tax calculators, share buyback, personal ownership, corporate ownership, tax credits, business expense deduction, tax planning, tax compliance, tax efficiency, user-friendly interface, tax solutions, tax expertise"
            }
        ),
    },

    "calculations.body.hero.title": new TextContentModel(
        {"fr": "Nos outils fiscaux", "en": "Our tax tools"}
    ),

    "calculations.body.hero.subtitle": new TextContentModel(
        {
            "fr": "Optitax offre une foule d’outils de calculs fiscaux qui vous feront économiser temps et argent.",
            "en": "Optitax offers a wide range of tax tools that will save you time and money."
        }
    ),

    CalculationsBodyFeatures: [
        CardsContentResources.CarOwnership,
        CardsContentResources.SalaryDividends,
        CardsContentResources.Rollover,
        CardsContentResources.SharesSelling,
    ]

}
