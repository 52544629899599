import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import {IsBoolean, IsNotEmpty, IsInt, ValidateIf} from "@pristine-ts/class-validator";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-property-transferred-section-84_1-options")
export class RolloverPropertyTransferredSection84_1Options {
  @advancedFormControl({
    labelTitle: {
      fr: "Actions font-elles partie du capital-actions d'une entreprise canadienne?",
      en: "Are the shares part of the share capital of a Canadian company?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean,
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  areTransferredSharesCanadianShares: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Actions cédées sont-elles des immobilisations pour le vendeur?",
      en: "Are the transferred shares capital property for the seller?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  areTransferredSharesCapitalPropertyForTheSeller: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Vendeur est-il un particulier ou une fiducie?",
      en: "Is the seller an individual or a trust?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isSellerAnIndividualOrATrust: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Vendeur réside-t-il au Canada?",
      en: "Is the seller a Canadian resident?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isSellerACanadianResident: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Acheteur est-il une société?",
      en: "Is the buyer a corporation?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isBuyerACorporation: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Acheteur et vendeur ont-ils un lien de dépendance?",
      en: "Are the buyer and seller at arm's length?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  areBuyerAndSellerAtArmsLength: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "La société dont les actions sont vendues est-elle rattachée à l'acheteur après la transaction?",
      en: "Is the corporation for which shares are being sold becoming at arm's length immediately after the transaction?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Les actions ont-elles été acquises après 1971?",
      en: "Were the shares acquired after 1971?"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o =>
      o.areTransferredSharesCanadianShares &&
      o.areTransferredSharesCapitalPropertyForTheSeller &&
      o.isSellerAnIndividualOrATrust &&
      o.isSellerACanadianResident &&
      o.isBuyerACorporation &&
      o.areBuyerAndSellerAtArmsLength &&
      o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction
  )
  wereSharesAcquiredAfter1971: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Juste valeur marchande des actions au jour de l'évaluation",
      en: "Fair market value of the shares on the valuation day"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o =>
      o.areTransferredSharesCanadianShares &&
      o.areTransferredSharesCapitalPropertyForTheSeller &&
      o.isSellerAnIndividualOrATrust &&
      o.isSellerACanadianResident &&
      o.isBuyerACorporation &&
      o.areBuyerAndSellerAtArmsLength &&
      o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction &&
      o.wereSharesAcquiredAfter1971 === false
  )
  fairMarketValueOnValuationDayOfTransferredShares: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Coût effectif des actions au 1er janvier 1972 selon RAIR 26(13)",
      en: "Actual cost of the shares on January 1, 1972, according to RAIR 26(13)"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o =>
      o.areTransferredSharesCanadianShares &&
      o.areTransferredSharesCapitalPropertyForTheSeller &&
      o.isSellerAnIndividualOrATrust &&
      o.isSellerACanadianResident &&
      o.isBuyerACorporation &&
      o.areBuyerAndSellerAtArmsLength &&
      o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction &&
      o.wereSharesAcquiredAfter1971 === false
  )
  actualCostOnJanuary1972OfTransferredShares: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Dividendes reçus avec choix du par. 83(1) sur les actions cédées",
      en: "Dividends received with a choice under paragraph 83(1) on the transferred shares"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o =>
      o.areTransferredSharesCanadianShares &&
      o.areTransferredSharesCapitalPropertyForTheSeller &&
      o.isSellerAnIndividualOrATrust &&
      o.isSellerACanadianResident &&
      o.isBuyerACorporation &&
      o.areBuyerAndSellerAtArmsLength &&
      o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction &&
      o.wereSharesAcquiredAfter1971 === false
  )
  receivedDividendChoice83OnTransferredShares: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Gains en capital déclarés après 1984 sur l'action par le contribuable ou un particulier avec qui le contribuable avait un lien de dépendance et qui ont donné droit à la DGC, ou sur une action substituée",
      en: "Capital gains declared after 1984 on the share by the taxpayer or an individual with whom the taxpayer was not at arm's length and that entitled to the DGC, or on a substituted share"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o =>
      o.areTransferredSharesCanadianShares &&
      o.areTransferredSharesCapitalPropertyForTheSeller &&
      o.isSellerAnIndividualOrATrust &&
      o.isSellerACanadianResident &&
      o.isBuyerACorporation &&
      o.areBuyerAndSellerAtArmsLength &&
      o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction
  )
  capitalGainsOnTheShareDeclaredAfter1984: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Frais de vente",
      en: "Selling fees"
    },
    tooltip: {
      fr: "Tooltip",
      en: "Tooltip",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf(o =>
      o.areTransferredSharesCanadianShares &&
      o.areTransferredSharesCapitalPropertyForTheSeller &&
      o.isSellerAnIndividualOrATrust &&
      o.isSellerACanadianResident &&
      o.isBuyerACorporation &&
      o.areBuyerAndSellerAtArmsLength &&
      o.isCorporationForWhichSharesAreBeingSoldBecomingAtArmsLengthImmediatelyAfterTransaction
  )
  sellingFees: number;
}
