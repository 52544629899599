import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {plainToInstance} from "class-transformer";
import {DataMapper, AutoDataMappingBuilderOptions, AutoDataMappingBuilder} from "@pristine-ts/data-mapping-common";

export const TransformerClassType = new HttpContextToken<any>(() => {
});


@Injectable()
export class TransformerHttpInterceptor implements HttpInterceptor {
    constructor(private readonly dataMapper: DataMapper) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            switchMap(async event => {
                if (event instanceof HttpResponse && req.context.has(TransformerClassType)) {
                    const body = await this.dataMapper.autoMap(event.body, req.context.get(TransformerClassType), new AutoDataMappingBuilderOptions({isOptionalDefaultValue: true,}));
                    event = event.clone({
                        body,
                    });
                }

                return event;
            })
        );
    }
}