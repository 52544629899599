import {ErrorHandler as BaseErrorHandler, Injectable} from '@angular/core';
import * as Sentry from "@sentry/angular";

@Injectable()
export class ErrorHandler implements BaseErrorHandler {
    private readonly sentryErrorHandler = Sentry.createErrorHandler({
        showDialog: false,
    });

    constructor() {
    }

    handleError(error: any): void {
        this.sentryErrorHandler.handleError(error);
    }
}
