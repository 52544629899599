import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import {PricingContentResources} from "../../content/resources/pricing.content-resources";
import {LocaleManager} from "../../managers/locale.manager";
import {LocaleEnum} from "../../enums/locale.enum";
import {isPlatformBrowser} from "@angular/common";
import {RouteManager} from "../../managers/route.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";
import {BaseComponent} from "../../components/base.component";

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrl: './pricing.component.scss'
})
export class PricingComponent extends BaseComponent implements AfterViewInit {

    ngAfterViewInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
          // @ts-ignore
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }

    constructor(
        localeManager: LocaleManager,
        routeManager: RouteManager,
        contentManager: ContentManager,
        titleService: Title,
        meta: Meta,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        super(localeManager,
            routeManager,
            contentManager,
            titleService,
            meta,
            PricingContentResources,
        )
    }

    getLocale(): LocaleEnum {
        return this.localeManager.getCurrentLocale();
    }

  protected readonly PricingContentResources = PricingContentResources;
    protected readonly LocaleEnum = LocaleEnum;
}
