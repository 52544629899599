import {Inject, Injectable} from "@angular/core";
import { HttpClient, HttpContext } from "@angular/common/http";
import {DataMapper} from "@pristine-ts/data-mapping-common";

@Injectable()
export class RampUpApiController {
    constructor(private readonly httpClient: HttpClient,
                private readonly dataMapper: DataMapper,
                @Inject("environment.api.url") private readonly apiUrl: string) {
    }

    public async rampUp(): Promise<void> {
        return this.httpClient.post<void>(`${this.apiUrl}/api/public/ramp-up`, {}, {}).toPromise();
    }
}
