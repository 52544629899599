import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {CardsContentResources} from "./cards.content-resources";
import {CardContentModel} from "../models/card.content-model";

export const FooterContentResources = {


    "footer.main.title": new TextContentModel(
        {"fr": "Automatisez la fiscalité de vos clients", "en": "Automate your clients' taxation needs"}
    ),

    "footer.main.subtitle": new TextContentModel(
        {
            "fr": "Donnez enfin des réponses précises aux questions fiscales de vos clients.",
            "en": "Provide precise answers to tax questions"
        }
    ),

    "footer.main.contact-us": new TextContentModel(
        {"fr": "Contactez-nous", "en": "Contact us"}
    ),

    "footer.main.support": new TextContentModel(
        {"fr": "Centre d'aide", "en": "Support center"}
    ),

    "footer.main.students": new TextContentModel(
        {"fr": "Gratuit pour étudiants", "en": "Free for students"}
    ),

    "footer.main.about-us": new TextContentModel(
        {"fr": "À propos / Notre équipe", "en": "About us / Our Team"}
    ),

    "footer.main.security": new TextContentModel(
        {"fr": "Sécurité et confidentialité", "en": "Security and confidentiality"}
    ),

    "footer.main.get-started": new TextContentModel(
        {"fr": "Débutez gratuitement maintenant!", "en": "Start for free now"}
    ),

    "footer.main.terms-and-conditions": new TextContentModel(
        {"fr": "Conditions d'utilisation", "en": "Terms and conditions"}
    ),

    "footer.main.calculations-title": new TextContentModel(
        {"fr": "Outils fiscaux", "en": "Tax Tools"}
    ),

    Calculations: [
        CardsContentResources.SalaryDividends,
        CardsContentResources.CarOwnership,
        CardsContentResources.Rollover,
        CardsContentResources.SharesSelling,
    ]
}
