import {Injectable} from "@angular/core";
import {LocaleManager} from "../../managers/locale.manager";
import {BaseContentModel} from "../models/base.content-model";
import {ContentTypeEnum} from "../enums/content-type.enum";
import {TextContentModel} from "../models/text.content-model";
import {TextRenderer} from "../renderers/text.renderer";
import {MarkdownRenderer} from "../renderers/markdown.renderer";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {HtmlRenderer} from "../renderers/html.renderer";
import {HtmlContentModel} from "../models/html.content-model";

@Injectable()
export class ContentManager {
    constructor(
        private readonly localeManager: LocaleManager,
        private readonly textRenderer: TextRenderer,
        private readonly markdownRenderer: MarkdownRenderer,
        private readonly htmlRenderer: HtmlRenderer,
    ) {
    }

    async render(contentModel: BaseContentModel, options?: any): Promise<string> {

        switch (contentModel.type) {
            case ContentTypeEnum.Text:
                return this.textRenderer.render(contentModel as TextContentModel);
            case ContentTypeEnum.Image:
                return "Use the `app-image` component to render an image."
            case ContentTypeEnum.Markdown:
                return this.markdownRenderer.render(contentModel as MarkdownContentModel);
            case ContentTypeEnum.Html:
                return this.htmlRenderer.render(contentModel as HtmlContentModel)
        }
    }
}
