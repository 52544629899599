import {Inject, Injectable} from "@angular/core";

@Injectable()
export class DebugUtils {
    public constructor(@Inject("environment.is-debug") private readonly isDebugFlagSetInEnvironment: any) {
    }

    isDebugActive?: boolean = undefined;

    isDebug(): boolean {
        if (this.isDebugActive) {
            return this.isDebugActive;
        }

        const isDebug: string | null = window.localStorage.getItem("is-debug");

        if (isDebug !== null) {
            this.isDebugActive = isDebug === "true";
            return this.isDebugActive;
        }

        this.isDebugActive = this.isDebugFlagSetInEnvironment;

        return this.isDebugActive ?? false;
    }

    activateDebugMode() {
        window.localStorage.setItem("is-debug", "true");

        this.isDebugActive = true;
    }

    deactivateDebugMode() {
        window.localStorage.setItem("is-debug", "false");

        this.isDebugActive = false;
    }
}