<app-header>
    <div class="row">
        <div class="col-xs-12 col-lg-7">
            <h1 class="display-4 mt-5 text-center text-lg-start">{{ StudentsContentResources["students.body.hero.title"] | render | async }}</h1>


            <!-- Devices -->
            <div class="d-lg-none d-xl-none d-xxl-none mt-3">
                <app-image url="images/illustrations/students.svg" [fetchPriority]="FetchPriorityEnum.High"
                           [loading]="LoadingEnum.Eager"></app-image>
            </div>
            <!-- End Devices -->

            <div class="hero-description text-light text-center text-lg-start mt-5">{{ StudentsContentResources["students.body.hero.subtitle"] | render | async }}</div>
            <div class="call-to-action mt-5 mb-8">
                <a [routerLink]="routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted)"
                   class="btn btn-warning btn-lg d-block"
                   style="font-size: 1.25rem">{{ StudentsContentResources["students.body.hero.call-to-action"] | render | async }}</a>
            </div>

        </div>


        <div class="col-lg-5 d-none d-lg-block d-xl-block">
            <app-image url="images/illustrations/students.svg" [fetchPriority]="FetchPriorityEnum.High"
                       [loading]="LoadingEnum.Eager"></app-image>
        </div>
    </div>
</app-header>
<div class="container">
        <div class="faq-title mt-5">
            <h2 class="text-center display-6">{{ StudentsContentResources.FrequentlyAskedQuestionsTitle | render | async }}</h2>
        </div>

        <div class=" content-space-2 content-space-lg-3 mt-5 faq">

            <div class="row justify-content-md-center">
                @for(frequentlyAskedQuestion of StudentsContentResources.FrequentlyAskedQuestions; track $index) {
                    <div class="col-12">
                        <h5 class=" mt-3">{{ frequentlyAskedQuestion.question | render | async }}</h5>
                        <div class="text-gray-700" [innerHTML]="frequentlyAskedQuestion.answer | render | async">
                        </div>
                    </div>
                }


            </div>
            <!-- End Row -->
        </div>
        <!-- End FAQ -->
    </div>
