import { CalculationRunOptions } from "../calculation-run.options";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { CalculationKeynameEnum } from "../../../enums/calculations/calculation-keyname.enum";
import {FieldTypeEnum} from "../../../enums/field-type.enum";
import {IsBoolean, IsNotEmpty, IsInt, Max, Min, IsDate, validateIf} from "@pristine-ts/class-validator";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";
import {translationBaseKey} from "../../../decorators/translation-base-key.decorator";

@translationBaseKey("salary-contributions-in-salary-dividend-options")
export class SalaryContributionsOptionsInSalaryDividendOptions extends CalculationRunOptions {
  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.SalaryContributions;

  @advancedFormControl({
    labelTitle: {
      fr: "Masse salariale totale (incluant les indemnités pour accident du travail non remboursées)",
      en: "Total payroll (including unreimbursed workers' compensation benefits)"
    },
    tooltip: {
      fr: "Ce champ représente la masse salariale totale de l'employeur, y compris les indemnités pour accident du travail non remboursées. La masse salariale totale est utilisée pour calculer les cotisations aux régimes sociaux et les impôts sur le revenu des employés.",
      en: "This field represents the total payroll of the employer, including unreimbursed workers' compensation benefits. The total payroll is used to calculate contributions to social programs and income taxes for employees."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object:  SalaryContributionsOptionsInSalaryDividendOptions, root, currentPath): boolean => {
    return object.shouldFSSBeCalculated === true;
  })
  totalPayroll?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Masse salariale totale se rapportant à des activités des secteurs primaire et manufacturier",
      en: "Total payroll related to primary and manufacturing sectors activities"
    },
    tooltip: {
      fr: "Ce champ représente la masse salariale totale se rapportant aux activités des secteurs primaire et manufacturier de l'employeur. Elle est utilisée pour des évaluations spécifiques ou des avantages fiscaux liés à ces secteurs d'activité.",
      en: "This field represents the total payroll related to the primary and manufacturing sectors activities of the employer. It is used for specific assessments or tax benefits related to these business sectors."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object:  SalaryContributionsOptionsInSalaryDividendOptions, root, currentPath): boolean => {
    return object.shouldFSSBeCalculated === true;
  })
  totalPayrollForPrimarySector?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "L'employeur est-il du secteur public?",
      en: "Is the employer from the public sector?"
    },
    tooltip: {
      fr: "Ce champ indique si l'employeur est du secteur public. Le secteur public comprend les organismes gouvernementaux, les sociétés d'État et les administrations publiques. Cette information est pertinente pour certaines déductions ou crédits d'impôt applicables aux employeurs du secteur public.",
      en: "This field indicates whether the employer is from the public sector. The public sector includes government agencies, state-owned enterprises, and public administrations. This information is relevant for certain deductions or tax credits applicable to employers in the public sector."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object:  SalaryContributionsOptionsInSalaryDividendOptions, root, currentPath): boolean => {
    return object.shouldFSSBeCalculated === true;
  })
  isEmployerFromPublicSector?: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Taux de versement périodique",
      en: "Recurring payment rate"
    },
    tooltip: {
      fr: "Ce champ représente le taux de versement périodique applicable à certains types de revenus, tels que les rentes ou les paiements échelonnés. Le taux de versement périodique est utilisé pour calculer le montant des paiements récurrents.",
      en: "This field represents the recurring payment rate applicable to certain types of income, such as annuities or installment payments. The recurring payment rate is used to calculate the amount of recurring payments."
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @Min(0, {buildErrorMessage: ErrorMessageBuilder.build})
  @Max(100, {buildErrorMessage: ErrorMessageBuilder.build})
  @validateIf((object: SalaryContributionsOptionsInSalaryDividendOptions, root, currentPath): boolean => {
    return object.shouldCNESSTBeCalculated === true;
  })
  recurringPaymentRate?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation FSS de l'employé et de l'employeur?",
      en: "Calculate employee and employer's FSS contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation au Fonds des services de santé (FSS) doit être calculée pour l'employé et l'employeur. Le FSS est un fonds qui finance les services de santé au Québec.",
      en: "This field indicates whether the contribution to the Health Services Fund (FSS) should be calculated for the employee and the employer. The FSS is a fund that finances health services in Quebec."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldFSSBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation CNT de l'employé et de l'employeur?",
      en: "Calculate employee and employer's CNT contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation au Comité paritaire de l'industrie de la construction (CNT) doit être calculée pour l'employé et l'employeur. Le CNT est un organisme paritaire qui gère les contributions pour l'industrie de la construction au Québec.",
      en: "This field indicates whether the contribution to the Joint Labor-Management Construction Committee (CNT) should be calculated for the employee and the employer. The CNT is a joint organization that manages contributions for the construction industry in Quebec."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldCNTBeCalculated: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Calculer la cotisation CNESST de l'employé et de l'employeur?",
      en: "Calculate employee and employer's CNESST contribution?"
    },
    tooltip: {
      fr: "Ce champ indique si la cotisation à la Commission des normes, de l'équité, de la santé et de la sécurité du travail (CNESST) doit être calculée pour l'employé et l'employeur. La CNESST gère les régimes d'indemnisation des travailleurs au Québec.",
      en: "This field indicates whether the contribution to the Commission des normes, de l'équité, de la santé et de la sécurité du travail (CNESST) should be calculated for the employee and the employer. The CNESST manages workers' compensation schemes in Quebec."
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shouldCNESSTBeCalculated: boolean;
}
