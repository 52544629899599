import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export type Feature = {
    title: TextContentModel;
    description: MarkdownContentModel;
    image: ImageContentModel;
}

export const SecurityContentResources: { Features: Feature[], } & ContentResourceInterface & { [id in string]: any } = {


    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "Sécurité et confidentialité", "en": "Security and confidentality"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Une entreprise québécoise qui crée des outils pour les cabinets comptables d’ici. Optitax est une entreprise québécoise pionnière dans le développement d’outils informatiques fiscaux. Nous vous permettons d’optimiser des situations fiscales complexes en quelques clics. Notre logiciel avancé effectue des dizaines de milliers de simulations pour déterminer la solution la plus optimale selon la situation précise de votre client. Optitax vous offre également l’option de télécharger les formulaires électroniques gouvernementaux remplis en bonne et due forme afin de vous faire économiser encore plus de temps. Ce n’est qu’un début. Ne tardez pas et commencez à utiliser Optitax dès maintenant.\n",
                "en": "Contact Optitax to get tailored tax solutions that suit your needs. Our experienced team is here to answer all  your questions and help you optimize your tax management. Whether you're an individual, a business, or a student,  we offer a comprehensive range of customized packages, including free solutions for students.\n"
            }
        ),

        Keywords: new TextContentModel(
            {
                "fr": "Optitax, Contactez-nous, Logiciel de gestion fiscale, Expertise fiscale, Solutions fiscales personnalisées, Assistance fiscale, Conseils fiscaux, Contactez-nous pour vos besoins fiscaux, Gestion fiscale efficace, Planification fiscale, Conformité fiscale, Services fiscaux professionnels, Optimisation fiscale, Réglementation fiscale, Support client pour la gestion fiscale, Demandez une démo gratuite, Partenariat fiscal",
                "en": "Optitax, Contact Us, Tax management software,Tax expertise,Customized tax solutions,Tax assistance,Tax advisory,Contact us for your tax needs,Efficient tax management,Tax planning,Tax compliance,Professional tax services,Tax optimization,Tax regulations,Customer support for tax management,Request a free demo,Tax partnership"
            }
        ),
    },

    "security.body.title": new TextContentModel(
        {"fr": "Sécurité et confidentialité", "en": "Security and confidentiality"}
    ),

    "security.body.subtitle": new MarkdownContentModel(
        {
            "fr": "Optitax souscrit à des normes très élévées de confidentialité et de sécurité des données. Nous employons une suite complète de mesures pour protéger les données de nos clients, en respectant les normes et les exigences réglementaires les plus élevées de l'industrie. Notre infrastructure de cybersécurité robuste comprend :\n\n**Authentification multifactorielle**: Nous offrons gratuitement l'authentification multifactorielle à tous nos clients, garantissant que seuls les individus autorisés peuvent accéder aux données sensibles. \n\n**Cryptage des données**: Toutes les données, tant au repos qu'en transit, sont cryptées à l'aide d'algorithmes standard de l'industrie, les rendant illisibles aux parties non autorisées.\n\n**Data centers sécurisés**: Nos centres de données sont situés dans des installations sécurisées avec des contrôles d'accès physiques stricts et des pare-feu avancés pour protéger contre les intrusions non autorisées.\n\n**Audits de sécurité réguliers**: Nous effectuons des audits de sécurité réguliers pour identifier et corriger les vulnérabilités potentielles, en veillant à ce que nos mesures de sécurité restent efficaces face aux menaces évolutives.\n\n**Formation des employés**: Nous fournissons une formation complète sur la sensibilisation à la sécurité à nos employés, en mettant l'accent sur l'importance de la protection des données et la manipulation appropriée des informations sensibles.\n\nChez Optitax, nous nous sommes engagés à protéger les données de nos clients. Nous accordons la plus haute importance à la confidentialité et à la sécurité des données, et nous employons une gamme complète de mesures pour protéger les informations sensibles. En respectant les normes et les exigences réglementaires les plus élevées de l'industrie, nous nous efforçons de gagner et de maintenir la confiance de nos clients.\n",
            "en": "\nAt Optitax, we recognize the utmost importance of data confidentiality and security, especially in the sensitive realm of accounting and financial information. We employ a comprehensive suite of measures to safeguard our clients' data, adhering to the highest industry standards and regulatory requirements. Our robust cybersecurity infrastructure includes:\n\n**Multi-factor authentication**: We offer multiple factor authentication for free to all our customers.\n\n**Data encryption**: All data, both at rest and in transit, is encrypted using industry-standard algorithms, rendering it unreadable to unauthorized parties.\n\n**Secure data centers**: Our data centers are located in secure facilities with stringent physical access controls and advanced firewalls to protect against unauthorized intrusion.\n\n**Regular security audits**: We conduct regular security audits to identify and address potential vulnerabilities, ensuring that our security measures remain effective against evolving threats.\n\n**Employee training**: We provide comprehensive security awareness training to our employees, emphasizing the importance of data protection and proper handling of sensitive information.\n\nAt Optitax, we are dedicated to safeguarding our clients' data. We place the utmost importance on data confidentiality and security, and we employ a comprehensive range of measures to protect sensitive information. By adhering to the highest industry standards and regulatory requirements, we strive to earn and maintain the trust of our clients.\n"
        }
    ),

    "security.body.image": new ImageContentModel(
        {
            url: new TextContentModel({
                fr: "images/illustrations/security.svg",
                en: "images/illustrations/security.svg",
            }),
        }
    ),

    Features: [
        {
            title: new TextContentModel(
                {"fr": "Confidentialité et sécurité des données", "en": "Data Confidentiality and Security"}
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "Dans le domaine de la comptabilité et des informations financières, la confidentialité et la sécurité des données sont primordiales. En tant que société de technologie qui développe des logiciels pour les cabinets comptables, nous comprenons l'importance de cette responsabilité et la prenons très au sérieux. \n\nNous employons une gamme complète de mesures pour protéger les données de nos clients, en respectant les normes et les exigences réglementaires les plus élevées de l'industrie.\n",
                    "en": "In the realm of accounting and financial information, data confidentiality and security are paramount. As a technology firm that creates software for accounting firms, we understand the weight of this responsibility and take it very seriously. \n\nWe employ a comprehensive suite of measures to safeguard our clients' data, adhering to the highest industry standards and regulatory requirements.\n"
                }
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/confidentiality_and_security.svg",
                        en: "images/illustrations/confidentiality_and_security.svg",
                    }),
                }
            )
        },
        {
            title: new TextContentModel(
                {"fr": "Protection de l'intégrité des données", "en": "Protecting Data Integrity"}
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "L'un des objectifs principaux de notre stratégie de sécurité des données est de protéger l'intégrité des données de nos clients. \n\nNous employons des mesures robustes pour garantir que les données restent exactes, cohérentes et non modifiées tout au long de leur cycle de vie. Nos systèmes sont conçus pour détecter et empêcher les modifications non autorisées, en veillant à ce que seuls les individus autorisés puissent apporter des modifications aux informations sensibles.\n",
                    "en": "One of the primary objectives of our data security strategy is to protect the integrity of our clients' data. We employ robust measures to ensure that data remains accurate, consistent, and unaltered throughout its lifecycle. \n\nOur systems are designed to detect and prevent unauthorized modifications, ensuring that only authorized individuals can make changes to sensitive information.\n"
                }
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/integrity.svg",
                        en: "images/illustrations/integrity.svg",
                    }),
                }
            ),
        },
        {
            title: new TextContentModel(
                {"fr": "Protection de la confidentialité des données", "en": "Safeguarding Data Privacy"}
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "Nous nous engageons à protéger la confidentialité des données de nos clients. Nous mettons en œuvre des contrôles d'accès stricts pour limiter l'accès aux informations sensibles aux seules personnes qui en ont besoin pour exercer leurs fonctions. \n\nNous cryptons également les données, tant au repos qu'en transit, ce qui les rend illisibles aux parties non autorisées. De plus, nous nous conformons aux principes de minimisation des données, en ne collectant que les informations nécessaires aux fins spécifiées et en les conservant pas plus longtemps que nécessaire.\n",
                    "en": "We are committed to safeguarding our clients' data privacy. We implement strict access controls to restrict access to sensitive information to only those who need it to perform their job duties. We also encrypt data both at rest and in transit, rendering it unreadable to unauthorized parties. \n\nAdditionally, we adhere to data minimization principles, only collecting the data that is necessary for the purposes specified and retaining it for no longer than necessary.\n"
                }
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/confidentiality.svg",
                        en: "images/illustrations/confidentiality.svg",
                    }),
                }
            ),
        },
        {
            title: new TextContentModel(
                {"fr": "Suivi et amélioration continus", "en": "Continuous Monitoring and Improvement"}
            ),
            description: new MarkdownContentModel(
                {
                    "fr": "Nous reconnaissons que les menaces pour la sécurité des données évoluent constamment et nous sommes engagés à rester en avance sur ces menaces. Nous surveillons en permanence nos systèmes à la recherche de vulnérabilités et mettons en œuvre les correctifs de sécurité rapidement. \n\nNous effectuons également des audits de sécurité réguliers pour identifier et adresser les risques potentiels. De plus, nous fournissons une formation complète sur la sensibilisation à la sécurité à nos employés afin qu'ils puissent comprendre et atténuer les risques de sécurité des données.\n",
                    "en": "We recognize that data security threats are constantly evolving, and we are committed to staying ahead of these threats. We continuously monitor our systems for vulnerabilities and implement security patches promptly. We also conduct regular security audits to identify and address potential risks. \n\nAdditionally, we provide comprehensive security awareness training to our employees to help them understand and mitigate data security risks.\n"
                }
            ),
            image: new ImageContentModel(
                {
                    url: new TextContentModel({
                        fr: "images/illustrations/infinity.png",
                        en: "images/illustrations/infinity.png",
                    }),
                }
            ),
        }
    ]

}
