import {Injectable} from "@angular/core";
import {ActivatedRoute, Router, Routes} from "@angular/router";
import {LocaleEnum} from "../enums/locale.enum";
import {LocaleManager} from "./locale.manager";
import {TextContentModel} from "../content/models/text.content-model";
import {RouteEnum} from "../enums/route.enum";
import {HomeComponent} from "../pages/home/home.component";
import {CalculationLocalizedRoute, LocalizedRoute, routesDictionary, SupportLocalizedRoute} from "../app.routes";
import {CalculationKeynameEnum} from "../../../../../../common/src/enums/calculations/calculation-keyname.enum";
import {SupportArticleEnum} from "../content/enums/support-article.enum";

@Injectable({
    providedIn: "root",
})
export class RouteManager {
    private currentRoute: RouteEnum = RouteEnum.Home;

    private additionalParameters: { articleKeyname: SupportArticleEnum } | {
        calculationKeyname: CalculationKeynameEnum
    } | undefined;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly localeManager: LocaleManager,
    ) {
    }

    setCurrentRoute(route: RouteEnum, additionalParameters?: { articleKeyname: SupportArticleEnum } | {
        calculationKeyname: CalculationKeynameEnum
    }) {
        this.currentRoute = route;
        this.additionalParameters = additionalParameters;
    }

    getCurrentRouteInLocale(locale: LocaleEnum): string {
        return this.getRouteInLocale(this.currentRoute, locale, this.additionalParameters);
    }

    getCurrentRouteInCurrentLocale(): string {
        return this.getCurrentRouteInLocale(this.localeManager.getCurrentLocale());
    }

    getRouteInLocale(routeId: RouteEnum, locale: LocaleEnum, additionalParameters?: {
        articleKeyname: SupportArticleEnum
    } | { calculationKeyname: CalculationKeynameEnum }): string {
        const route = routesDictionary[routeId];

        let path: string = "/";

        switch (routeId) {
            case RouteEnum.Calculation:
                if (!additionalParameters || !("calculationKeyname" in additionalParameters)) {
                    return path;
                }
                path += (route as CalculationLocalizedRoute).calculations[additionalParameters.calculationKeyname]?.paths[locale];

                break;

            case RouteEnum.SupportArticle:
                if (!additionalParameters || !("articleKeyname" in additionalParameters)) {
                    return "/";
                }
                path += (route as SupportLocalizedRoute).articles[additionalParameters.articleKeyname]?.paths[locale];
                break;

            default:
                path += (route as LocalizedRoute).paths?.[locale];
        }

        return path;
    }

    getRouteInCurrentLocale(routeId: RouteEnum, additionalParameters?: { articleKeyname: SupportArticleEnum } | {
        calculationKeyname: CalculationKeynameEnum
    }): string {
        return this.getRouteInLocale(routeId, this.localeManager.getCurrentLocale(), additionalParameters);
    }

    getRouteInCurrentLocaleForTextModel(textModel?: TextContentModel): string {
        if(!textModel) {
            return "";
        }
        
        // todo: verify that this is indeed a RouteEnum.
        return this.getRouteInCurrentLocale(textModel[this.localeManager.getCurrentLocale()] as RouteEnum);
    }
}
