import { Component } from '@angular/core';
import {AboutUsContentResources} from "../../content/resources/about-us.content-resources";
import {RouteManager} from "../../managers/route.manager";
import {RouteEnum} from "../../enums/route.enum";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {LoadingEnum} from "../../enums/loading.enum";
import {BaseComponent} from "../../components/base.component";
import {ContentResourceInterface} from "../../content/interfaces/content-resource.interface";
import {LocaleManager} from "../../managers/locale.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent extends BaseComponent {

  protected readonly AboutUsContentResources = AboutUsContentResources;

  constructor(
      localeManager: LocaleManager,
      routeManager: RouteManager,
      contentManager: ContentManager,
      titleService: Title,
      meta: Meta,
  ) {
    super(
        localeManager,
        routeManager,
        contentManager,
        titleService,
        meta,
        AboutUsContentResources,
    )
  }

  protected readonly RouteEnum = RouteEnum;
  protected readonly FetchPriorityEnum = FetchPriorityEnum;
  protected readonly LoadingEnum = LoadingEnum;
}
