import { translationBaseKey } from "../../../../../decorators/translation-base-key.decorator";
import { advancedFormControl } from "../../../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../../../enums/field-type.enum";
import { IsInt, IsNotEmpty, ValidateNested } from "@pristine-ts/class-validator";
import { ErrorMessageBuilder } from "../../../../../builders/error-message.builder";
import { advancedFormArray } from "../../../../../decorators/advanced-form-array.decorator";
import { IncomeTaxPersonalTaxableDividendOptions } from "./../income-tax-personal-taxable-dividend.options";
import {advancedFormGroup} from "../../../../../decorators/advanced-form-group.decorator";
import {type} from "@pristine-ts/data-mapping-common";

@translationBaseKey("income-tax-personal-quebec-person-income-tax-personal-federal-person-options")
export class IncomeTaxPersonalQuebecPersonIncomeTaxPersonalFederalPersonOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de la ligne 12000 dans la déclaration fédérale",
      en: "Value of line 12000 on the federal income tax"
    },
    tooltip: {
      fr: "Indiquez la valeur de la ligne 12000 de la déclaration de revenus fédérale. La ligne 12000 correspond au montant total du revenu imposable fédéral.",
      en: "Specify the value of line 12000 on the federal income tax return. Line 12000 corresponds to the total amount of federal taxable income.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  line12000: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de la ligne 12700 dans la déclaration fédérale",
      en: "Value of line 12700 on the federal income tax"
    },
    tooltip: {
      fr: "Indiquez la valeur de la ligne 12700 de la déclaration de revenus fédérale. La ligne 12700 correspond au montant total des déductions fédérales.",
      en: "Specify the value of line 12700 on the federal income tax return. Line 12700 corresponds to the total amount of federal deductions.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  line12700: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de la ligne 13000 dans la déclaration fédérale",
      en: "Value of line 13000 on the federal income tax"
    },
    tooltip: {
      fr: "Indiquez la valeur de la ligne 13000 de la déclaration de revenus fédérale. La ligne 13000 correspond au revenu net fédéral.",
      en: "Specify the value of line 13000 on the federal income tax return. Line 13000 corresponds to the federal net income.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  line13000: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de la ligne 20800 dans la déclaration fédérale",
      en: "Value of line 20800 on the federal income tax"
    },
    tooltip: {
      fr: "Indiquez la valeur de la ligne 20800 de la déclaration de revenus fédérale. La ligne 20800 correspond au montant total de l'impôt fédéral retenu.",
      en: "Specify the value of line 20800 on the federal income tax return. Line 20800 corresponds to the total amount of federal tax withheld.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  line20800: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de la ligne 22200 dans la déclaration fédérale",
      en: "Value of line 22200 on the federal income tax"
    },
    tooltip: {
      fr: "Indiquez la valeur de la ligne 22200 de la déclaration de revenus fédérale. La ligne 22200 correspond au montant total des prestations fiscales pour enfants fédérales.",
      en: "Specify the value of line 22200 on the federal income tax return. Line 22200 corresponds to the total amount of federal child tax benefits.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  line22200: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de la ligne 22215 dans la déclaration fédérale",
      en: "Value of line 22215 on the federal income tax"
    },
    tooltip: {
      fr: "Indiquez la valeur de la ligne 22215 de la déclaration de revenus fédérale. La ligne 22215 correspond au montant total des prestations universelles pour la garde d'enfants fédérales.",
      en: "Specify the value of line 22215 on the federal income tax return. Line 22215 corresponds to the total amount of federal universal child care benefits.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  line22215: number;

  @type(() => new IncomeTaxPersonalTaxableDividendOptions())
  @advancedFormGroup({
    classType: IncomeTaxPersonalTaxableDividendOptions,
  })
  @ValidateNested()
  taxableDividend: IncomeTaxPersonalTaxableDividendOptions;
}