import {IsBoolean, IsNotEmpty, IsInt, ValidateIf, ValidateNested, IsString} from "@pristine-ts/class-validator";
import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import {RolloverOptions} from "./rollover.options";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-property-transferred-non-share-consideration-options")
export class RolloverPropertyTransferredNonShareConsiderationOptions {

  @advancedFormControl({
    labelTitle: {
      fr: "Contrepartie autre qu'en actions du bien",
      en: "Non-share consideration of the property"
    },
    tooltip: {
      fr: "Ce champ représente la valeur totale de la contrepartie autre qu'en actions du bien transféré. La contrepartie non monétaire comprend toutes les formes de paiement autres que des actions, telles que des biens, des services ou des créances. Cette valeur est importante pour évaluer la transaction et déterminer les implications fiscales liées au roulement de propriété.",
      en: "This field represents the total value of the non-share consideration of the transferred property. Non-share consideration includes all forms of payment other than shares, such as property, services, or debts. This value is important for evaluating the transaction and determining the tax implications related to the property rollover."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf((object, root: RolloverOptions, currentPath) => {
    if (!currentPath) {
      return false;
    }
    // Find the first string that is a number.
    const index = currentPath.match(/[0-9]+/gm)?.shift();

    // Validate that we found an index that is a number.
    if (!index || isNaN(+index)) {
      return false;
    }
    if (root.propertiesTransferred.length <= +index) {
      return false;
    }
    return root.propertiesTransferred[+index].isCalculateMaximumNonShareConsideration === false;
  })
  amountOfNonShareConsideration?: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Identifiant de la contrepartie autre qu'en action?",
      en: "Non share consideration ID"
    },
    tooltip: {
      fr: "Ce champ représente l'identifiant unique de la contrepartie autre qu'en actions. Il est utilisé pour identifier spécifiquement cette contrepartie dans le cadre du roulement de propriété. Cet identifiant est important pour assurer une correspondance appropriée entre le bien transféré et la contrepartie non monétaire associée.",
      en: "This field represents the unique identifier of the non-share consideration. It is used to specifically identify this non-share consideration in the context of the property rollover. This identifier is important to ensure proper matching between the transferred property and the associated non-share consideration."
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  nonShareConsiderationId: string;

  // @formElement({
  //   labelTitle: {
  //     fr: "Quel est le nom de la contrepartie autre qu'en actions?",
  //     en: ""
  //   },
  //   tooltip: {
  //     fr: "Tooltip"
  //   },
  //   fieldType: FieldTypeEnum.String
  // })
  // @IsString()
  // @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  // title: string;
}
