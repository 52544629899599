<app-header [headerStyle]="HeaderStyleEnum.HomePage">
    <div class="row">
        <div class="col-xs-12 col-lg-6">
            <h1 class="display-4 mt-5 text-center text-lg-start text-white">{{ IndexContentResources["index.body.hero.title"] | render | async }}</h1>

            <!-- Devices -->
            <div class="d-lg-none d-xl-none d-xxl-none mt-3">
                <app-image [url]="'images/illustrations/hero-illustration.png'"
                           [fetchPriority]="FetchPriorityEnum.High"
                           [loading]="LoadingEnum.Eager"
                ></app-image>

            </div>
            <!-- End Devices -->

            <div class="hero-description text-light text-center text-lg-start"
                 [innerHTML]="IndexContentResources['index.body.hero.subtitle'] | render | async"></div>
            <div class="call-to-action">
                <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted)"
                   class="btn btn-warning btn-lg d-block"
                   style="font-size: 1.25rem">{{ KeywordsContentResources["keywords.start-for-free-now"] | render | async }}</a>
            </div>

        </div>


        <div class="col-lg-6 d-none d-lg-block d-xl-block">
            <app-image [url]="'images/illustrations/hero-illustration.png'"
                       [fetchPriority]="FetchPriorityEnum.High"
                       [loading]="LoadingEnum.Eager"
            ></app-image>
        </div>
    </div>
</app-header>

<div class="second-section">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-md-10 offset-md-1">
                <div class="card text-center">
                    <div class="card-body">
                        <h2></h2>
                        <h2 class="card-title">{{ IndexContentResources["index.body.second-section.title"] | render | async }}</h2>
                        <div class="card-text"
                             [innerHTML]="IndexContentResources['index.body.second-section.description'] | render | async"></div>

                    </div>
                    <div class="card-footer">
                        <a class="btn btn-primary"
                           [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted)">{{ IndexContentResources["index.body.second-section.call-to-action"] | render | async }}
                            <font-awesome-icon icon="fas fa-long-arrow-right ms-5"></font-awesome-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row features">
            @for (feature of IndexContentResources.IndexBodyFeatures; track $index) {
                <div class="col-xs-12 col-md-6 text-center">

                    <app-image class="icon-feature"
                               [imageContentModel]="feature.image"
                               [fetchPriority]="FetchPriorityEnum.Low"
                               [loading]="LoadingEnum.Lazy"
                    ></app-image>

                    <h4> {{ feature.title | render | async }}</h4>
                    <p style="font-size:1.25rem;">{{ feature.description | render | async }}</p>
                </div>
            }
        </div>
    </div>
</div>

<div class="third-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="text-center">{{ IndexContentResources["index.body.third-section.title"] | render | async }}</h2>
            </div>

            @for (feature of IndexContentResources.BodyThirdSectionFeatures; track $index) {
                <div class="row calculation">
                    <div class="col-xs-12 col-md-6" [class.order-md-1]="$even" [class.order-md-2]="!$even">

                        <app-image
                                [imageContentModel]="feature.image"
                                [fetchPriority]="FetchPriorityEnum.Low"
                                [loading]="LoadingEnum.Lazy"
                                [class]="'img-fluid calculation-img'"
                        ></app-image>

                    </div>

                    <div class="col-xs-12 col-md-6"
                         [class.order-md-2]="$even" [class.order-md-1]="!$even">
                        <h4>{{ feature.title | render | async }}</h4>
                        <div [innerHTML]="feature.description | render | async">
                        </div>

                        <a [routerLink]="'/' + this.routeManager.getRouteInCurrentLocale(RouteEnum.Calculations) + '/' + (feature.callToAction?.calculationKeyname | render | async)">{{ feature.callToAction?.displayText | render | async }}</a>
                    </div>

                </div>

                @if ($index != IndexContentResources.BodyThirdSectionFeatures.length - 1) {
                    <hr>
                }
            }

            <p class="text-center mt-5">
                <a class="btn btn-warning d-block"
                   [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.Calculations)"><strong>{{ IndexContentResources["index.body.third-section.call-to-action"] | render | async }}</strong></a>
            </p>
        </div>
    </div>
</div>

<div class="fourth-section">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="try-it-now-badge"><span
                        class="badge rounded-pill bg-secondary">{{ IndexContentResources["index.body.fourth-section.call-to-action-2"] | render | async }}</span>
                </div>

                <h2 class="display-4 text-center">{{ IndexContentResources["index.body.fourth-section.title"] | render | async }}</h2>

                <div class="subtext text-center"
                     [innerHTML]="IndexContentResources['index.body.fourth-section.description'] | render | async">
                </div>


                <div class="row">

                    @for (feature of IndexContentResources.BodyFourthSectionFeatures; track $index) {
                        <div class="col-xs-12 col-lg-6 feature">
                            <div class="row">
                                <div class="col-1">
                                    <font-awesome-icon
                                            [icon]="'icon-feature fa-lg fas ' + (feature.iconStyle | render | async)"></font-awesome-icon>
                                </div>

                                <div class="col-11">
                                    <p><strong>{{ feature.title | render | async }}</strong></p>
                                    <div>{{ feature.subtitle  | render | async }}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div class="text-center call-to-action">
                    <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted)"
                       class="btn btn-primary btn-lg">{{ IndexContentResources["index.body.fourth-section.call-to-action"] | render | async }}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">

    <div class="contact-us-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center">{{ IndexContentResources["index.body.sixth-section.title"] | render | async }}</h2>
                    <div class="row">

                        <div class="col-xs-12 col-lg-8 offset-lg-2 subtext text-center">
                            <div [innerHTML]="IndexContentResources['index.body.sixth-section.description'] | render | async"></div>

                            <div class="text-center call-to-actions d-flex flex-column flex-md-row gap-2 justify-content-center">
                                <a href="#hs-chat-open" class="btn btn-light chat">
                                    <font-awesome-icon
                                            [icon]="IndexContentResources['index.body.sixth-section.call-to-action.icon-class'] | render | async"></font-awesome-icon>
                                    {{ IndexContentResources["index.body.sixth-section.call-to-action.display-text"] | render | async }}
                                </a>
                                <!--  [routerLink]="this.routeManager.getRouteInCurrentLocale('get-started')" -->
                                <a [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.ContactUs)"
                                   class="btn btn-light email">
                                    <font-awesome-icon
                                            [icon]="IndexContentResources['index.body.sixth-section.call-to-action-2.icon-class'] | render | async"></font-awesome-icon>
                                    {{ IndexContentResources["index.body.sixth-section.call-to-action-2.display-text"] | render | async }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
