import {Injectable} from "@angular/core";
import {NavItemModel} from "../models/nav-item.model";

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public menuItems: { [id: string]: NavItemModel } = {};

    constructor() {
    }

    updateActiveMenu() {
        for (const menuItemsKey in this.menuItems) {
            if (this.menuItems.hasOwnProperty(menuItemsKey) === false) {
                continue;
            }
            const menuItem = this.menuItems[menuItemsKey];

            menuItem.isActive = menuItem.matches(window.location.pathname);
        }
    }


}