import {Injectable} from "@angular/core";
import {CacheModel} from "../models/cache.model";

@Injectable({
    providedIn: 'root'
})
export class CacheStorageService {
    async exists(key: string): Promise<boolean> {
        const element = await this.get(key);

        return element !== null;
    }

    async get(key: string): Promise<any | null> {
        const cache = await caches.open("optitax");

        const found = await cache.match(key)

        if (found === undefined) {
            return null;
        }

        const cachedObject: CacheModel = await found.json();
        cachedObject.expiresAt = new Date(cachedObject.expiresAt);

        if (cachedObject.expiresAt < new Date()) {
            return null;
        }

        return cachedObject.element;
    }

    async set(key: string, value: any, expiresAt?: Date): Promise<void> {
        // Set expiration by default to 1 hour
        const cachedModel = new CacheModel(new Date(expiresAt ?? new Date().getTime() + 1000 * 60 * 60));
        cachedModel.element = value;

        const cache = await caches.open("optitax");
        return cache.put(key, new Response(JSON.stringify(cachedModel)));
    }

    async clear(): Promise<void> {
        await caches.delete("optitax");
    }
}
