import {Injectable} from "@angular/core";
import {ActivatedRoute, NavigationStart, ResolveEnd, Router} from "@angular/router";
import {LocaleEnum} from "../enums/locale.enum";
import {filter} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class LocaleManager {
    private defaultLocale: LocaleEnum = LocaleEnum.French;
    private currentLocale?: LocaleEnum;

    setCurrentLocale(locale: string) {
        switch (locale) {
            case LocaleEnum.French:
                this.currentLocale = LocaleEnum.French;
                return;
            case LocaleEnum.English:
                this.currentLocale = LocaleEnum.English;
                return;
        }

        console.error(`Invalid locale: '${locale}', passed to \`LocaleManager.setCurrentLocale\`. Must be of type \`LocaleEnum.French\` or \`LocaleEnum.English\`.`);
    }

    getCurrentLocale(): LocaleEnum {
        return this.currentLocale || this.defaultLocale;
    }

    isCurrentBrowserPreferredLanguageDifferentThenCurrentLocale(): boolean {
        if (navigator.language.startsWith("en") && this.getCurrentLocale() !== LocaleEnum.English) {
            return true;
        }

        if (navigator.language.startsWith("fr") && this.getCurrentLocale() !== LocaleEnum.French) {
            return true;
        }

        return false;
    }
}
