import {LocaleEnum} from "../../enums/locale.enum";
import {CalculationKeynameEnum} from "../../../../../../../common/src/enums/calculations/calculation-keyname.enum";

export class CalculationKeynameRoutePathConstants {
    public static [LocaleEnum.French]: { [id in CalculationKeynameEnum]?: string } = {
        [CalculationKeynameEnum.SalaryDividend]: "remuneration-salaire-ou-dividendes",
        [CalculationKeynameEnum.VehicleOwnership]: "detention-automobile-personelle-ou-corporative",
        [CalculationKeynameEnum.Rollover]: "roulement-fiscal",
        [CalculationKeynameEnum.Section84_1]: "vente-actions",
    };

    public static [LocaleEnum.English]: { [id in CalculationKeynameEnum]?: string } = {
        [CalculationKeynameEnum.SalaryDividend]: "salary-vs-dividends",
        [CalculationKeynameEnum.VehicleOwnership]: "vehicle-ownership",
        [CalculationKeynameEnum.Rollover]: "tax-rollover",
        [CalculationKeynameEnum.Section84_1]: "shares-selling",
    }
}