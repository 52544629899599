<app-header [headerStyle]="HeaderStyleEnum.Support">
    <div class="row">
        <div class="col-12 col-lg-12">
            <h1 class="text-center display-4 mt-5 mt-lg-0">{{ SupportContentResources["support.body.hero.title"] | render | async }}</h1>

            <h3 class="text-center mt-5 h5 text-gray-600 mb-5">{{ SupportContentResources["support.body.hero.description"] | render | async }}</h3>
        </div>
    </div>
</app-header>

<div class="categories container">
    <div class="row">
        @for (category of SupportContentResources.Categories; track $index) {

            <div class="col-12 col-md-6">
                <a class="card m-4" [href]="category.link | render | async">
                    <div class="card-body p-4">
                        <app-image class="img img-fluid" [imageContentModel]="category.image"
                                   [fetchPriority]="FetchPriorityEnum.High" [loading]="LoadingEnum.Eager"></app-image>

                        <h3 class="lead mt-4 mb-3">
                            {{ category.title | render | async }}
                        </h3>

                        <div class="text-dark" [innerHTML]="category.description | render | async"></div>

                        <p class="learn-more">{{ category.callToAction | render | async }}
                            <font-awesome-icon icon="fa fa-arrow-right" class="ms-2"></font-awesome-icon>
                        </p>
                    </div>
                </a>
            </div>
        }
    </div>
</div>


<div class="faq-section container">
    <h2 class="text-center">Foire aux questions</h2>

    <div class="questions-container">
        <div class="row">
            <div class="col-12">

            </div>
        </div>
    </div>
</div>


<br>
<br>
<br>
<br>
<br>
<br>
