<div class="container-fluid p-0">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2 d-flex flex-column justify-content-center" style="min-height: 60vh">
                <h1 class="text-center text-muted h4">Erreur 404</h1>

                <h2 class="text-center display-3 mt-3">Cette page n'existe pas</h2>

                <p class="text-center mt-3">La page que vous recherchez n'existe pas</p>

                <h3 class="text-left mt-5 text-muted h5">Pages populaires</h3>


                <div class="list-group list-group-flush list-group-focus">
                    <a class="list-group-item" href="{{ this.routeManager.getRouteInCurrentLocale(RouteEnum.Home) }}">
                        <div class="row align-items-center">
                            <div class="col-auto d-flex flex-column align-content-center">

                                <!-- Avatar -->
                                <div class="avatar text-center d-flex">
                                    <font-awesome-icon icon="fas fa-home icon"></font-awesome-icon>
                                </div>

                            </div>
                            <div class="col ms-n2">

                                <!-- Title -->
                                <h5 class="text-body text-focus mb-1 name">
                                    Accueil
                                </h5>

                            </div>
                        </div> <!-- / .row -->
                    </a>
                    <a class="list-group-item" href="{{ this.routeManager.getRouteInCurrentLocale(RouteEnum.Calculations) }}">
                        <div class="row align-items-center">
                            <div class="col-auto">

                                <!-- Avatar -->
                                <div class="avatar text-center d-flex">
                                    <font-awesome-icon icon="fas fa-calculator-alt icon"></font-awesome-icon>
                                </div>

                            </div>
                            <div class="col ms-n2">

                                <!-- Title -->
                                <h5 class="text-body text-focus mb-1 name">
                                    Outils de calcul
                                </h5>

                                <!-- Time -->
                                <p class="text-muted mb-0">
                                    Consultez la liste de tous les outils de calcul que nous offrons.
                                </p>

                            </div>
                        </div> <!-- / .row -->
                    </a>
                    <a class="list-group-item" href="{{ this.routeManager.getRouteInCurrentLocale(RouteEnum.ContactUs) }}">
                        <div class="row align-items-center">
                            <div class="col-auto">

                                <!-- Avatar -->
                                <div class="avatar text-center d-flex">
                                    <font-awesome-icon icon="fas fa-phone-alt icon"></font-awesome-icon>
                                </div>

                            </div>
                            <div class="col ms-n2">

                                <!-- Title -->
                                <h5 class="text-body text-focus mb-1 name">
                                    Contactez-nous
                                </h5>

                                <!-- Time -->
                                <p class="text-muted mb-0">
                                    N'hésitez pas à nous contacter si vous avez des questions!
                                </p>

                            </div>
                        </div> <!-- / .row -->
                    </a>
                </div>

                <div class="text-center">
                    <a href="{{ this.routeManager.getRouteInCurrentLocale(RouteEnum.Home) }}"
                       class="btn btn-primary mt-5">
                        <font-awesome-icon icon="fas fa-arrow-left me-2"></font-awesome-icon>
                        Retour à la page d'accueil</a>
                </div>
            </div>

        </div>
    </div>


    <app-footer></app-footer>

</div>