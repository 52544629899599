import {Component, Input} from '@angular/core';
import {LocaleManager} from "../../managers/locale.manager";
import {LocaleEnum} from "../../enums/locale.enum";
import {RouteManager} from "../../managers/route.manager";
import {PreferenceManager} from "../../managers/preference.manager";
import {BaseComponent} from "../base.component";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {LoadingEnum} from "../../enums/loading.enum";
import {HeaderContentResources} from "../../content/resources/header.content-resources";
import {ContentManager} from "../../content/managers/content.manager";
import {RouteEnum} from "../../enums/route.enum";
import {HeaderStyleEnum} from "../../enums/header-style.enum";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {

    collapsed = true;

    showLanguageSwitcherBar = this.preferenceManager.getDoNotShowLanguageSwitcherBar() && this.localeManager.isCurrentBrowserPreferredLanguageDifferentThenCurrentLocale();

    @Input()
    headerStyle: HeaderStyleEnum = HeaderStyleEnum.Default;

    constructor(
        private readonly preferenceManager: PreferenceManager,
        public readonly localeManager: LocaleManager,
        public readonly routeManager: RouteManager,
        private readonly contentManager: ContentManager,
    ) {
    }

    doNotShowLanguageSwitcherBar() {
        this.preferenceManager.setDoNotShowLanguageSwitcherBar()
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }

    protected readonly LocaleEnum = LocaleEnum;
    protected readonly FetchPriorityEnum = FetchPriorityEnum;
    protected readonly LoadingEnum = LoadingEnum;
    protected readonly HeaderContentResources = HeaderContentResources;
    protected readonly RouteEnum = RouteEnum;
    protected readonly HeaderStyleEnum = HeaderStyleEnum;
}
