import {ContentTypeEnum} from "../enums/content-type.enum";
import {BaseContentModel} from "./base.content-model";

export class MarkdownContentModel extends BaseContentModel {

    public fr: string;

    public en: string;

    constructor(options?: Partial<MarkdownContentModel>) {
        super(ContentTypeEnum.Markdown);

        this.fr = options?.fr ?? "";
        this.en = options?.en ?? "";
    }
}
