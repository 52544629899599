var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { plainToInstance } from "class-transformer";
import { DataMappingInterceptorNotFoundError } from "../errors/data-mapping-interceptor-not-found.error";
import { DataMapperOptions } from "../options/data-mapper.options";
export class DataMapper {
  constructor(autoDataMappingBuilder, dataNormalizers, dataTransformerInterceptors) {
    this.autoDataMappingBuilder = autoDataMappingBuilder;
    this.dataNormalizers = dataNormalizers;
    this.dataTransformerInterceptors = dataTransformerInterceptors;
    this.dataNormalizersMap = {};
    this.dataTransformerInterceptorsMap = {};
    dataNormalizers.forEach(dataNormalizer => {
      this.dataNormalizersMap[dataNormalizer.getUniqueKey()] = dataNormalizer;
    });
    dataTransformerInterceptors.forEach(interceptor => {
      this.dataTransformerInterceptorsMap[interceptor.getUniqueKey()] = interceptor;
    });
  }
  /**
   * This method takes an array of source and maps each item.
   *
   * @param builder
   * @param source
   * @param destinationType
   */
  mapAll(builder, source, destinationType) {
    return __awaiter(this, void 0, void 0, function* () {
      const destination = [];
      for (const element of source) {
        destination.push(yield this.map(builder, element, destinationType));
      }
      return destination;
    });
  }
  /**
   * This method automatically maps a source object into the DestinationType.
   * @param source
   * @param destinationType
   * @param options
   */
  autoMap(source, destinationType, options) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        if (Array.isArray(source)) {
          if (source.length === 0) {
            return [];
          }
          const dataMappingBuilder = this.autoDataMappingBuilder.build(source[0], destinationType, options);
          const destination = [];
          for (const element of source) {
            destination.push(yield this.map(dataMappingBuilder, element, destinationType, new DataMapperOptions({
              excludeExtraneousValues: options === null || options === void 0 ? void 0 : options.excludeExtraneousValues
            })));
          }
          return destination;
        }
        const dataMappingBuilder = this.autoDataMappingBuilder.build(source, destinationType, options);
        return yield this.map(dataMappingBuilder, source, destinationType, new DataMapperOptions({
          excludeExtraneousValues: options === null || options === void 0 ? void 0 : options.excludeExtraneousValues
        }));
      } catch (e) {
        if (options === null || options === void 0 ? void 0 : options.logErrors) {
          console.error(e);
        }
        if (options === null || options === void 0 ? void 0 : options.throwOnErrors) {
          throw e;
        }
        // Return the source on error.
        return source;
      }
    });
  }
  /**
   * This method takes a builder, a source and maps it according to the builder. You can pass a `destinationType (optional)`
   * that is an object that will be constructed.
   *
   * @param builder
   * @param source
   * @param destinationType
   * @param options
   */
  map(builder, source, destinationType, options) {
    return __awaiter(this, void 0, void 0, function* () {
      let destination = {};
      if ((options === null || options === void 0 ? void 0 : options.excludeExtraneousValues) === false) {
        Object.keys(source).forEach(property => {
          destination[property] = source[property];
        });
      }
      let interceptedSource = source;
      options = new DataMapperOptions(options);
      // Execute the before interceptors.
      for (const element of builder.beforeMappingInterceptors) {
        const interceptor = this.dataTransformerInterceptorsMap[element.key];
        if (interceptor === undefined) {
          throw new DataMappingInterceptorNotFoundError("The interceptor wasn't found and cannot be loaded.", element.key);
        }
        // todo: Pass the options when we start using them.
        interceptedSource = yield interceptor.beforeMapping(interceptedSource);
      }
      // Loop over the properties defined in the builder
      for (const key in builder.nodes) {
        if (builder.nodes.hasOwnProperty(key) === false) {
          continue;
        }
        const node = builder.nodes[key];
        yield node.map(interceptedSource, destination, this.dataNormalizersMap, options);
      }
      // Execute the before interceptors.
      for (const element of builder.afterMappingInterceptors) {
        const interceptor = this.dataTransformerInterceptorsMap[element.key];
        if (interceptor === undefined) {
          throw new DataMappingInterceptorNotFoundError("The interceptor wasn't found and cannot be loaded.", element.key);
        }
        // todo pass the options when we start using it.
        destination = yield interceptor.afterMapping(destination);
      }
      if (destinationType) {
        destination = plainToInstance(destinationType, destination);
      }
      return destination;
    });
  }
}
