import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { CalculationRunOptions } from "../calculation-run.options";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { CalculationKeynameEnum } from "../../../enums/calculations/calculation-keyname.enum";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import { IsBoolean, IsNotEmpty, IsInt, ValidateIf, IsNumber, Min, Max } from '@pristine-ts/class-validator';
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("qpppension-in-salary-dividend-options")
export class QppPensionOptionsInSalaryDividendOptions extends CalculationRunOptions {
  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.QppPension;

  @advancedFormControl({
    labelTitle: {
      fr: "Âge estimé du décès de l'entrepreneur?",
      en: "Estimated age at the time of death ",
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  deathAge: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Taux d'augmentation de la rente RRQ",
      en: "Increasing rate for QPP Pension",
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsNumber(undefined, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @Min(0, {buildErrorMessage: ErrorMessageBuilder.build})
  qPPIncreasingRate: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Taux de rendement annuel après impôt",
      en: "Annual investment rate after taxes",
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsNumber(undefined, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @Min(0, {buildErrorMessage: ErrorMessageBuilder.build})
  investmentRate: number;
}
