import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsInt,
  IsDate,
  Max,
  Min,
  ValidateNested, IsOptional
} from "@pristine-ts/class-validator";
import { FieldTypeEnum } from "../../../../../enums/field-type.enum";
import { v4 as uuid } from 'uuid';
import { advancedFormControl } from "../../../../../decorators/advanced-form-control.decorator";
import { translationBaseKey } from "../../../../../decorators/translation-base-key.decorator";
import {ErrorMessageBuilder} from "../../../../../builders/error-message.builder";
import { advancedFormArray } from "../../../../../decorators/advanced-form-array.decorator";
import { IncomeTaxPersonalQuebecPersonIncomeTaxPersonalFederalPersonOptions } from "./income-tax-personal-quebec-person-income-tax-personal-federal-person.options";
import {advancedFormGroup} from "../../../../../decorators/advanced-form-group.decorator";
import {type} from "@pristine-ts/data-mapping-common";

@translationBaseKey("income-tax-personal-spouse-quebec-options")
export class IncomeTaxPersonalSpouseQuebecOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Allocations pour frais de garde incluses à la case 201 du relevé 1 et la case J du relevé 5",
      en: "Care allowances included on box 201 of RL-1 and box J of RL-5"
    },
    tooltip: {
      fr: "Indiquez le montant des allocations pour frais de garde incluses à la case 201 du relevé 1 et la case J du relevé 5. Ces allocations peuvent avoir un impact sur les crédits d'impôt pour frais de garde au Québec.",
      en: "Specify the amount of care allowances included on box 201 of RL-1 and box J of RL-5. These allowances may have an impact on the Quebec tax credit for childcare expenses.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  careAllowanceCase201Statement: number = 0;
  //Ligne_40_Qc_conjoint_1

  @advancedFormControl({
    labelTitle: {
      fr: "Revenu d'emploi excluant Q201",
      en: "Employment income excluding Q201"
    },
    tooltip: {
      fr: "Indiquez le montant du revenu d'emploi excluant Q201. Ce revenu peut être utilisé pour déterminer l'admissibilité au crédit d'impôt pour frais de garde au Québec.",
      en: "Specify the amount of employment income excluding Q201. This income may be used to determine eligibility for the Quebec tax credit for childcare expenses.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  employmentIncomeExcludingSlipQ201: number = 0;
  //revenu_emploi_exclu_Q201_conjoint_1

  @advancedFormControl({
    labelTitle: {
      fr: "Admissibilité au crédit d'impôt pour frais de garde au Québec",
      en: "Eligibility for the Quebec tax credit for childcare expenses"
    },
    tooltip: {
      fr: "Indiquez si le conjoint est admissible au crédit d'impôt pour frais de garde au Québec. L'admissibilité est basée sur certains critères définis par les lois fiscales du Québec.",
      en: "Specify if the spouse is eligible for the Quebec tax credit for childcare expenses. Eligibility is based on certain criteria defined by Quebec tax laws.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isSpouseEligibleForCreditTaxCareAllowance: boolean = true;
  //admissible_ligne455_conjoint_1

  @type(() => new IncomeTaxPersonalQuebecPersonIncomeTaxPersonalFederalPersonOptions())
  @advancedFormGroup({
    classType: IncomeTaxPersonalQuebecPersonIncomeTaxPersonalFederalPersonOptions,
  })
  @IsOptional()
  incomeTaxPersonalFederalPerson?: IncomeTaxPersonalQuebecPersonIncomeTaxPersonalFederalPersonOptions;
}
