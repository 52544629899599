import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import {IsInt, IsNotEmpty, IsString, Min, ValidateIf} from "@pristine-ts/class-validator";
import {RolloverOptions} from "./rollover.options";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-property-transferred-shares-class-options")
export class RolloverPropertyTransferredSharesClassOptions {

  @advancedFormControl({
    labelTitle: {
      fr: "Identifiant de la catégorie d'actions",
      en: "Share class identifier"
    },
    tooltip: {
      fr: "Ce champ représente l'identifiant unique de la catégorie d'actions. Il est utilisé pour identifier spécifiquement cette catégorie d'actions dans le cadre du roulement de propriété. Cet identifiant est important pour assurer une correspondance appropriée entre les actions transférées et les catégories d'actions existantes.",
      en: "This field represents the unique identifier of the share class. It is used to specifically identify this share class in the context of the property rollover. This identifier is important to ensure proper matching between the transferred shares and existing share classes."
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString()
  @IsNotEmpty()
  sharesClassId: string;

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre d'actions",
      en: "Number of shares"
    },
    tooltip: {
      fr: "Ce champ représente le nombre total d'actions transférées de cette catégorie. Il est utilisé pour indiquer combien d'actions sont transférées dans cette catégorie spécifique. Le nombre d'actions est essentiel pour calculer la juste valeur marchande totale des actions transférées de cette catégorie.",
      en: "This field represents the total number of shares transferred from this share class. It is used to indicate how many shares are transferred in this specific class. The number of shares is essential for calculating the total fair market value of shares transferred from this share class."
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @Min(1, {buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf((o, root: RolloverOptions, currentPath) => {
    if (!currentPath) {
      return false;
    }

    // Find the first string that is a number.
    const index = currentPath.match(/[0-9]+/gm)?.shift();

    // Validate that we found an index that is a number.
    if (!index || isNaN(+index)) {
      return false;
    }

    if (root.propertiesTransferred.length <= +index) {
      return false;
    }
    return root.propertiesTransferred[+index].isCalculateNumberOfPreferredShares === false;
  })
  numberOfShares: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Valeur de rachat des actions",
      en: "Fair market value of the shares"
    },
    tooltip: {
      fr: "Ce champ représente la valeur de rachat totale des actions transférées de cette catégorie. Il est utilisé pour indiquer la valeur financière totale des actions transférées dans cette catégorie spécifique. Cette valeur est essentielle pour calculer les gains en capital et déterminer les implications fiscales liées au roulement de propriété.",
      en: "This field represents the total fair market value of the shares transferred from this share class. It is used to indicate the total financial value of shares transferred in this specific class. This value is essential for calculating capital gains and determining the tax implications related to the property rollover."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateIf((o, root: RolloverOptions, currentPath) => {
    if (!currentPath) {
      return false;
    }
    // Find the first string that is a number.
    const index = currentPath.match(/[0-9]+/gm)?.shift();

    // Validate that we found an index that is a number.
    if (!index || isNaN(+index)) {
      return false;
    }
    if (root.propertiesTransferred.length <= +index) {
      return false;
    }
    return root.propertiesTransferred[+index].isCalculateNumberOfPreferredShares === false;
  })
  fairMarketValue: number;
}
