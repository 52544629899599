import {LocaleEnum} from "../../enums/locale.enum";
import {SupportArticleEnum} from "../enums/support-article.enum";

export const SupportArticleSlugConstants: { [key in LocaleEnum]: { [id in SupportArticleEnum]: string } } = {
    [LocaleEnum.French]: {
        [SupportArticleEnum.SuccessfullyOrchestrateRolloversInHarmonyWithCanadianAndQuebecTaxLaws]: "reorganisez-avec-succes-maitrisez-les-roulements-en-harmonie-avec-les-lois-fiscales-canadiennes-et-quebecoises",
    },
    [LocaleEnum.English]: {
        [SupportArticleEnum.SuccessfullyOrchestrateRolloversInHarmonyWithCanadianAndQuebecTaxLaws]: "successfully-orchestrate-rollovers-in-harmony-with-canadian-and-quebec-tax-laws",
    }
}