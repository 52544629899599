import {property} from "@pristine-ts/metadata";
import {IsInt} from "@pristine-ts/class-validator";

export class CalculationDate {
    @property()
    @IsInt()
    day: number;

    @property()
    @IsInt()
    month: number;

    @property()
    @IsInt()
    year: number;

    constructor(date?: Date | { year: number, month: number, day: number }) {
        if (!date) {
            date = new Date();
        } else if ( (date instanceof Date) === false) {
            this.year = date.year;
            this.month = date.month;
            this.day = date.day;
            return;
        }

        this.day = date.getDate();
        this.month = date.getMonth() + 1;
        this.year = date.getFullYear();
    }

    /**
     * Constructs the Date object and from the CalculationDate.
     */
    toDate(): Date {
        return new Date(this.year, this.month - 1, this.day, 0, 0, 0, 0);
    }
}
