import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() { }

    public setJsonItem(key: string, value: any) {
        this.setItem(key, JSON.stringify(value));
    }

    public getJsonItem(key: string): any {
        const item = this.getItem(key);

        if (item == null) {
            return {};
        }
        
        return JSON.parse(item);
    }

    public setItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public getItem(key: string){
        return localStorage.getItem(key)
    }
    public removeItem(key:string) {
        localStorage.removeItem(key);
    }
    public clear(){
        localStorage.clear();
    }
}