import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule, provideClientHydration, withEventReplay} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {LayoutComponent} from './components/layout/layout.component';
import {RootComponent} from './components/root/root.component';
import {AppRoutingModule} from './app.routes';
import {HomeComponent} from './pages/home/home.component';
import {LocaleManager} from "./managers/locale.manager";
import {RouteManager} from "./managers/route.manager";
import {PreferenceManager} from "./managers/preference.manager";
import {FooterComponent} from "./components/footer/footer.component";
import {ContentManager} from "./content/managers/content.manager";
import {RenderPipe} from "./content/pipes/render.pipe";
import {TextRenderer} from "./content/renderers/text.renderer";
import {ImageComponent} from "./components/image/image.component";
import {MarkdownRenderer} from "./content/renderers/markdown.renderer";
import {TermsAndConditionsComponent} from './pages/terms-and-conditions/terms-and-conditions.component';
import {HtmlRenderer} from "./content/renderers/html.renderer";
import { CalculationsComponent } from './pages/calculations/calculations.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { StudentsComponent } from './pages/students/students.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { SecurityComponent } from './pages/security/security.component';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { CalculationComponent } from './pages/calculation/calculation.component';
import { SupportComponent } from './pages/support/support.component';
import { SupportArticleComponent } from './pages/support-article/support-article.component';
import { Error404Component } from './pages/error-404/error-404.component';
import {CoreModule} from "../../../modules/src/lib/core/core.module";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LayoutComponent,
        RootComponent,

        ImageComponent,

        // Pages
        HomeComponent,

        // Content
        RenderPipe,
        TermsAndConditionsComponent,
        CalculationsComponent,
        ContactUsComponent,
        PricingComponent,
        StudentsComponent,
        AboutUsComponent,
        SecurityComponent,
        GetStartedComponent,
        CalculationComponent,
        SupportComponent,
        SupportArticleComponent,
        Error404Component,
    ],
    imports: [
        AppRoutingModule,
        CommonModule,
        BrowserModule,
        RouterModule,

        CoreModule,
    ],
    providers: [
        provideClientHydration(withEventReplay()),

        LocaleManager,
        PreferenceManager,
        RouteManager,

        //
        // Content
        //

        // Managers
        ContentManager,

        // Renderers
        HtmlRenderer,
        MarkdownRenderer,
        TextRenderer,

    ],
    bootstrap: [RootComponent]
})
export class AppModule {
}
