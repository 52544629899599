import {Injectable} from "@angular/core";
import {enCA, frCA} from "date-fns/locale";
import {TranslateService} from "@ngx-translate/core";
import {format, formatDistance} from "date-fns";

@Injectable()
export class DateUtils {
    constructor(private readonly translateService: TranslateService) {
    }


    getLastModifiedText(lastModified: Date) {
        if (lastModified === undefined || (lastModified instanceof Date) === false) {
            return "En cours de chargement..."; // todo: translation
        }

        let locale = enCA;

        if (this.translateService.currentLang.startsWith("fr")) {
            locale = frCA
        }

        return formatDistance(lastModified, Date.now(), {
            locale,
            addSuffix: true,
        })
    }

    getText(date: Date) {
        if (date === undefined || (date instanceof Date) === false) {
            return "Chargement..."; // todo: translation
        }

        let locale = enCA;

        if (this.translateService.currentLang.startsWith("fr")) {
            locale = frCA
        }

        return format(date, "d MMMM yyyy", {
            locale,
        })
    }
}