export enum RolloverPropertyTypeEnum {
    mineralAsset = "MINERAL_ASSET",
    debtObligations = "DEBT_OBLIGATIONS",
    specificDebtObligations = "SPECIFIC_DEBT_OBLIGATIONS",
    capitalPropertyThatIsRealPropertyOwnedByNonResident = "CAPITAL_PROPERTY_THAT_IS_REAL_PROPERTY_OWNED_BY_NON_RESIDENT",
    agriInvestment = "AGRI_INVESTMENT",
    depreciableProperty = "DEPRECIABLE_PROPERTY",
    nonDepreciableProperty = "NON_DEPRECIABLE_PROPERTY",
    inventoryProperty = "INVENTORY_PROPERTY",
}
