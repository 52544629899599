import { IsBoolean, IsNotEmpty, IsInt, IsString, ValidateNested } from "@pristine-ts/class-validator";
import { v4 as uuid } from 'uuid';
import { advancedFormControl } from "../../../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../../../enums/field-type.enum";
import { translationBaseKey} from "../../../../../decorators/translation-base-key.decorator";
import { advancedFormArray } from "../../../../../decorators/advanced-form-array.decorator";
import {ErrorMessageBuilder} from "../../../../../builders/error-message.builder";

@translationBaseKey("income-tax-personal-child-quebec-options")
export class IncomeTaxPersonalChildQuebecOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Frais de garde admissible au Québec",
      en: "Other Quebec admissible care expenses"
    },
    tooltip: {
      fr: "Indiquez le montant des autres frais de garde admissibles pour l'enfant. Les frais de garde admissibles peuvent être utilisés pour réclamer des crédits d'impôt pour frais de garde au Québec.",
      en: "Specify the amount of other eligible childcare expenses for the child. Eligible childcare expenses can be used to claim childcare tax credits in Quebec.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  careExpensesOthers: number;
}