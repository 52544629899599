import {Injectable} from "@angular/core";
import {ValidationErrors} from "@angular/forms";
import {ValidationError} from "@pristine-ts/class-validator";

@Injectable()
export class ErrorsUtils {
    getErrors(validationErrors: ValidationError[]): string[] {
        const errors: string[] = [];

        validationErrors.forEach((validationError: ValidationError) => {
            for (const constraintsKey in validationError.constraints) {
                errors.push(validationError.constraints[constraintsKey].message);
            }

            errors.push(...this.getErrors(validationError.children));
        })

        return errors;
    }
}