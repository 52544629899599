import {Injectable} from "@angular/core";
import {MagienoApiController} from "../api-controllers/magieno.api-controller";
import {differenceInSeconds} from "date-fns";

@Injectable()
export class MagienoObservabilityManager {
    lastAlertSent?: Date;

    constructor(private readonly apiController: MagienoApiController) {
    }

    async sendAlert(severity: "CRITICAL" | "ERROR" | "WARNING" | "INFO" | "DEBUG", message: string, extra: { [id in string]: any }) {
        try {
            if (this.lastAlertSent && differenceInSeconds(new Date(), this.lastAlertSent) < 120) {
                // throttle
                return;
            }

            this.lastAlertSent = new Date();

            await this.apiController.sendAlert(severity, message, extra);
        } catch (e) {
            // Well, we're fucked.
            return;
        }
    }

    async sendLog(severity: "CRITICAL" | "ERROR" | "WARNING" | "INFO" | "DEBUG", message: string, extra: { [id in string]: any }) {
        try {
            return this.apiController.sendLog(severity, message, extra);
        } catch (e) {
            // Well, we're fucked.
            return;
        }
    }
}
