
export enum RouteEnum {
    Home = 'home',
    Calculations = 'calculations',
    TermsAndConditions = 'terms-and-conditions',
    ContactUs = 'contact-us',
    Pricing = 'pricing',
    Students = "students",
    AboutUs = "about-us",
    Security = "security",

    GetStarted = "get-started",

    // Calculations
    Calculation = "calculation",
    // CalculationSalaryDividend = "salary-vs-dividends",
    // CalculationVehicleOwnership = "vehicle-ownership",
    // CalculationRollover = "rollover",
    // CalculationSharesSelling = "shares-selling",

    Support = "support",
    SupportArticle = "support-article",
}
