import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import {ContactUsContentResources} from "../../content/resources/contact-us.content-resources";
import {LocaleManager} from "../../managers/locale.manager";
import {LocaleEnum} from "../../enums/locale.enum";
import {clearInterval} from "node:timers";
import {HubspotChatStatusEnum} from "../../enums/hubspot-chat-status.enum";
import {RouteManager} from "../../managers/route.manager";
import {Router} from "@angular/router";
import {isPlatformBrowser, Location} from "@angular/common";
import {platform} from "node:os";
import {BaseComponent} from "../../components/base.component";
import {ContentManager} from "../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent extends BaseComponent implements AfterViewInit {

    numberOfTriesToCheck = 0;

    hubspotChatStatus: HubspotChatStatusEnum = HubspotChatStatusEnum.Loading;

    constructor(
        localeManager: LocaleManager,
        routeManager: RouteManager,
        contentManager: ContentManager,
        titleService: Title,
        meta: Meta,
        private readonly router: Router,
        private readonly location: Location,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        super(localeManager,
            routeManager,
            contentManager,
            titleService,
            meta,
            ContactUsContentResources,
        )
    }

    ngAfterViewInit(): void {
        if(isPlatformBrowser(this.platformId)) {
            this.loadHubspotChat();

            this.loadHubspotForm();
        }
    }

    loadHubspotForm() {
        const locale = this.localeManager.getCurrentLocale()

        // @ts-ignore
        if(typeof hbspt === 'undefined') {
            return;
        }

        switch (locale) {
            case LocaleEnum.French:
                // @ts-ignore
                hbspt.forms.create({
                    region: "na1",
                    portalId: "20373920",
                    formId: "51661698-1fc2-4757-94ea-40cf6f226021",
                    target: "#contact_us_hubspot_form"
                });
                break;
            case LocaleEnum.English:
                // @ts-ignore
                hbspt.forms.create({
                    region: "na1",
                    portalId: "20373920",
                    formId: "9fc092df-3801-4e90-b4c2-7680d7221d48",
                    target: "#contact_us_hubspot_form"
                });
                break;
        }

    }

    openHubspotChat(e: any) {
        e.preventDefault();

        const urlTree = this.router.createUrlTree([], { fragment:"hs-chat-open" });
        this.location.go(urlTree.toString());
    }

    loadHubspotChat() {
        this.numberOfTriesToCheck = 0;
        this.hubspotChatStatus = HubspotChatStatusEnum.Loading;

        const checkIsHubspotLoading = () => {
            // @ts-ignore
            if (typeof document !== 'undefined' && document.getElementById("hubspot-messages-iframe-container") != null) {
                this.hubspotChatStatus = HubspotChatStatusEnum.Loaded;
                return;
            }

            if(this.numberOfTriesToCheck >= 5) {
                this.hubspotChatStatus = HubspotChatStatusEnum.Blocked;
                return;
            }

            this.numberOfTriesToCheck++;
            setTimeout(() => checkIsHubspotLoading(), 500);
        }

        checkIsHubspotLoading();
    }

    protected readonly ContactUsContentResources = ContactUsContentResources;
    protected readonly HubspotChatStatusEnum = HubspotChatStatusEnum;
}
