import {IsBoolean, IsNotEmpty, IsInt, ValidateIf, ValidateNested, IsString} from "@pristine-ts/class-validator";
import { translationBaseKey } from "../../../decorators/translation-base-key.decorator";
import { advancedFormControl } from "../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../enums/field-type.enum";
import {ErrorMessageBuilder} from "../../../builders/error-message.builder";

@translationBaseKey("rollover-shares-class-shareholder-distribution-options")
export class RolloverSharesClassShareholderDistributionOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Identifiant de l'actionnaire",
      en: "Shareholder's ID"
    },
    tooltip: {
      fr: "Ce champ représente l'identifiant unique de l'actionnaire, utilisé pour identifier l'individu ou l'entité détenant les actions. Il est essentiel pour le suivi de la propriété des actions et pour faciliter la déclaration fiscale. Cette information assure une déclaration précise des gains en capital, des dividendes et autres transactions liées à l'impôt pour l'actionnaire.",
      en: "This field represents the unique identifier of the shareholder, which is used to identify the individual or entity holding the shares. It is essential for tracking ownership and facilitating tax reporting. This information ensures accurate reporting of capital gains, dividends, and other tax-related transactions for the shareholder."
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  shareholderId: string;

  // todo I think we can remove that we only need the id.
  // @advancedFormControl({
  //   labelTitle: {
  //     fr: "Quel est le nom de l'actionnaire?",
  //     en: ""
  //   },
  //   tooltip: {
  //     fr: "Tooltip"
  //   },
  //   fieldType: FieldTypeEnum.String
  // })
  // @IsString()
  // // @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  // title: string;

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre d'actions détenues",
      en: "Number of shares owned"
    },
    tooltip: {
      fr: "Ce champ représente le nombre total d'actions détenues par l'actionnaire. Il est crucial pour calculer le pourcentage de propriété de l'actionnaire et déterminer ses gains ou pertes en capital lors de la vente d'actions. De plus, il est utilisé dans les calculs fiscaux pour les crédits d'impôt et déductions liés à la propriété d'actions.",
      en: "This field represents the total number of shares owned by the shareholder. It is crucial for calculating the shareholder's ownership percentage and determining their capital gains or losses when selling shares. Additionally, it is used in tax calculations for eligible tax credits and deductions related to share ownership."
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  numberOfSharesOwned: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Prix de base rajusté des actions",
      en: "Adjusted cost base of shares"
    },
    tooltip: {
      fr: "Ce champ représente le coût de base rajusté des actions détenues par l'actionnaire. Le coût de base rajusté est un facteur clé pour déterminer les gains ou pertes en capital réalisés lors de la vente d'actions. Il prend en compte divers ajustements, tels que les coûts d'acquisition, les dividendes réinvestis et certaines dépenses, qui affectent le coût initial des actions.",
      en: "This field represents the adjusted cost base of the shares owned by the shareholder. The adjusted cost base is a key factor in determining the capital gains or losses realized when selling shares. It takes into account various adjustments, such as acquisition costs, dividends reinvested, and certain expenses, which affect the original cost of the shares."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  adjustedCostBase: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Juste valeur marchande des actions",
      en: "Fair market value of shares"
    },
    tooltip: {
      fr: "Ce champ représente la juste valeur marchande des actions détenues par l'actionnaire. La juste valeur marchande est le prix actuel auquel les actions seraient échangées entre un acheteur et un vendeur consentants sur un marché ouvert. Cette valeur est pertinente à des fins fiscales pour évaluer les actifs, déterminer les gains en capital et évaluer l'éligibilité à certains crédits d'impôt ou déductions.",
      en: "This field represents the fair market value of the shares owned by the shareholder. The fair market value is the current price at which the shares would be traded between a willing buyer and a willing seller in an open market. This value is relevant for tax purposes when valuing assets, determining capital gains, and assessing eligibility for certain tax credits or deductions."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  fairMarketValue: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Capital versé des actions",
      en: "Paid-up capital of shares"
    },
    tooltip: {
      fr: "Ce champ représente le capital versé des actions détenues par l'actionnaire. Le capital versé fait référence au montant total d'argent reçu par la société lors de l'émission d'actions. Dans le contexte des réglementations fiscales, le capital versé est essentiel pour déterminer le traitement fiscal des dividendes et distributions de capital reçus par l'actionnaire.",
      en: "This field represents the paid-up capital of the shares owned by the shareholder. Paid-up capital refers to the total amount of money received by the corporation from the issuance of shares. In the context of tax regulations, paid-up capital is essential for determining the tax treatment of dividends and capital distributions received by the shareholder."
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  paidUpCapital: number;
}
