import {Injectable} from "@angular/core";
import Dexie, {Table} from "dexie";
import {
    CalculationResponse
} from "../../../../../../../common/dist/angular/responses/calculation.response";
import {
    OrganizationModel
} from "../../../../../../../common/dist/angular/models/organization.model";
import {
    ClientQuestionnaireModel
} from "../../../../../../../common/dist/angular/models/client-questionnaire.model";

@Injectable({
    providedIn: 'root'
})
export class IndexedDbService extends Dexie {
    calculationResponses!: Table<CalculationResponse, string>;
    organizations!: Table<OrganizationModel, string>;
    clientQuestionnaires!: Table<ClientQuestionnaireModel, string>;

    constructor() {
        super("optitax");

        this.version(4).stores({
            calculationResponses: 'id, calculationResponse',
            organizations: 'id, organization',
            clientQuestionnaires: 'id, clientQuestionnaire',
        })
    }

}
