import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";

export const ContactUsContentResources: ContentResourceInterface & { [id in string]: any } = {
    LastModified: new TextContentModel(
        {"fr": "2024-12-04", "en": "2024-12-04"}
    ),

    Title: new TextContentModel(
        {"fr": "Contactez-nous", "en": "Contact us"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Contactez Optitax pour obtenir des solutions fiscales personnalisées adaptées à vos besoins. Notre équipe  expérimentée est là pour répondre à toutes vos questions et vous aider à optimiser votre gestion fiscale. Que  vous soyez un particulier, une entreprise ou un étudiant, nous offrons une gamme complète de forfaits adaptés,  y compris des solutions gratuites pour les étudiants.\n",
                "en": "Contact Optitax to get tailored tax solutions that suit your needs. Our experienced team is here to answer all  your questions and help you optimize your tax management. Whether you're an individual, a business, or a student,  we offer a comprehensive range of customized packages, including free solutions for students.\n"
            }
        ),

        Keywords: new TextContentModel(
            {
                "fr": "Optitax, Contactez-nous, Logiciel de gestion fiscale, Expertise fiscale, Solutions fiscales personnalisées, Assistance fiscale, Conseils fiscaux, Contactez-nous pour vos besoins fiscaux, Gestion fiscale efficace, Planification fiscale, Conformité fiscale, Services fiscaux professionnels, Optimisation fiscale, Réglementation fiscale, Support client pour la gestion fiscale, Demandez une démo gratuite, Partenariat fiscal",
                "en": "Optitax, Contact Us, Tax management software,Tax expertise,Customized tax solutions,Tax assistance,Tax advisory,Contact us for your tax needs,Efficient tax management,Tax planning,Tax compliance,Professional tax services,Tax optimization,Tax regulations,Customer support for tax management,Request a free demo,Tax partnership"
            }
        ),
    },

    "contact-us.body.title": new TextContentModel(
        {"fr": "Vous avez des questions?", "en": "Any questions?"}
    ),

    "contact-us.body.subtitle": new TextContentModel(
        {
            "fr": "Nous sommes là pour vous! Laissez-nous savoir comment nous pouvons vous aider!",
            "en": "We're here to help!"
        }
    ),

    "contact-us.body.chat.title": new TextContentModel(
        {"fr": "Clavardage", "en": "Chat"}
    ),

    "contact-us.body.chat.call-to-action": new TextContentModel(
        {"fr": "Clavardage en ligne", "en": "Online chat"}
    ),

    "contact-us.body.email.title": new TextContentModel(
        {"fr": "Courriel", "en": "Email"}
    ),

    "contact-us.body.contact-us.title": new TextContentModel(
        {"fr": "Contactez-nous directement!", "en": "Contact-us directly"}
    ),

    "contact-us.body.contact-us.subtitle": new TextContentModel(
        {
            "fr": "S'il est plus simple pour vous de remplir ce formulaire pour nous contacter, n'hésitez pas!",
            "en": "If you find it easier to fill this form to contact-us, do not hesitate!"
        }
    ),
}
