import {translationBaseKey} from "../../../../../decorators/translation-base-key.decorator";
import {CalculationRunOptions} from "../../../calculation-run.options";
import {CalculationKeynameEnum} from "../../../../../enums/calculations/calculation-keyname.enum";
import {advancedFormControl} from "../../../../../decorators/advanced-form-control.decorator";
import {FieldTypeEnum} from "../../../../../enums/field-type.enum";
import {
    IsInt,
    IsNotEmpty, IsOptional, validateIf,
} from "@pristine-ts/class-validator";
import {ErrorMessageBuilder} from "../../../../../builders/error-message.builder";
import {CompanyTypeEnum} from "../../../../../enums/company-type.enum";

@translationBaseKey("corporate-tax-federal-options")
export class CorporateIncomeTaxFederalOptions extends CalculationRunOptions {

    @advancedFormControl({})
    calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.CorporateTaxFederal;

    @advancedFormControl({
        labelTitle: {
            fr: "Dividendes ordinaires reçus de sociétés non rattachées",
            en: "Ordinary dividends received from unrelated corporations",
        },
        tooltip: {
            fr: "Inclure le montant total des dividendes ordinaires reçus de sociétés sans lien de rattachement avec le contribuable. Ces dividendes sont soumis à un traitement fiscal spécifique.",
            en: "Include the total amount of ordinary dividends received from corporations with no connection to the taxpayer. These dividends are subject to specific tax treatment.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    nonEligibleDividendNonConnected: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Dividendes déterminés reçus de sociétés non rattachées",
            en: "Eligible dividends received from unconnected corporations",
        },
        tooltip: {
            fr: "Inclure le montant total des dividendes déterminés reçus de sociétés sans lien de rattachement avec le contribuable. Les dividendes déterminés peuvent être admissibles à un taux d'imposition réduit grâce aux crédits d'impôt.",
            en: "Include the total amount of eligible dividends received from corporations with no connection to the taxpayer. Eligible dividends may qualify for a lower tax rate due to dividend tax credits.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    eligibleDividendNonConnected: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Autres revenus de placement ",
            en: "Other Income",
        },
        tooltip: {
            fr: "Revenus de placement à l’exception des dividendes de sociétés canadiennes et des gains (pertes) en capital.",
            en: "",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    interestIncome: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Gain en capital",
            en: "Capital gain",
        },
        tooltip: {
            fr: "Inclure le montant total des gains en capital réalisés par la société au cours de l'année en cours. Ces gains sont soumis à un taux d'inclusion dans le revenu imposable.",
            en: "Include the total amount of capital gains realized by the corporation in the current year. These gains are subject to an inclusion rate in taxable income.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    capitalGain: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Perte en capital",
            en: "Capital loss",
        },
        tooltip: {
            fr: "Inclure le montant total des pertes en capital réalisées par la société au cours de l'année en cours. Ces pertes peuvent être utilisées pour réduire le revenu imposable.",
            en: "Include the total amount of capital losses realized by the corporation in the current year. These losses can be used to offset taxable income.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    capitalLoss: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Revenu d'entreprise gagné à l'extérieur du Canada",
            en: "Business income earned outside of Canada",
        },
        tooltip: {
            fr: "Inclure le montant total du revenu d'entreprise gagné par la société à l'extérieur du Canada au cours de l'année en cours. Ce revenu est généralement imposable au Canada sous certaines règles de déclaration.",
            en: "Include the total amount of business income earned by the corporation outside of Canada in the current year. This income is generally taxable in Canada under certain reporting rules.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    businessIncomeOutsideCanada: number = 0;

    @advancedFormControl({
        labelTitle: {
            fr: "Solde de l'impôt en main remboursable au titre de dividendes non déterminés (IMRTDND) à la fin de l'année d'imposition précédente",
            en: "Balance of non-eligible refundable dividend tax on hand (IRDTND) at the end of the previous tax year.",
        },
        tooltip: {
            fr: "Inclure le solde de l'impôt en main remboursable au titre de dividendes non déterminés (IMRTDND) à la fin de l'année d'imposition précédente. Cette information impacte les calculs fiscaux pour l'année en cours. Il s'agit de la ligne 545 de l'année d'imposition précédente.",
            en: "Include the balance of non-eligible refundable dividend tax on hand (IRDTND) at the end of the previous tax year. This information impacts the tax calculations for the current year. Line 545 of the previous tax year.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsOptional()
    previousIMRTDND?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Remboursement au titre de dividendes non déterminés (RTDND) à la fin de l'année d'imposition précédente?",
            en: "Non-Eligible Dividend Refund (NDRTD) at the end of the previous tax year?",
        },
        tooltip: {
            fr: "Inclure le montant du remboursement au titre de dividendes non déterminés (RTDND) à la fin de l'année d'imposition précédente. Ce montant peut être utilisé pour réduire l'impôt à payer.",
            en: "Include the amount of Non-Eligible Dividend Refund (NDRTD) at the end of the previous tax year. This amount can be used to reduce the taxes payable.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsOptional()
    previousRTDND?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Dividende ordinaire déjà déclaré et versé dans l’année",
            en: "Ordinary dividend already declared and paid in the year",
        },
        tooltip: {
            fr: "Inclure le montant total des dividendes ordinaires versés par la société au cours de l'année en cours. Ces dividendes sont soumis à un traitement fiscal spécifique.",
            en: "Include the total amount of ordinary dividends paid by the corporation in the current year. These dividends are subject to specific tax treatment.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    nonEligibleDividendPaid: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Solde de l'impôt en main remboursable au titre de dividendes déterminés (IMRTDD) à la fin de l'année d'imposition précédente.",
            en: "Balance of eligible refundable dividend tax on hand (RDTOH) at the end of the previous tax year.",
        },
        tooltip: {
            fr: "Inclure le solde de l'impôt en main remboursable au titre de dividendes déterminés (IMRTDD) à la fin de l'année d'imposition précédente. Cette information impacte les calculs fiscaux pour l'année en cours. Ligne 530 de l'année d'imposition précédente.",
            en: "Include the balance of eligible refundable dividend tax on hand (RDTOH) at the end of the previous tax year. This information impacts the tax calculations for the current year. Line 530 of the previous tax year.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsOptional()
    previousIMRTDD?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Remboursement au titre de dividendes déterminés (RTDD) à la fin de l'année d'imposition précédente?",
            en: "Eligible dividend refund (DTDR) at the end of the previous tax year?",
        },
        tooltip: {
            fr: "Inclure le montant du remboursement au titre de dividendes déterminés (RTDD) à la fin de l'année d'imposition précédente. Ce montant peut être utilisé pour réduire l'impôt à payer.",
            en: "Include the amount of Eligible dividend refund (DTDR) at the end of the previous tax year. This amount can be used to reduce the taxes payable.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsOptional()
    previousRTDD?: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Dividendes déterminés payés au cours de l'année précédente?",
            en: "Eligible dividends paid during the previous year",
        },
        tooltip: {
            fr: "Inclure le montant total des dividendes déterminés payés par la société au cours de l'année précédente. Ces dividendes peuvent être admissibles à des crédits d'impôt pour les actionnaires.",
            en: "Include the total amount of eligible dividends paid by the corporation during the previous year. These dividends may qualify for tax credits for shareholders.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @validateIf((object: CorporateIncomeTaxFederalOptions, root, currentPath) => {

        if (root.hasOwnProperty("isEligibleDividendMaximized") === false ||
            typeof root["isEligibleDividendMaximized"] !== "boolean" ||
            root.hasOwnProperty("corporateIncomeTaxOptionsForm") === false ||
            root["corporateIncomeTaxOptionsForm"].hasOwnProperty("companyType") === false
        ) {
            return false;
        }

        const isEligibleDividendMaximized: boolean = root["isEligibleDividendMaximized"];
        const companyType = root["corporateIncomeTaxOptionsForm"]["companyType"];

        return companyType === CompanyTypeEnum.ccpcAllYear && isEligibleDividendMaximized;
    })
    eligibleDividendPaidLastYear: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Solde de CRTG à la fin de l'année précédente?",
            en: "CRTG's balance at the end of the previous year",
        },
        tooltip: {
            fr: "Inclure le solde du Cumulative RDTOH (CRTG) à la fin de l'année fiscale précédente. Cette information impacte les calculs fiscaux pour l'année en cours.",
            en: "Include the balance of Cumulative RDTOH (CRTG) at the end of the previous tax year. This information impacts the tax calculations for the current year.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @validateIf((object: CorporateIncomeTaxFederalOptions, root, currentPath) => {

        if (root.hasOwnProperty("isEligibleDividendMaximized") === false ||
            typeof root["isEligibleDividendMaximized"] !== "boolean" ||
            root.hasOwnProperty("corporateIncomeTaxOptionsForm") === false ||
            root["corporateIncomeTaxOptionsForm"].hasOwnProperty("companyType") === false
        ) {
            return false;
        }

        const isEligibleDividendMaximized: boolean = root["isEligibleDividendMaximized"];
        const companyType = root["corporateIncomeTaxOptionsForm"]["companyType"];

        return companyType === CompanyTypeEnum.ccpcAllYear && isEligibleDividendMaximized;
    })
    gRIPBalance: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Dividende déterminé déjà déclaré et versé dans l’année",
            en: "Eligible dividend alraedy declared and paid in the year",
        },
        tooltip: {
            fr: "Inclure le montant total des dividendes déterminés versés par la société au cours de l'année en cours. Ces dividendes sont soumis à un traitement fiscal spécifique.",
            en: "Include the total amount of eligible dividends paid by the corporation in the current year. These dividends are subject to specific tax treatment.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    eligibleDividendPaid: number;

    @advancedFormControl({
        labelTitle: {
            fr: "Solde du CRTR à la fin de l'année d'imposition précédente?",
            en: "CRTR balance at the end of the previous tax year",
        },
        tooltip: {
            fr: "Inclure le solde du Cumulative RDTO (CRTR) à la fin de l'année fiscale précédente. Cette information impacte les calculs fiscaux pour l'année en cours.",
            en: "Include the balance of Cumulative RDTO (CRTR) at the end of the previous tax year. This information impacts the tax calculations for the current year.",
        },
        fieldType: FieldTypeEnum.Money,
    })
    @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
    @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
    @IsOptional()
    lRIPBalance?: number;

}
