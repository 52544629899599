import { Component } from '@angular/core';
import {SecurityContentResources} from "../../content/resources/security.content-resources";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {LoadingEnum} from "../../enums/loading.enum";
import {BaseComponent} from "../../components/base.component";
import {LocaleManager} from "../../managers/locale.manager";
import {RouteManager} from "../../managers/route.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrl: './security.component.scss'
})
export class SecurityComponent extends BaseComponent {

  constructor(
      localeManager: LocaleManager,
      routeManager: RouteManager,
      contentManager: ContentManager,
      titleService: Title,
      meta: Meta,) {
    super(localeManager,
        routeManager,
        contentManager,
        titleService,
        meta,
        SecurityContentResources
    )
  }

  protected readonly SecurityContentResources = SecurityContentResources;
  protected readonly FetchPriorityEnum = FetchPriorityEnum;
  protected readonly LoadingEnum = LoadingEnum;
}
