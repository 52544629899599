import {IsBoolean, IsNotEmpty, IsInt, IsString, ValidateNested, IsOptional} from "@pristine-ts/class-validator";
import { v4 as uuid } from 'uuid';
import { advancedFormControl } from "../../../../../decorators/advanced-form-control.decorator";
import { FieldTypeEnum } from "../../../../../enums/field-type.enum";
import { advancedFormArray } from "../../../../../decorators/advanced-form-array.decorator";
import {ErrorMessageBuilder} from "../../../../../builders/error-message.builder";
import {translationBaseKey} from "../../../../../decorators/translation-base-key.decorator";

@translationBaseKey("income-tax-personal-child-federal-options")
export class IncomeTaxPersonalChildFederalOptions {
  @advancedFormControl({
    labelTitle: {
      fr: "Frais de garde admissible au fédéral",
      en: "Other federally admissible care expenses",
    },
    tooltip: {
      fr: "Les frais de garde supplémentaires associés à votre enfant.",
      en: "The additional care expenses associated with your child.",
    },
    fieldType: FieldTypeEnum.Money,
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  careExpensesOthers: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que votre enfant réside avec vous?",
      en: "Does your child live with you?",
    },
    tooltip: {
      fr: "Indique si votre enfant réside avec vous ou non.",
      en: "Indicates whether your child lives with you or not.",
    },
    fieldType: FieldTypeEnum.Boolean,
  })
  @IsBoolean()
  @IsOptional()
  isLivingWithYou?: boolean = true;

  @advancedFormControl({
    labelTitle: {
      fr: "Nombre de mois à temps plein dans un établissement post-secondaire pour cet enfant",
      en: "Number of full-time months in a post-secondary institution for this child",
    },
    tooltip: {
      fr: "Le nombre de mois où cet enfant a été à temps plein dans un établissement post-secondaire.",
      en: "The number of months this child attended a post-secondary institution on a full-time basis.",
    },
    fieldType: FieldTypeEnum.Number,
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional()
  numberOfFullTimeMonthsAtSchool?: number = 0;
}
