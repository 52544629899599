import {TextContentModel} from "../models/text.content-model";
import {ImageContentModel} from "../models/image.content-model";

export const HeaderContentResources = {
    "header.main.calculations" : new TextContentModel(
        {"fr": "Nos outils Fiscaux", "en": "Tax Tools"}
    ),

    "header.main.features" : new TextContentModel(
        {"fr": "Fonctionnalités", "en": "Features"}
    ),

    "header.main.pricing" : new TextContentModel(
        {"fr": "Tarifs", "en": "Pricing"}
    ),

    "header.main.contact-us" : new TextContentModel(
        {"fr": "Contactez-nous", "en": "Contact us"}
    ),

    "header.main.start-now" : new TextContentModel(
        {"fr": "Débutez gratuitement maintenant!", "en": "Start for free now!"}
    ),

    "header.main.login" : new TextContentModel(
        {"fr": "Se connecter", "en": "Login"}
    ),

    "header.main.client-portal" : new TextContentModel(
        {"fr": "Portail client", "en": "Client Portal"}
    ),
}
